import React, { useEffect } from "react";
import "./PackageListing.scss";
import saleTag from "../../Assets/img/icons8-price_tag.png";
import priceTag from "../../Assets/img/icons8-sale_price_tag.png";
import SalonCatSlider from "../Common/SalonCatSlider/salonCatSlider";
import ProductBox from "../PackageBox/PackageBox";
import packageImg1 from "../../Assets/img/package1.png";
import packageImg2 from "../../Assets/img/package2.png";
import packageImg3 from "../../Assets/img/package3.png";
import packageImg4 from "../../Assets/img/package4.png";
import packageImg5 from "../../Assets/img/package5.png";
import packageImg6 from "../../Assets/img/package6.png";
import packageImg7 from "../../Assets/img/package7.png";
import packageImg8 from "../../Assets/img/package8.png";
import filtIcon from "../../Assets/img/icons8-filter.png";
import $ from "jquery";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PackageDetailAddress from "../PackageChooseFreq/PackageChooseFreq";
import { useSelector } from "react-redux";
import PackageBox from "../PackageBox/PackageBox";
import { useTranslation } from "react-i18next";
const PackageListing = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    $(document).ready(function () {
      $("div.mobIcon").click(function () {
        $(".rightIn").toggleClass("filtShow");
      });
    });
  }, []);
  const navigate = useNavigate();
  const { dashboardSections } = useSelector((state) => state.home);
  return (
    <>
      <section className="container service-details">
        <div className="service-details-title">
          <i
            className="fa fa-angle-left"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <span className="service-details-title-text">
            {t("common.all_packges")}
          </span>
        </div>
      </section>

      <div className="container mb-5">
        <div className="box row">
          {dashboardSections.packages &&
            dashboardSections.packages.map((packages, key) => (
              <>
                <div className="box-item col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                  <PackageBox packages={packages} key={key} />
                </div>
              </>
            ))}
        </div>
      </div>

      {/* <section className=''>
        <div className=''>
          <div className=' headerFilterOut'>
            <div className='borderBottomDesign titleHead'>
              <div className='spaceSect row mlr0 aligCent jSpaceBetween wid100'>
                <h2 className='sectionTitle centerText'>
                  <Link to={"/"} className='backbt'>
                    <i className='fa fa-angle-left'></i> All {" "}
                    <span>Packages</span>
                  </Link>
                </h2>
              </div>
            </div>
            <div className='row headerFilter'>
              <div className='col-md-3 col-sm-4 col-xs-6 headerFilterIn'>
                <ProductBox
                  proImg={packageImg1}
                  title='Full Car Deep Cleaning & Polish'
                />
              </div>
              <div className='col-md-3 col-sm-4 col-xs-6 headerFilterIn'>
                <ProductBox
                  proImg={packageImg2}
                  title='Full Car Deep Cleaning & Polish'
                />
              </div>
              <div className='col-md-3 col-sm-4 col-xs-6 headerFilterIn'>
                <ProductBox
                  proImg={packageImg3}
                  title='Full Car Deep Cleaning & Polish'
                />
              </div>
              <div className='col-md-3 col-sm-4 col-xs-6 headerFilterIn'>
                <ProductBox
                  proImg={packageImg4}
                  title='Full Car Deep Cleaning & Polish'
                />
              </div>
              <div className='col-md-3 col-sm-4 col-xs-6 headerFilterIn'>
                <ProductBox
                  proImg={packageImg5}
                  title='Full Car Deep Cleaning & Polish'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <PackageDetailAddress/> */}
    </>
  );
};

export default PackageListing;
