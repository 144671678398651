import React from "react";
import "./DowloadApp.scss";
import appS from "../../Assets/img/ButtonAppStore.png";
import playS from "../../Assets/img/ButtonPlayStore.png";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

const DowloadApp = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="dowloadAppOut">
      <div className="dowloadApp conatiner-fluid">
        <h3 className="download-text">{t("common.download_an_app")}</h3>
        <ul className="logoIcon">
          <li>
            <a
              href="https://www.apple.com/in/app-store/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={appS} />
            </a>
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps"
              target="_blank"
              rel="noreferrer"
            >
              <img src={playS} />
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default DowloadApp;
