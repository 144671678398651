import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clock from "../../Assets/img/clock.png";
import { Link, useNavigate } from "react-router-dom";
import car from "../../Assets/img/carIcon.png";
import { toast } from "react-toastify";
import { handlepackageServiceBooking } from "../../Slices/home";
import moment from "moment";
export default function PackageStartDate() {
  const navigate = useNavigate();
  const [date, setDate] = useState(-1);
  const [time, setTime] = useState(-1);
  const {
    serviceDetails,
    isLoadingBooking,
    bookingDetails,
    packageServiceBooking,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [timing, setTiming] = useState({
    start_date: "",
    start_time: "",
  });

  const handleNextStep = () => {
    if (timing?.start_date && timing?.start_time) {
      dispatch(handlepackageServiceBooking({ ...timing }));
      navigate("/package-address-options");
    } else {
      toast.error("Select Date and Time");
    }
  };

  useEffect(() => {
    setTiming({
      start_time: packageServiceBooking?.start_time,
      start_date: packageServiceBooking?.start_date,
      start_time_full: packageServiceBooking?.start_time_full,
    });
  }, []);

  return (
    <>
      <section className="container service-details">
        <div className="service-details-title">
          <i className="fa fa-angle-left" onClick={() => navigate(-1)}></i>
          <span className="service-details-title-text">Schedule</span>
        </div>

        <div className="service-details-body">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="">
                <img
                  className="service-details-img"
                  src={serviceDetails?.service_image_url}
                  alt=""
                />
              </div>
              <div className="service-details-information marginTop-19">
                <div className="service-details-info">
                  <h6 className="service-details-name">
                    {serviceDetails?.service_name}
                  </h6>
                  <p className="service-details-description">
                    {serviceDetails?.description}
                  </p>
                </div>
                <div className="service-details-price-box">
                  {serviceDetails?.offer_price ? (
                    <>
                      <span className="service-details-mrp">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.price ? (
                    <>
                      <span className="service-details-price">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.offer_discount_price ? (
                    <>
                      <span className="service-details-save-price">
                        Save {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_discount_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="service-details-time">
                  <img src={clock} alt="clock-icon" width={15} height={15} />
                  <span className="time">
                    {serviceDetails?.duration} Minutes.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-7 choose-provider">
              <div className="schedule-title-box">
                <div>
                  <span className="schedule-box">Schedule</span>
                </div>
                <div className="date-box">
                  <div className="date-bar">
                    <span className="selec-date-text">
                      Select Date of Service
                    </span>
                    <span className="date">
                      {moment(new Date()).format("MMMM DD")}
                    </span>
                  </div>
                  <div className="date-list">
                    {[...Array(8)].map((item, index) => {
                      return (
                        <div
                          className={`${
                            moment(
                              new Date(
                                new Date().setDate(
                                  new Date().getDate() + index + 1
                                )
                              )
                            ).format("YYYY-MM-DD") == timing.start_date
                              ? `date--box-active`
                              : `date--box`
                          }`}
                          onClick={() => {
                            setDate(index);
                            setTiming((old) => {
                              return {
                                ...old,
                                start_date: moment(
                                  new Date(
                                    new Date().setDate(
                                      new Date().getDate() + index + 1
                                    )
                                  )
                                ).format("YYYY-MM-DD"),
                              };
                            });
                          }}
                        >
                          <span className="day-name">
                            {moment(
                              new Date(
                                new Date().setDate(
                                  new Date().getDate() + index + 1
                                )
                              )
                            ).format("ddd")}
                          </span>
                          <span className="date-day">
                            {moment(
                              new Date(
                                new Date().setDate(
                                  new Date().getDate() + index + 1
                                )
                              )
                            ).format("DD")}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="time--box">
                  <span className="time-title">Time to Start The Service</span>
                  <div className="time-list">
                    {[
                      "07:00",
                      "08:00",
                      "09:00",
                      "10:00",
                      "11:00",
                      "12:00",
                      "13:00",
                      "14:00",
                      "15:00",
                      "16:00",
                      "01:00",
                      "02:00",
                      "03:00",
                      "04:00",
                      "05:00",
                      "06:00",
                    ].map((item, index) => {
                      return (
                        <div
                          className={`${
                            item === timing?.start_time_full
                              ? "time-item-active"
                              : "time-item"
                          }`}
                          onClick={() => {
                            setTime(index);
                            setTiming((old) => {
                              return {
                                ...old,
                                start_time: item.split(" ")[0],
                                start_time_full: item,
                              };
                            });
                          }}
                        >
                          {moment(item, ["h:mm A"]).format("HH:mm")}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="choose-provider-footer">
                <span className="choose-provider-price">
                  Total : {serviceDetails?.currency?.symbol}{" "}
                  {serviceDetails?.price}
                </span>
                {/* <Link to="/package-address-options"> */}
                {
                  <>
                    <button
                      onClick={() => {
                        handleNextStep();
                      }}
                      className="next-button"
                    >
                      CONTINUE
                    </button>
                  </>
                }
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
