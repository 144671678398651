import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import { getCountryList, registerUser } from "../../Slices/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
export default function Registration({
  handleInputChange,
  user,
  setUser,
  initialUserValue,
  isEmailValid,
}) {
  const [birthDate, setBirthDate] = useState(new Date());
  const [isChecked, setIsChecked] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [requiredFirstName, setRequiredFirstName] = useState(true);
  const [requiredLastName, setRequiredLastName] = useState(true);
  const [requiredMobileNo, setRequiredMobileNo] = useState(true);
  const [requiredCountryCode, setRequiredCountryCode] = useState(true);
  const [requiredEmail, setRequiredEmail] = useState(true);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCountryCode = () => {
    dispatch(getCountryList()).then((response) => {
      setCountryList(response.payload.data.result.data);
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    console.log(user);
    if (user.first_name === undefined || user.first_name === "") {
      setRequiredFirstName(false);
    } else {
      setRequiredFirstName(true);
    }
    if (user.last_name === undefined || user.last_name === "") {
      setRequiredLastName(false);
    } else {
      setRequiredLastName(true);
    }
    if (
      user.mobile_no === undefined ||
      user.mobile_no === "" ||
      user.mobile_no.length != 10
    ) {
      setRequiredMobileNo(false);
    } else {
      setRequiredMobileNo(true);
    }

    if (user.country_code === undefined || user.country_code === "") {
      setRequiredCountryCode(false);
    } else {
      setRequiredCountryCode(true);
    }
    if (user.email === undefined || user.email === "") {
      setRequiredEmail(false);
    } else {
      setRequiredEmail(true);
    }

    if (user.mobile_no && user.mobile_no.length !== 10) {
      toast.error("Enter Valid Mobile Number", { autoClose: 2000 });
    }

    if (
      user.is_subscribed_news_letter === undefined ||
      user.is_subscribed_news_letter === 0
    ) {
      toast.error(
        "Please Check Subscribe for Newsletter & Promotional Offers",
        { autoClose: 7000 }
      );
    }

    dispatch(registerUser(user))
      .then((response) => {
        if (response.payload.result.token) {
          toast.success(response.payload.message, { position: "top-center" });
          navigate("/");
          sessionStorage.setItem("token", response.payload.result.token);
        } else {
          toast.error(response.payload.message, { autoClose: 7000 });
        }
      })
      .catch((err) => {
        toast.error(err, { autoClose: 7000 });
      });
  };
  console.log(requiredFirstName, user);
  useEffect(() => {
    getCountryCode();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="signup-main-box">
        <div className="container">
          <div className="signup-header">
            <h3 className="signup-title"> {t("common.signup")}</h3>
          </div>
        </div>

        <div className="container signup-box">
          <div className="row justify-content-between">
            <div className="col-12 col-xl-5 signup-welcome-section">
              <h2 className="signup-welcome">{t("common.welcome_word")}</h2>
              <p className="signup-opportunity">
                {t("common.proffesional_world")}
              </p>
            </div>
            <div className="col-12 col-xl-7 signup-field-box">
              <div className="row main-field-box">
                <div className="col-12 col-sm-6">
                  <div className="field-box">
                    <label className="form-label" id="first_name">
                      {t("common.first_name")}
                    </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      className={`text-field ${!requiredFirstName && "error"}`}
                      name="first_name"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="field-box">
                    <label className="form-label" id="last_name">
                      {t("common.last_name")}
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className={`text-field ${!requiredLastName && "error"}`}
                      name="last_name"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row main-field-box">
                <div className="col-12">
                  <div className="field-box">
                    <label className="form-label">{t("common.email")} </label>
                    <input
                      type="email"
                      placeholder="Email"
                      className={`text-field ${!requiredEmail && "error"}`}
                      name="email"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row main-field-box">
                <div className="col-12">
                  <div className="field-box">
                    <label className="form-label">
                      {t("common.phone_number")}
                    </label>
                    <div className="phone-main-box">
                      <div className="dialcode-box">
                        <select
                          name="country_code"
                          className={`login-phone-dialcode bg-white ${
                            !requiredCountryCode && "error"
                          }`}
                          onChange={handleInputChange}
                        >
                          <option
                            selected
                            value={"Select Country Code"}
                            style={{ textAlign: "center" }}
                          >
                            {" "}
                            Code
                          </option>

                          <option value={"+91"}>{"IN (+91)"}</option>
                          {countryList?.map((list) => {
                            return (
                              <option
                                key={list.id}
                                value={"+" + list.phone_code}
                              >{`${list.country_code} (${
                                "+" + list.phone_code
                              })`}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="phone-box">
                        <input
                          type="Text"
                          placeholder="Phone"
                          className={`text-field ${
                            !requiredMobileNo && "error"
                          }`}
                          name="mobile_no"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row main-field-box">
                <div className="col-12 col-sm-6">
                  <div className="field-box">
                    <label className="form-label">
                      {t("common.gender_optional")}
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      onChange={handleInputChange}
                      className={`text-field'}`}
                    >
                      <option value="Male">{t("common.male")}</option>
                      <option value="Female">{t("common.female")}</option>
                      <option value="Other">{t("common.other")}</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="field-box ">
                    <label className="form-label">
                      {t("common.birthday_optional")}
                    </label>
                    <input
                      type="date"
                      value={user.birth_date}
                      name="birth_date"
                      onChange={handleInputChange}
                      className={`text-field`}
                    />
                  </div>
                </div>
              </div>

              <div className="team-main-box">
                <div className="row ">
                  <div className="col-12 team-box">
                    <label>
                      <input
                        type="checkbox"
                        className="d-none"
                        checked={user.is_subscribed_promotional_offer}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setUser({
                              ...user,
                              is_subscribed_promotional_offer: 1,
                              is_subscribed_news_letter: 1,
                            });
                          } else {
                            setUser({
                              ...user,
                              is_subscribed_promotional_offer: 0,
                              is_subscribed_news_letter: 0,
                            });
                          }
                        }}
                      />
                      <span
                        className={`checkbox ${
                          user.is_subscribed_promotional_offer
                            ? "checkbox--active"
                            : ""
                        }`}
                        aria-hidden="true"
                      />
                    </label>
                    <div>
                      <p className="mb-0 subcribe">
                        {t("common.subscribe_for")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div>
                      <p className="subIn">
                        {t("common.by_proceeding")}
                        <a href="/cms?slug=Conditions" target={"_blank"}>
                          {" "}
                          {t("common.conditions_of_uses")}{" "}
                        </a>{" "}
                        and{" "}
                        <a href="/cms?slug=privacy-policy" target={"_blank"}>
                          {t("common.privacy_policy")}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 mb-2">
                <div className="line-h"></div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button className="validate-button" onClick={handleRegister}>
                    {t("common.validate")}
                  </button>
                </div>
              </div>

              {/* <div className="skip-login">
                <Link to={"#"} className="skip-login-text">
                  Skip login & go to dashboard
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container signup-box">
          <div className="row">
            <div className="col-12 col-xl-6 info-section">
              <h2 className="signup-welcome">Welcome Back!</h2>
              <p className="signup-opportunity">
                Don’t miss your next opportunity. Sign In to stay updated on
                your proffesional world.
              </p>
            </div>

            <div className="col-12 col-xl-6">
              <div className="row">
                <div className="col-12 col-xl-6">
                  <h3 className="login-field-label">First Name</h3>
                  <input
                    type="text"
                    id="fname"
                    name="first_name"
                    className="text-field"
                    placeholder="Enter your first name"
                  />
                </div>
                <div className="col-12 col-xl-6">
                  <h3 className="login-field-label">Last Name</h3>
                  <input
                    type="text"
                    id="lname"
                    name="last_name"
                    className="text-field"
                    placeholder="Enter your last name"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <h3 className="login-field-label">{`Email (Optional)`}</h3>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="text-field"
                    placeholder="Enter your email"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h3 className="login-field-label">{`Phone Number`}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-2 pr-0">
                  <input
                    type="number"
                    id="dialcode"
                    name="dialcode"
                    className="text-field"
                    placeholder="+91"
                  />
                </div>
                <div className="col-10">
                  <input
                    type="number"
                    id="number"
                    name="Phone_number"
                    className="text-field"
                    placeholder="Enter your phone"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 pr-0">
                  <h3 className="login-field-label">{`Gender (Optional)`}</h3>
                  <select id="gender" name="gender" className="dropdown-field">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-6">
                  <h3 className="login-field-label">{`Birthday (Optional)`}</h3>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="text-field"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="d-flex team">

                    <div className="team-checkbox"> </div>
                     <input class="" type="checkbox" value="" id="" /> 
                    <span className="subscribe-text">Subscribe for Newsletter & Promotional Offers</span>
                  </div>
                  <p className="by-procee"> 
                    by proceeding, you agree to brand’s
                    <Link to={"/"}><span className="team-text"> conditions of uses </span> </Link>and
                    <Link to={"/"}><span className="team-text"> privacy policy </span></Link>
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button className="validate-button">Validate</button>
                </div>
              </div>
              <div className='skip-login'>
                      <Link to={"#"} className='skip-login-text'>Skip login & go to dashboard</Link>
              </div>
            </div>
          </div>
        </div>  */}
    </>
  );
}
