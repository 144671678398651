import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./PackageDetail.scss";
import car from "../../Assets/img/clock.png";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

const PackageDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { packageDetail } = useSelector((state) => state.home);
  const { isLoadingBooking } = useSelector((state) => state.home);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  return (
    <section className="container service-details">
      <div className="service-details-title">
        <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
        <span className="service-details-title-text">Package Detail</span>
      </div>
      <div className="service-details-body">
        <div className="row">
          {!isLoadingBooking ? (
            <>
              {" "}
              <div className="col-12 col-lg-5">
                <div className="">
                  <img
                    className="service-details-img"
                    src={packageDetail?.package_image_url}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-12 col-lg-7 service-details-information">
                <div className="service-details-info">
                  <h6 className="service-details-name">
                    {packageDetail?.package_name}
                  </h6>
                  <p className="service-details-description">
                    {packageDetail?.package_description}
                  </p>
                </div>
                <div className="service-details-price-box">
                  <span className="service-details-price">
                    {packageDetail?.currency?.symbol}{" "}
                    {packageDetail?.total_price}
                  </span>
                </div>
                <div className="service-details-time">
                  <img src={car} alt="" width={15} height={15} />
                  <span className="time">
                    {packageDetail?.package_service_limit} {t("common.time")}.{" "}
                  </span>
                </div>
                <div className="service-details-book-box">
                  <Link to={"/packageDetailSchedule"}>
                    <button className="purchase-now-button">
                      {" "}
                      {t("common.purchase_now")}{" "}
                    </button>
                  </Link>
                </div>
                {packageDetail?.service?.services_includes ? (
                  <>
                    <div className="service-details-more-info">
                      <h4 className="service-details-more-info-title">
                        {t("common.service_includes")}
                      </h4>
                      <div className="service-details-packagebox">
                        <img
                          src={packageDetail?.service?.service_image_url}
                          width={122}
                          height={97}
                          alt=""
                        />
                        <div className="service-details-packagebox-info">
                          <h3 className="service-details-title-packagebox">
                            {packageDetail?.service?.service_name}
                          </h3>
                          <span className="service-details-title-description">
                            {packageDetail?.service?.description}
                          </span>
                        </div>
                      </div>
                      <div className="service-details-more-sub-info">
                        {packageDetail?.service?.services_includes.map(
                          (item, index) => {
                            return (
                              <>
                                <div key={index}>
                                  <h5 className="sub-tltle">
                                    {item.attribute_name.attribute_name}
                                  </h5>
                                  <p className="sub-dec">
                                    {item.product_attribute_text}
                                  </p>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-lg-5">
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={500}
                  sx={{ margin: "12px", borderRadius: "12px" }}
                />
              </div>
              <div className="col-12 col-lg-7 service-details-information">
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={500}
                  sx={{ margin: "12px", borderRadius: "12px" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default PackageDetail;
