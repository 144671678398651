import React, { useState } from "react";
import { useDispatch } from "react-redux";
import LogoText from "../Common/LogoText/LogoText.jsx";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import "./OtpForm.scss";
import { showOtp, userSignIn } from "../../Slices/auth.js";
import { Link } from "react-router-dom";

const OtpForm = ({ handleInputChange, user, initialUserValue, setUser }) => {
  const dispatch = useDispatch();
  const [changeNumber, setChangeNumber] = useState(false);
  const [OTP, setOTP] = useState("");

  const handleVerifyOTP = () => {
    console.log(OTP, "OTP");
    if (OTP !== "111111") {
      toast.error("Enter Valid OTP");
      return null;
    }
    dispatch(userSignIn(user)).then((response) => {
      if (response.payload.status === 200) {
        toast.success(response.payload.data.message, {
          position: "top-center",
        });
        setTimeout(() => {
          dispatch(showOtp(false));
          document
            .getElementsByTagName("body")[0]
            .classList.remove("logSinoScroll");
        }, 1500);
      } else {
        toast.error(response.payload.data.message);
      }
    });
  };

  const submitPhoneNumberAuthCode = (e) => {
    return new Promise((resolve, reject) => {
      try {
        var code = OTP;
        window.confirmationResult
          .confirm(code)
          .then(function (result) {
            var user = result.user;
            resolve(user);
          })
          .catch(function (error) {
            console.log(error);
            reject(error.message);
          });
      } catch (error) {
        console.log(error);
        reject(error.message);
      }
    });
  };

  const handleUserProvidedCode = async (e) => {
    e.preventDefault();
    try {
      const userRes = await submitPhoneNumberAuthCode();
      try {
        handleVerifyOTP();
      } catch (error) {
        console.log(error.payload.message);
        toast.error(error.payload.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="container otpForm">
      <section className="ServDetailPopOut">
        <div className="contianer-fluid ServDetailPop">
          <div className="closeIcon" onClick={() => dispatch(showOtp(false))}>
            <i className="fa fa-times"></i>
          </div>
          <LogoText />
          <div className="formOut">
            <form>
              <div className="formInText borderVot">
                {/* <div className='inpuWidth aftBorder'>
                  <div className='formInText mb0 pb0 m0'>
                    <label>
                      Phone Number{" "}
                      <span style={{ cursor: "pointer" }} onClick={() => setChangeNumber(!changeNumber)}>Change Number</span>
                    </label>
                    <div className='phLeft'>
                      <input type='tel' name='country_code' disabled={!changeNumber} value={user.country_code}  />
                    </div>
                    <div className='pHright'>
                      <input
                        type='tel'
                        maxLength={10}
                        name='mobile_no'
                        // placeholder='98982 45235'
                        disabled={!changeNumber}
                        value={user.mobile_no}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="inpuWidth fullwidth">
                  <div className="formInText main-otp-box mb0 pb0 m0">
                    <div className="label-body">
                      <label className="login-phone">Type OTP Here</label>
                      <Link to={"/"} className="login-label">
                        Resend
                      </Link>
                    </div>

                    <div className="optValue">
                      <OtpInput
                        value={OTP}
                        onChange={(value) => setOTP(value)}
                        numInputs={6}
                        separator={<span />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttonsec">
                <input
                  type="button"
                  value="Verify OTP"
                  onClick={(e) => handleVerifyOTP(e)}
                />
              </div>
              <div className="textCenter wid100 skipLogin">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(showOtp(false));
                    setUser(initialUserValue);
                  }}
                >
                  Skip login & go to dashboard
                </span>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* <input type="number" value="+91"/> */}
    </div>
  );
};

export default OtpForm;
