import React, { useEffect, useState } from "react";
import "./MidSlider.scss";
import { Link } from "react-router-dom";
import { List } from "react-content-loader";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons } from "../../Slices/home";
import { useTranslation } from "react-i18next";
const MidSlider = () => {
  const SliderSettings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  //   const { homeOfferSlider, isHomeOfferSliderFetched } = useSelector((state) => state.home)
  const { isgetAllCouponsFetched, coupons } = useSelector(
    (state) => state.home
  );
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [settings, setSettings] = useState(SliderSettings);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllCoupons({ country_id: user?.country_id || "103" }));
  }, []);

  useEffect(() => {
    if (coupons.length === 1) {
      setSettings({
        ...settings,
        dots: false,
        arrow: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
        ],
      });
    } else if (coupons.length === 2) {
      setSettings({
        ...settings,
        dots: false,
        arrow: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              infinite: true,
              dots: false,
              arrow: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrow: false,
            },
          },
        ],
      });
    } else if (coupons.length >= 3) {
      setSettings({
        ...settings,
        dots: false,
        arrow: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              infinite: true,
              dots: false,
              arrow: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrow: false,
            },
          },
        ],
      });
    } else {
      setSettings({
        ...settings,
        dots: false,
        arrow: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              infinite: true,
              dots: false,
              arrow: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrow: false,
            },
          },
        ],
      });
    }
  }, [coupons]);

  return (
    <>
      <section className="coupons-section">
        <div className="container">
          <div className="section-title-box m-0">
            <h2 className="sectionTitle centerText">{t("common.coupons")}</h2>
            {/* <p className='sectionSeeAll centerText m-0'>
                        <Link className="see-all-text" to={"/"}>SEE ALL</Link>
                </p> */}
          </div>
          <hr />

          {coupons ? (
            <>
              {coupons.length >= 2 ? (
                <>
                  {" "}
                  <Slider {...settings}>
                    {coupons &&
                      coupons?.map((list, index) => {
                        return (
                          <>
                            <Link
                              to={`/subSpecialities/${list?.id}`}
                              className={`mlr-5 ${
                                coupons.length == 2 ? "d-flex-center" : ""
                              }`}
                            >
                              <img
                                className={`slider-coupons-item ${
                                  coupons.length == 2 ? "w-70" : ""
                                }`}
                                src={list?.coupon_image_url}
                                alt="coupon-img"
                              />
                            </Link>
                          </>
                        );
                      })}

                    {coupons &&
                      coupons?.map((list, index) => {
                        return (
                          <>
                            <Link
                              to={`/subSpecialities/${list?.id}`}
                              className={`mlr-5 ${
                                coupons.length == 2 ? "d-flex-center" : ""
                              }`}
                            >
                              <img
                                className={`slider-coupons-item ${
                                  coupons.length == 2 ? "w-70" : ""
                                }`}
                                src={list?.coupon_image_url}
                                alt="coupon-img"
                              />
                            </Link>
                          </>
                        );
                      })}
                  </Slider>
                </>
              ) : (
                <>
                  {coupons &&
                    coupons?.map((list, index) => {
                      return (
                        <>
                          <div className="mySlider row">
                            <div className="box-item col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <Link
                                style={{display:'inline-block', width:'100%'}}
                                to={`/subSpecialities/${list?.id}`}
                                className={`mlr-5 ${
                                  coupons.length == 2 ? "d-flex-center" : ""
                                }`}
                              >
                                <img
                                  className={`slider-coupons-item ${
                                    coupons.length == 2 ? "w-70" : ""
                                  }`}
                                  src={list?.coupon_image_url}
                                  alt="coupon-img"
                                />
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </>
              )}
            </>
          ) : (
            <section className="container">
              <List />
            </section>
          )}
        </div>
      </section>

      {/* <section className='middleSlider'>
            <div div className='container'>
            <div className='row mlr0'>
              {isHomeOfferSliderFetched ?
                <Slider {...settings}>
                  {homeOfferSlider.length && homeOfferSlider.map((list) => {
                    return <div className='carouselPadding'>
                    <Link to={`/offer-listing/${list.id}`} className='carouselImg'>
                      <LazyLoad>
                        <img className="carouselSliderImg" alt='img1' src={list.offer_banner_url} />
                      </LazyLoad>
                    </Link>
                  </div>
                  })}
                  <div className='carouselPadding'>
                    <Link to='' className='carouselImg'>
                      <LazyLoad>
                        <img className="carouselSliderImg" alt='img1' src={catImg2} />
                      </LazyLoad>
                    </Link>
                  </div>
                  <div className='carouselPadding'>
                    <Link to='' className='carouselImg'>
                      <LazyLoad>
                        <img className="carouselSliderImg" alt='img1' src={catImg2} />
                      </LazyLoad>
                    </Link>
                  </div>
                  <div className='carouselPadding'>
                    <Link to='' className='carouselImg'>
                      <LazyLoad>
                        <img className="carouselSliderImg" alt='img1' src={catImg2} />
                      </LazyLoad>
                    </Link>
                  </div> 
                </Slider>
              : <Skeleton count={5} />}
               </div> 
             </div>
        </section> */}
      {/* <section className='middleSlider spaceSect '>
              <div className="  titleHead container">
                  <h2 className='sectionTitle centerText'>
                      Coupons
                  </h2>
                  <p className='sectionSeeAll centerText'>
                      <Link to={"/"}>
                          See All
                      </Link>
                  </p>
              </div>
              <div className='container  '>

                  <Slider {...settings}>

                      {coupons?.map((list, key) => {
                          return (
                              <div className='text-center' key={key}>
                                  <Link to={`/subSpecialities/${list.id}`}>
                                      <div className='imagOut'>
                                          <img
                                              className='categoryCard img-radius mx-auto'
                                              src={list.coupon_image_url}
                                              alt='img1'
                                          />
                                      </div>
                                      <span className='categoryTitle'>
                        </span>
                                  </Link>
                              </div>
                          );
                      })}

                  </Slider>
              </div>
          </section>  */}
    </>
  );
};

export default MidSlider;
