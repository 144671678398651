import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editUserProfile, getUserProfile } from "../../../Slices/auth";
import { Facebook } from "react-content-loader";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Profile = ({ loading, setLoading, profile, setProfile, FetchData }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { t, i18n } = useTranslation();

  const editProfile = () => {
    if (!isEmailValid) {
      toast.error("Enter Valid Email");
      return null;
    }
    setLoading(true);
    let gender = "";
    if (profile.gender == "male") {
      gender = "Male";
    } else if (profile.gender == "female") {
      gender = "Female";
    } else if (profile.gender == "other") {
      gender = "Other";
    } else {
      gender = profile.gender;
    }
    const formData = new FormData();
    formData.append("first_name", profile.first_name);
    formData.append("last_name", profile.last_name);
    formData.append("email", profile.email);
    formData.append("gender", gender);
    formData.append("birth_date", profile.birth_date);
    formData.append(
      "is_subscribed_news_letter",
      profile?.is_subscribed_news_letter
    );
    formData.append(
      "is_subscribed_promotional_offer",
      profile?.is_subscribed_promotional_offer
    );
    console.log(formData, "formData");
    formData.gender = dispatch(editUserProfile(formData))
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message);
          FetchData();
          setLoading(false);
        } else {
          toast.error(response.payload.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("profile change", user);
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(profile.email && profile.email)) {
      setIsEmailValid(true);
    } else {
      console.log(false, "console.log(true)console.log(true)console.log(true)");
      setIsEmailValid(false);
    }
    // eslint-disable-next-line
  }, [profile.email]);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    const emailRegex = /\S+@\S+\.\S+/;

    switch (name) {
      case "first_name":
        setProfile({ ...profile, [name]: value.replace(/[0-9]/g, "") });
        break;
      case "last_name":
        setProfile({ ...profile, [name]: value.replace(/[0-9]/g, "") });
        break;
      case "email":
        if (emailRegex.test(value)) {
          setIsEmailValid(true);
        } else {
          setIsEmailValid(false);
        }
        setProfile({ ...profile, [name]: value });
        break;
      default:
        setProfile({ ...profile, [name]: value });
        break;
    }
    // setProfile({ ...profile, [name]: value });
  };

  return (
    <>
      {!loading ? (
        <div className="profDetailIn">
          <div className="profdetHead">
            <span className="myprofext">
              <span>{/* <i className="fa fa-angle-left"></i> */}</span>
              {t("common.my_profile")}
            </span>
            <span className="save" onClick={editProfile}>
              {t("common.save")}
            </span>
          </div>
          <div className="profdetHeadText">
            <span className="textalert">{t("common.welcome_here")}</span>
            <h2> {profile.name}</h2>
          </div>
          <div className="formOut profdet">
            <form>
              <div className="formInText mt-3">
                <div className="inpuWidth">
                  <label>{t("common.first_name")}</label>
                  <input
                    type="text"
                    name="first_name"
                    value={profile?.first_name}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="inpuWidth">
                  <label>{t("common.last_name")}</label>
                  <input
                    type="text"
                    name="last_name"
                    value={profile?.last_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="formInText mt-3">
                <div className="inpuWidth">
                  <div className="formInText mb0 pb0">
                    <label>{t("common.phone_number")}</label>
                    <div className="phLeft">
                      <input
                        type={"text"}
                        disabled
                        name="country_code"
                        value={profile.country_code}
                      />
                    </div>
                    <div className="pHright">
                      <input
                        type="text"
                        disabled
                        name="mobile_no"
                        value={profile.mobile_no}
                      />
                    </div>
                  </div>
                </div>
                <div className="inpuWidth">
                  <label>{t("common.email")}</label>
                  <input
                    type="text"
                    name="email"
                    value={profile.email}
                    onChange={handleInputChange}
                  />
                  {!isEmailValid &&
                    profile.email !== undefined &&
                    profile.email !== "" && (
                      <strong className="text-danger">
                        {t("common.enter_valid_email")}
                      </strong>
                    )}
                </div>
              </div>
              <div className="formInText mt-3">
                <div className="inpuWidth ">
                  <label>{t("common.gender_optional")}</label>
                  <select name="gender" onChange={handleInputChange}>
                    <option selected={profile.gender === "male"} value={"Male"}>
                      {t("common.male")}
                    </option>
                    <option
                      selected={profile.gender === "female"}
                      value={"Female"}
                    >
                      {t("common.female")}
                    </option>
                  </select>
                </div>
                <div className="inpuWidth dateImg">
                  <label>{t("common.birthday_optional")}</label>
                  <input
                    type={"date"}
                    name="birth_date"
                    value={moment(profile.birth_date).format("YYYY-MM-DD")}
                    max={moment().format("YYYY-MM-DD")}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="row ">
                  <div className="col-12 team-box">
                    <label>
                      <input
                        type="checkbox"
                        className="d-none"
                        checked={profile.is_subscribed_promotional_offer}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setProfile((prev) => {
                              return {
                                ...prev,
                                is_subscribed_promotional_offer: 1,
                                is_subscribed_news_letter: 1,
                              };
                            });
                          } else {
                            setProfile((prev) => {
                              return {
                                ...prev,
                                is_subscribed_promotional_offer: 0,
                                is_subscribed_news_letter: 0,
                              };
                            });
                          }
                        }}
                      />
                      <span
                        className={`checkbox ${
                          profile.is_subscribed_promotional_offer
                            ? "checkbox--active"
                            : ""
                        }`}
                        aria-hidden="true"
                      />
                    </label>
                    <div>
                      <p className="mb-0 subcribe">
                        {t("common.subscribe_for")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Facebook />
      )}
    </>
  );
};

export default Profile;
