import React from "react";
import "./ConFirmBooking.scss";
import { useSelector } from "react-redux";
// import { setShowLocationModal, setShowOptionModal } from '../../slices/service';

import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
const ConFirmBooking = () => {
  const { state } = useLocation();
  // const { serviceDetails } = useSelector((state) => state.home);
  const bookingDetails = state?.bookingDetails;
  const serviceDetails = state?.serviceDetails;

  // const calculateDiscount = (price, offerPrice) => {
  //     const discount = price - offerPrice
  //     return discount
  // }
  const navigate = useNavigate();
  console.log(bookingDetails, state, "bookingDetails");
  // if (!bookingDetails) {
  //     navigate('/     ')
  // }
  return (
    <>
      <section className="container conFirm-booking service-details">
        <div className="service-details-title">
          <i className="fa fa-angle-left" onClick={() => navigate(-1)}></i>
          <span className="service-details-title-text">Booking Confirmed</span>
        </div>

        <div className="row">
          <div className="col-12 col-lg-5 img-box">
            <img
              className="conFirm-booking-img"
              src={require("../../Assets/img/booking.png")}
              alt=""
            />
            <div className="tag-box  mt-5">
              <h3 className="tag-1 m-0">Thank You!!!</h3>
              <h3 className="tag-2 m-0">Booking Confirmed</h3>
            </div>
          </div>
          <div className="col-12 col-lg-7 ">
            <div className="booking-info-box">
              <div className="booking-info">
                <img
                  className="booking-service-img"
                  src={serviceDetails?.service_image_url}
                  alt=""
                />
                <div className="booking-item">
                  <h3 className="booking-service-name">
                    {serviceDetails?.service_name}
                  </h3>
                  <p className="booking-service-info">
                    {serviceDetails?.description}
                  </p>
                </div>
              </div>
              <div className="booking--info mt-2">
                {[
                  moment(bookingDetails?.start_date).format("DD MMMM"),
                  bookingDetails?.start_time_full,
                  serviceDetails?.duration + " " + "Minutes",
                  "At " + bookingDetails?.location_type,
                ].map((item, index) => (
                  <>
                    <div className="booking--info-item" key={index}>
                      <span className="booking--info-text text-capitalize">
                        {item}
                      </span>
                    </div>
                  </>
                ))}
              </div>

              <div className="booking-address mt-0">
                <h3 className="booking-address-title text-capitalize">
                  {bookingDetails?.location_type}
                </h3>
                <p className="booking-address-info">
                  {bookingDetails?.location}
                </p>
              </div>

              <button
                className="my-booking-button mt-0"
                onClick={() => navigate("/myProfile?page=booking")}
              >
                Go To My Bookings
              </button>
            </div>
          </div>
        </div>
      </section>
      {/*}    <section className='ServDetailPopOut serviceOpt confirm'>

        <div className='contianer-fluid ServDetailPop'>
            <div className='closeIcon' style={{cursor: "pointer"}} onClick={()=> dispatch(showConfirmBooking(false))}>
                <i className='fa fa-times'></i>
            </div>
            
            <div className='contetTextArea'>
                    <img src={topImg} className='topImg'/>
                        <div className="profdetHeadText">
                            <span className="textalert">{"Thank You !!!"}</span>
                            <h2>{"Booking Confirmed"} </h2>
                        </div>
                        
                
                <div className='summarLeft'>
                    <div className='summarLeftIn'>
                        <div className='summarLeftImgText'>
                            <div className='summarLeftImg'>
                                <img src={service}/>
                            </div>
                            <div className='headerFilterInfo'>
                                <strong className='serviceName'>
                                    Delux Body Wash - This Screen will be Removed after confirmation
                                </strong>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy...</p>
                    
                            </div>
                        </div>
                    </div>
                    <div className='summarLeftAdd'>
                        <ul className='listRadio addlocationradio'>
                            <li>
                                <ul className='listRadio locationradioList'>
                                    <li className='date'>
                                        <label>
                                            <h5>26 August</h5>
                                        </label>
                                    </li>
                                    <li className='date'>
                                        <label>
                                            <h5>02:00 PM</h5>
                                        </label>
                                    </li>
                                    <li className='date'>
                                        <label>
                                            <h5>45 Minutes</h5>
                                        </label>
                                    </li>
                                    <li className='date'>
                                        <label>
                                            <h5>At Home</h5>
                                        </label>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                            <ul className='listRadio addlocationradio'>
                                <li className='addText'>
                                
                                    <label>
                                        <h5>Office</h5>
                                        <p>G-57, Goving Park, Ghodasar Road, manekchowk,Ahmedabad-380050
                                         </p>
                                    </label>
                                </li>
                            </ul>
                    </div>
                    <button className='confirm' style={{cursor: "pointer"}} onClick={() => {navigate("/myProfile?page=booking"); 
                    dispatch(showConfirmBooking(false))}} >Go to My Bookings</button>
                </div>
               
            </div>
        </div>
  </section>*/}
    </>
  );
};

export default ConFirmBooking;
