import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceDetails,
  setLoading,
  setLoadingFalse,
} from "../../../Slices/home";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Button.scss";

const Button = ({ serviceId, car_id, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, user_car_id_default } = useSelector((state) => state.auth);
  const handleNext = () => {
    if (Object.keys(user).length == 0 || !sessionStorage.getItem("token")) {
      toast.error("Please Login Before Book Service");
    } else if (!user_car_id_default) {
      toast.error("Select Default Car");
    } else {
      dispatch(setLoading({ status: 1 }));
      navigate("/ServiceDetail");
      dispatch(
        getServiceDetails({
          service_id: serviceId,
          country_id: user?.country_id,
          car_id: user_car_id_default,
        })
      )
        .then((response) => {
          if (response?.payload?.status == 200) {
          } else {
            toast.error(response?.payload?.message);
          }
          dispatch(setLoadingFalse({ status: 1 }));
        })
        .catch((error) => toast.error(error.message));
    }
  };
  return (
    <>
      <button
        className="btn btn-sm serFilBTn book-button sm:p-0"
        onClick={() => {
          handleNext();
        }}
      >
        BOOK
      </button>
    </>
  );
};

export default Button;
