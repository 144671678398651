import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import clock from "../../Assets/img/clock.png";
import { handleBookingDetails, handleResetBookingDetails } from "../../Slices/home";
import "./AddressOptions.scss";
import { createBooking, handlePaymentSummaryDetails } from "../../Slices/auth";

export default function AddressOptions() {
  const { serviceDetails } = useSelector((state) => state.home);
  const { bookingDetails } = useSelector((state) => state.home);
  const [locationType, setLocationType] = useState({
    home: false,
    outlet: true,
  });


  const { user_address } = useSelector((state) => state.auth.user);
  const { user } = useSelector((state) => state.auth);

  const [location, setLocation] = useState("Outlet");
  const navigate = useNavigate();
  const [selectaddress, setSelectedAddress] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLocationSelect = (location) => {
    if (location === "home") {
      setLocationType({ home: true, outlet: false });
      dispatch(handleBookingDetails({ location_type: "home" }));
    }
    if (location === "outlet") {
      setLocationType({ home: false, outlet: true });
      dispatch(handleBookingDetails({ location_type: "outlet" }));
    }
  };

  const handleNext = () => {
    console.log(bookingDetails, "booking-detail")
    if (bookingDetails.location_type == "outlet") {
      if(bookingDetails?.is_advance_payment_on == 0) {
        PaymentSummaryDetails()
      }else {
        navigate("/paymentOption");
      }
    } else {
      if (bookingDetails.location != "Outlet") {
        if(bookingDetails?.is_advance_payment_on == 0) {
          PaymentSummaryDetails()
        }else {
          navigate("/paymentOption");
        }
      } else {
        toast.error(t("common.no_address_selected"));
      }
    }
  };

  const PaymentSummaryDetails = () => {
    const formData = new FormData();
    formData.append("country_id", 103);
    formData.append(
      "total_price",
      serviceDetails.offer_price != ""
        ? serviceDetails.offer_price
        : serviceDetails.price
    );
    formData.append("sp_id", bookingDetails?.sp_id)

    console.log(formData, "cpp");
    dispatch(handlePaymentSummaryDetails(formData))
      .then((response) => {
        placeOrder({ ...response.payload.result.data })
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
        // setLoading(false);
      });
  };

  
  const placeOrder = (payment) => {
    let body = {
      ...serviceDetails,
      ...bookingDetails,
      payment_summary: JSON.stringify(payment),
      total_price: payment?.total,
      service_id: serviceDetails?.id,
      payment_type: 2,
    };

      dispatch(createBooking(body))
        .then((response) => {
          console.log(response, "booked");
          if (response.meta.rejectedWithValue) {
            console.log("first option in if")
            toast.error(response.payload.message);
          } else {
            toast.success(response.payload.message);
            console.log("success")
            navigate("/booking-confirmed", {
              state: {
                bookingDetails,
                serviceDetails,
              },
            });
            dispatch(handleResetBookingDetails());
          }
        })
        .catch((err) => console.log("Booking Err", err));
  };

  // if (!bookingDetails.car_id) {
  //   return navigate(-1);
  // }

  return (
    <>
      <section className="container service-details">
        <div className="service-details-title">
          <i
            class="fa fa-angle-left"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <span className="service-details-title-text">
            {t("common.location")}
          </span>
        </div>

        <div className="service-details-body">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="">
                <img
                  className="service-details-img"
                  src={serviceDetails?.service_image_url}
                  alt=""
                />
              </div>
              <div className="service-details-information marginTop-19">
                <div className="service-details-info">
                  <h6 className="service-details-name text-capitalize">
                    {serviceDetails?.service_name}
                  </h6>
                  <p className="service-details-description text-capitalize">
                    {serviceDetails?.description}
                  </p>
                </div>
                <div className="service-details-price-box">
                  {serviceDetails?.offer_price ? (
                    <>
                      <span className="service-details-mrp">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.price ? (
                    <>
                      <span className="service-details-price">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.offer_discount_price ? (
                    <>
                      <span className="service-details-save-price">
                        {t("common.save")} {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_discount_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="service-details-time">
                  <img src={clock} alt="" width={15} height={15} />
                  <span className="time">
                    {serviceDetails?.duration} {t("common.minutes")}.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-7 ">
              <div className="choose-provider address-options">
                <div className="schedule-title-box">
                  <div>
                    <span className="schedule-box p-0">
                      {t("common.location")} & {t("common.address")}
                    </span>
                  </div>
                </div>
                <div className="choose-body">
                  <div className="choose-body-title">
                    <span className="choose-car-here p-0">
                      {t("common.choose_location")}
                    </span>
                  </div>
                  <div className="pr-3 pb-3 pl-3">
                    <div className="address-option-list">
                      <div
                        style={{ cursor: "pointer" }}
                        className={`${
                          bookingDetails.location_type == "outlet"
                            ? "address-option-item-active"
                            : "address-option-item"
                        }`}
                        onClick={() => {
                          dispatch(
                            handleBookingDetails({
                              location: "Outlet",
                              address_id: "",
                              location_type: "outlet",
                            })
                          );
                        }}
                      >
                        <img
                          width={55}
                          height={55}
                          src={require("../../Assets/img/flat.png")}
                          alt=""
                        />
                        <span
                          className={`${
                            bookingDetails.location_type == "outlet"
                              ? "address-option-item-text-active"
                              : "address-option-item-text"
                          }`}
                        >
                          {t("common.at_outlet")}
                        </span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className={`${
                          bookingDetails?.location_type == "home"
                            ? "address-option-item-active"
                            : "address-option-item"
                        }`}
                        onClick={() => {
                          dispatch(
                            handleBookingDetails({
                              location_type: "home",
                            })
                          );
                        }}
                      >
                        <img
                          width={55}
                          height={55}
                          src={require("../../Assets/img/home.png")}
                          alt=""
                        />
                        <span
                          className={`${
                            bookingDetails.location_type === "Home"
                              ? "address-option-item-text-active"
                              : "address-option-item-text"
                          }`}
                        >
                          {t("common.at_home")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {bookingDetails.location_type == "home" ? (
                  <>
                    <div className="p-1"></div>
                    <div className="select-address">
                      <h3 className="select-address-Saved">
                        {t("common.saved_address")}
                      </h3>
                      <h6
                        className="select-address-Add"
                        onClick={() => navigate("/myProfile?page=address")}
                        style={{ cursor: "pointer" }}
                      >
                        + {t("common.add_new_address")}
                      </h6>
                    </div>
                    <div className="address-list">
                      {user_address &&
                        user_address.length > 0 &&
                        user_address?.map((list, key) => {
                          return (
                            <>
                              {
                                <div
                                  className={`${
                                    bookingDetails?.location === list.location
                                      ? "address-item address-item-active"
                                      : ""
                                  } address-item`}
                                >
                                  <div className="d-flex align-items-center radio--box">
                                    <div
                                      className="radio-body"
                                      onClick={() => {
                                        setSelectedAddress(key);
                                        dispatch(
                                          handleBookingDetails({
                                            location: list.location,
                                            address_id: list.id,
                                            country_id:
                                              user?.country_id || "103",
                                            address_type: list.address_type,
                                          })
                                        );
                                      }}
                                    >
                                      {bookingDetails?.location ===
                                      list.location ? (
                                        <>
                                          {" "}
                                          <div className="radio-dot"></div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="address-info">
                                      <h3 className="address-title text-capitalize ">
                                        {list.address_type}
                                      </h3>
                                      <p className="address-text text-capitalize">
                                        {list.block_shop_no} , {list.location}
                                      </p>
                                      {/* <h6 className='address-mobile'>Mo : +91 8141000000</h6> */}
                                    </div>
                                  </div>
                                  {/* <img width={30} height={30} src={require('../../Assets/img/menu.png')} alt="" /> */}
                                </div>
                              }
                            </>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {user_address && user_address.length === 0 && (
                  <strong>{t("common.no_address")}</strong>
                )}
                {bookingDetails?.location_type && bookingDetails?.location && (
                  <div className="choose-provider-footer">
                    <span className="choose-provider-price">
                      {t("common.total")} : ₹ {serviceDetails?.price}
                    </span>
                    {/* <Link> */}
                    <button onClick={handleNext} className="next-button">
                      {t("common.continue")}
                    </button>
                    {/* </Link> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
