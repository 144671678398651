import React, { useEffect, useState } from "react";
import "./ServiceDetail.scss";
import clock from "../../Assets/img/clock.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addServiceToFavorite,
  listFavorite,
  removeServiceToFavorite,
  isLoggedIn,
} from "../../Slices/auth";

import { getServiceDetails } from "../../Slices/home";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Alert, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

const ServiceDetail = () => {
  const dispatch = useDispatch();
  const { user, user_car_id_default, favouriteList } = useSelector(
    (state) => state.auth
  );
  const { serviceDetails, isLoadingBooking } = useSelector(
    (state) => state.home
  );
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      window.scrollTo(0, 0);
      console.log(user);
    } else {
      navigate("/login");
      toast.error("You Are not Login Plz Login.");
    }
  }, []);

  const handleAddToFavorite = (serviceId) => {
    const data = {
      service_id: serviceId,
      country_id: user.country_id,
      car_id: user_car_id_default,
    };
    if (isLoggedIn) {
      dispatch(addServiceToFavorite({ service_id: serviceId }))
        .then((response) => {
          toast.success(response.payload.message);
          dispatch(listFavorite());
          dispatch(getServiceDetails(data));
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      toast.info("Login for Add to Favorite");
    }
  };

  const handleRemoveFavorite = (serviceId) => {
    const data = {
      service_id: serviceId,
      country_id: "103",
      car_id: user_car_id_default,
    };
    if (isLoggedIn) {
      dispatch(removeServiceToFavorite(serviceId))
        .then((response) => {
          toast.info(response.payload.message);
          dispatch(listFavorite());
          dispatch(getServiceDetails(data));
        })
        .catch((err) => toast.error(err));
    } else {
      toast.info("Login for Add to Favorite");
    }
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {/* New Design */}
      <section className="container service-details">
        <div className="service-details-title">
          <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
          <span className="service-details-title-text">
            {t("common.service_detail")}
          </span>
        </div>
        <div className="service-details-body">
          <div className="row">
            {!isLoadingBooking ? (
              <>
                <div className="col-12 col-lg-5">
                  <div className="">
                    <img
                      className="service-details-img"
                      src={serviceDetails?.service_image_url}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-7 service-details-information">
                  <div className="service-details-info">
                    <h6 className="service-details-name">
                      {serviceDetails?.service_name}
                    </h6>
                    <p className="service-details-description">
                      {serviceDetails?.description}
                    </p>
                  </div>
                  <div className="service-details-price-box">
                    {serviceDetails?.offer_price ? (
                      <>
                        <span className="service-details-mrp">
                          {serviceDetails?.currency?.symbol}{" "}
                          {serviceDetails?.offer_price}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {serviceDetails?.price ? (
                      <>
                        <span className="service-details-price">
                          {serviceDetails?.currency?.symbol}{" "}
                          {serviceDetails?.price}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {serviceDetails?.offer_discount_price ? (
                      <>
                        <span className="service-details-save-price">
                          Save {serviceDetails?.currency?.symbol}{" "}
                          {serviceDetails?.offer_discount_price}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="service-details-time">
                    <img src={clock} alt="" width={15} height={15} />
                    <span className="time">
                      {serviceDetails?.duration} {t("common.minutes")}.{" "}
                    </span>
                  </div>
                  <div className="service-details-book-box">
                    <Link to={"/ChooseProvider"}>
                      <button className="book-button">
                        {" "}
                        {t("common.book_now")}{" "}
                      </button>
                    </Link>
                    <div className="wish-list-box">
                      <div>
                        {
                          // serviceDetails &&  isFavourite() ?
                          serviceDetails && serviceDetails.is_favorite ? (
                            <>
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  handleRemoveFavorite(serviceDetails.id);
                                }}
                              >
                                <img
                                  width={30}
                                  style={{ cursor: "pointer" }}
                                  src={require("../../Assets/img/active-heart.png")}
                                  alt=""
                                />{" "}
                                {/* <span className="add-to-Favourites-text">
                                  {" "}
                                  {t("common.favourite")}
                                </span> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  handleAddToFavorite(serviceDetails.id);
                                }}
                              >
                                <img
                                  width={30}
                                  style={{ cursor: "pointer" }}
                                  src={require("../../Assets/img/heart.png")}
                                  alt=""
                                />{" "}
                                {/* <span className="add-to-Favourites-text">
                                  {t("common.add_to_favourite")}
                                </span> */}
                              </div>
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  {serviceDetails?.services_includes ? (
                    <>
                      <div className="service-details-more-info">
                        <h4 className="service-details-more-info-title">
                          {t("common.service_includes")}
                        </h4>
                        <div className="service-details-more-sub-info">
                          {serviceDetails?.services_includes.map(
                            (item, index) => {
                              return (
                                <>
                                  <div key={index}>
                                    <h5 className="sub-tltle">
                                      {item.attribute_name.attribute_name}
                                    </h5>
                                    <p className="sub-dec">
                                      {item.product_attribute_text}
                                    </p>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col-12 col-lg-5">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={500}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                </div>
                <div className="col-12 col-lg-7 service-details-information">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={500}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetail;
