import React, { useEffect, useState } from "react";
import "./Landing.scss";

import star from "../../Assets/img/drop_dwan_arrow.png";
import loction_icon12 from "../../Assets/img/loction_icon12.png";
import Geosuggest from "react-geosuggest";
import { useDispatch, useSelector } from "react-redux";
import { getBrands, setLandingDetails } from "../../Slices/home";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Landingpage = () => {
  const [visibility, setVisibility] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  // const [search, setSearch] = useState("");
  const { t, i18n } = useTranslation();

  const { carBrand, carFuel } = useSelector((state) => state.home);
  // console.log(carBrand,"carBrand")
  const [visibility1, setVisibility1] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState("");
  // const [search, setSearch] = useState("");

  const [active, setActive] = useState("item");
  const handleClick = (e) => {
    setActive(e);
  };
  useEffect(() => {
    dispatch(getBrands());
  }, []);
  const [address, setAddress] = useState(null);
  const dispatch = useDispatch();

  const getLanLngForAddress = (e) => {
    if (e !== undefined) {
      let results = e.gmaps.hasOwnProperty("address_components");
      if (results) {
        var city = "";
        var state = "";
        var country = "";
        var postal_code = "";
        var block_no = "";

        let results = e.gmaps.address_components;

        for (var i = 0; i < results.length; i++) {
          if (results[i].types[0] === "country") {
            country = results[i].long_name;
          }
          if (results[i].types[0] === "administrative_area_level_1") {
            state = results[i].long_name;
          }
          if (results[i].types[0] === "locality") {
            city = results[i].long_name;
          }
          if (results[i].types[0] === "postal_code") {
            postal_code = results[i].long_name;
          }
          if (results[i].types[0] === "sublocality_level_1") {
            block_no = results[i].long_name;
          }
        }
        setAddress({
          ...address,
          latitude: e.location.lat.toString(),
          longitude: e.location.lng.toString(),
          block_shop_no: block_no,
          location: e.label,
          city: city,
          state: state,
          country: country,
          pin_code: postal_code,
        });
      }
    } else {
      setAddress({
        ...address,
        city: "",
        state: "",
        country: "",
        pin_code: "",
      });
    }
  };

  const handleLandingProcess = () => {
    if (address) {
      if (selectedOption1) {
        if (active) {
          dispatch(
            setLandingDetails({
              location: address,
              car_brand: selectedOption1,
              car_type: active,
              country: address.country,
            })
          );
        } else {
          toast.error("Type Feild Is Must");
        }
      } else {
        toast.error("Brand Selection Is Must");
      }
    } else {
      toast.error("Location Is Must");
    }
  };
  useEffect(() => {
    dispatch(getBrands());
  }, []);

  // console.log(address,"address")

  return (
    <div className="best_services_wrapper">
      <div className="best_services_wrap">
        <div className="best_services_block">
          <div className="best_services_sec">
            <h2 className="explore-services">
              {t("common.explore_best_service_near_you")}
            </h2>
            <div className="location_select">
              <p className="label">{t("common.your_location")}</p>
              <Geosuggest
                placeholder={"Enter Location"}
                initialValue={address?.location && address?.location}
                onSuggestSelect={(e) => getLanLngForAddress(e)}
                onChange={(e) => {
                  setAddress({ ...address, location: e });
                }}
                required
              />
            </div>

            <div className="location_select">
              <p className="label">{t("common.car_brand")}</p>
              <div
                className="select"
                onClick={(e) => {
                  setVisibility1(!visibility1);
                  e.currentTarget.children[0].children[1].append =
                    visibility ? <img src={star} /> : <img src={star} />;
                }}
              >
                <div className="selected-option">
                  <span title={selectedOption1?.car_name || "Select Car"}>
                    {selectedOption1?.car_name || "Select Car"}
                  </span>
                  <i className="material-icons">
                    <img src={star} />
                  </i>
                </div>
                {visibility1 && (
                  <div className="options">
                    <ul>
                      <li
                        data-value="default"
                        onClick={() => setSelectedOption1("")}
                      >
                        {t("common.select_car")}
                      </li>
                      {carBrand?.map((option1, index) => (
                        <li
                          key={index}
                          className={
                            selectedOption1?.car_name === option1?.car_name
                              ? "active-option"
                              : null
                          }
                          onClick={() => setSelectedOption1(option1)}
                        >
                          {option1?.car_name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="car_type_block">
              <p className="label">{t("common.car_type")}</p>
              <ul>
                <li>
                  <a
                    href="#0"
                    className={`${active === "suv" ? "active" : ""} car-type`}
                    onClick={() => handleClick("suv")}
                  >
                    SUV
                  </a>
                </li>
                <li>
                  <a
                    href="#0"
                    className={`${
                      active === "Hatchback" ? "active" : ""
                    } car-type`}
                    onClick={() => handleClick("Hatchback")}
                  >
                    Hatchback
                  </a>
                </li>
                <li>
                  <a
                    href="#0"
                    className={`${active === "Sedan" ? "active" : ""} car-type`}
                    onClick={() => handleClick("Sedan")}
                  >
                    Sedan
                  </a>
                </li>
              </ul>
            </div>

            <div className="done_btn">
              <a
                href="#0"
                className="done-btn-text"
                onClick={handleLandingProcess}
              >
                {t("common.done")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
