import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

let interval;
function Progressbar() {
  const { isLoading } = useSelector((state) => state.home);
  const [width, setWidth] = useState(0);

  const handleProgressBar = () => {
    console.log(width);
    setWidth((width + 45) % 100);
  };

  useEffect(() => {
    if (isLoading) {
      interval = setInterval(() => {
        handleProgressBar();
      }, 300);
    }
    return () => {
      clearInterval(interval);
      setWidth(0);
    };
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated bg-success animation_progress"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${width}%` }}
          ></div>
        </div>
      )}
    </>
  );
}

export default Progressbar;
