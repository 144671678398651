import axios from "axios";
// import config from "../Config/Config.json"
import authHeader from "./auth-header";
let config = {
  CARWASH_URL: process.env.REACT_APP_BASE_URL,
};
const getCarsBrand = () => {
  return axios.get(config.CARWASH_URL + "/v1/get-car-list");
};

const getCarsFuel = () => {
  return axios.get(config.CARWASH_URL + "/v1/fuel/list");
};

const getCategories = (data) => {
  return axios.post(
    config.CARWASH_URL + "/v1/category/list",
    data.type === "sub" ? data.action : ""
  );
};

const getCMSPages = () => {
  return axios.get(config.CARWASH_URL + "/v1/get-cms-pages");
};

const getDashboardSection = (data) => {
  // console.log(data,"123456789")
  return axios.post(config.CARWASH_URL + "/v1/dashboard", data);
};

const getDashboardSectionById = (data) => {
  return axios.post(
    config.CARWASH_URL + `/v1/dashboard/sections/${data.id}`,
    data.data
  );
};

const getServiceDetails = (data) => {
  console.log(data, "data");
  return axios.get(
    config.CARWASH_URL +
      `/v1/service/detail?service_id=${data.service_id}&country_id=${data.country_id}&car_id=${data.car_id}`
  );
};
const getPackageDetails = (data) => {
  return axios.get(
    config.CARWASH_URL +
      `/v1/package/detail/${data.package_id}?country_id=${data.country_id}&car_id=${data.car_id}`,
    { headers: authHeader() }
  );
};
const getServiceDetailsWithoutAuth = (data) => {
  return axios.get(
    config.CARWASH_URL +
      `/v1/service/detail?service_id=${data.service_id}&country_id=${data.country_id}`
  );
};

const getCMSContent = (data) => {
  return axios.get(config.CARWASH_URL + `/v1/get-cms?slug=${data}`);
};

const getAllOffers = (data) => {
  return axios.get(config.CARWASH_URL + `/v1/offer/list?country_id=${data}`);
};
const getAllCoupons = (data) => {
  return axios.get(config.CARWASH_URL + `/v1/coupon/list?country_id=${data}`);
};

const getOfferById = (data) => {
  return axios.get(
    config.CARWASH_URL +
      `/v1/offer/detail?country_id=${data.country_id}&offer_id=${data.offer_id}`
  );
};

const getCarType = (data) => {
  return axios.get(config.CARWASH_URL + `/v1/car_type_list`);
};

const getCategoryListById = (data) => {
  return axios.post(config.CARWASH_URL + `/v1/category/list`, data);
};

const search = (data) => {
  return axios.post(config.CARWASH_URL + `/v1/search`, data);
};
const homeServices = {
  getCarsBrand,
  getCarsFuel,
  getCategories,
  getCMSPages,
  getDashboardSection,
  getServiceDetails,
  getPackageDetails,
  getServiceDetailsWithoutAuth,
  getDashboardSectionById,
  getCMSContent,
  getAllOffers,
  getAllCoupons,
  getOfferById,
  getCarType,
  getCategoryListById,
  search,
};

export default homeServices;
