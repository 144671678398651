import React, { useState, useEffect } from "react";
import "./MyProfile.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import icon1 from "../../Assets/img/icons8-user.png";
import icon2 from "../../Assets/img/icons8-maintenance.png";
import icon3 from "../../Assets/img/icons8-heart.png";
import icon4 from "../../Assets/img/icons8-home_address.png";
import icon7 from "../../Assets/img/icons8-help.png";
import icon6 from "../../Assets/img/icons8-bill.png";
import icon8 from "../../Assets/img/Path.png";
import MyFavorite from "./components/MyFavorite";
import MyBookings from "../MyBookings/MyBookings";
import MyPackage from "../MyPackage/MyPackage";
import Profile from "./components/Profile";
import PramotionCar from "../PramotionCar/OfferCar";
import MyAddress from "./components/MyAddress";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, logoutUser, showLogin } from "../../Slices/auth";
import { toast } from "react-toastify";
import MyCars from "./components/MyCars";
import {
  getAllCoupons,
  getAllOffers,
  setLandingDetails,
} from "../../Slices/home";
import ProfileSupport from "../ProfileSupport/ProfileSupport";
import { useTranslation } from "react-i18next";

const MyProfile = () => {
  const [showComp, setShowComp] = useState({});
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [profile, setProfile] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getAllCoupons({ country_id: user?.country_id || "103" }));
    dispatch(getAllOffers({ country_id: user?.country_id || "103" }));

    if (isLoggedIn) {
      if (searchParams.get("page") === "profile") {
        getProfile();
      }
      handleShowComp(true, searchParams.get("page"));
    } else {
      toast.error(t("common.please_login_to_continue"));
      navigate("/");
      dispatch(showLogin(true));
    }
    // eslint-disable-next-line
  }, [searchParams.get("page")]);

  const handleShowComp = (state, name) => {
    switch (name) {
      case "profile":
        setShowComp({
          ...showComp,
          profile: state,
          booking: false,
          packages: false,
          favorites: false,
          cars: false,
          address: false,
          pramotion: false,
          support: false,
        });
        searchParams.set("page", "profile");
        setSearchParams(searchParams);
        break;
      case "booking":
        setShowComp({
          ...showComp,
          profile: false,
          booking: state,
          packages: false,
          cars: false,
          favorites: false,
          address: false,
          pramotion: false,
          support: false,
        });
        searchParams.set("page", "booking");
        setSearchParams(searchParams);
        break;
      case "packages":
        setShowComp({
          ...showComp,
          profile: false,
          booking: false,
          packages: state,
          favorites: false,
          cars: false,
          address: false,
          pramotion: false,
          support: false,
        });
        searchParams.set("page", "packages");
        setSearchParams(searchParams);
        break;
      case "favorites":
        setShowComp({
          ...showComp,
          profile: false,
          booking: false,
          packages: false,
          favorites: state,
          cars: false,
          address: false,
          pramotion: false,
          support: false,
        });
        searchParams.set("page", "favorites");
        setSearchParams(searchParams);
        break;
      case "cars":
        setShowComp({
          ...showComp,
          profile: false,
          booking: false,
          packages: false,
          favorites: false,
          cars: state,
          address: false,
          pramotion: false,
          support: false,
        });
        searchParams.set("page", "cars");
        setSearchParams(searchParams);
        break;
      case "address":
        setShowComp({
          ...showComp,
          profile: false,
          booking: false,
          packages: false,
          favorites: false,
          cars: false,
          address: state,
          pramotion: false,
          support: false,
        });
        searchParams.set("page", "address");
        setSearchParams(searchParams);
        break;
      case "pramotion":
        setShowComp({
          ...showComp,
          profile: false,
          booking: false,
          packages: false,
          favorites: false,
          cars: false,
          address: false,
          pramotion: state,
          support: false,
        });
        searchParams.set("page", "pramotion");
        setSearchParams(searchParams);
        break;
      case "support":
        setShowComp({
          ...showComp,
          profile: false,
          booking: false,
          packages: false,
          favorites: false,
          cars: false,
          address: false,
          pramotion: false,
          support: true,
        });
        searchParams.set("page", "support");
        setSearchParams(searchParams);
        break;
      default:
        return;
    }
  };

  const getProfile = () => {
    dispatch(getUserProfile())
      .then((response) => {
        if (response.payload.status === 200) {
          setProfile(response.payload.data.result.userData);
          setShowComp({ ...showComp, profile: true });
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  };

  const handleLogout = () => {
    dispatch(logoutUser())
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message, {
            position: "top-center",
          });
          dispatch(
            setLandingDetails({
              location: null,
              car_brand: null,
              car_type: null,
            })
          );
          setTimeout(() => {
            navigate("/");
          }, 1500);
        } else {
          toast.error(response.payload.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <>
      <div className="spaceSect  myProfileOut container">
        <div className="row myProfileIn">
          <div className="profList">
            <div className="profListIn">
              <div className="nameNum">
                <h3>{user.name}</h3>
                <span>{`${user.country_code} ${user.mobile_no}`} </span>
              </div>
              <ul className="listName">
                <li onClick={() => handleShowComp(true, "profile")}>
                  <span
                    className={`showProfile ${
                      showComp.profile && "activeMenu"
                    }`}
                  >
                    <i>
                      <img src={icon1} alt="icon" />
                    </i>
                    {t("common.my_profile")}
                  </span>
                </li>
                <li onClick={() => handleShowComp(true, "booking")}>
                  <span
                    className={`showBooking ${
                      showComp.booking && "activeMenu"
                    }`}
                  >
                    <i>
                      {" "}
                      <img src={icon6} alt="icon" />
                    </i>
                    {t("common.my_bookings")}
                  </span>
                </li>
                <li onClick={() => handleShowComp(true, "packages")}>
                  <span
                    className={`showFavorite ${
                      showComp.packages && "activeMenu"
                    }`}
                  >
                    <i>
                      <img src={icon3} alt="icon" />
                    </i>
                    {t("common.my_packages")}
                  </span>
                </li>
                <li onClick={() => handleShowComp(true, "cars")}>
                  <span
                    className={`showFavorite ${showComp.cars && "activeMenu"}`}
                  >
                    <i>
                      <img src={icon3} alt="icon" />
                    </i>
                    {t("common.my_cars")}
                  </span>
                </li>
                <li onClick={() => handleShowComp(true, "favorites")}>
                  <span
                    className={`showFavorite ${
                      showComp.favorites && "activeMenu"
                    }`}
                  >
                    <i>
                      <img src={icon3} alt="icon" />
                    </i>
                    {t("common.my_favourites")}
                  </span>
                </li>
                <li onClick={() => handleShowComp(true, "pramotion")}>
                  <span
                    className={`showFavorite ${
                      showComp.pramotion && "activeMenu"
                    }`}
                  >
                    <i>
                      <img src={icon3} alt="icon" />
                    </i>
                    {t("common.promotion")}
                  </span>
                </li>
                <li onClick={() => handleShowComp(true, "address")}>
                  <span
                    className={`showFavorite ${
                      showComp.address && "activeMenu"
                    }`}
                  >
                    <i>
                      <img src={icon4} alt="icon" />
                    </i>
                    {t("common.my_address")}
                  </span>
                </li>
                <li onClick={() => navigate("/cms?slug=privacy-policy")}>
                  <span>
                    <i>
                      {" "}
                      <img src={icon7} />
                    </i>
                    {t("common.help")}
                  </span>
                </li>
                <li onClick={() => navigate("/cms?slug=privacy-policy")}>
                  <span
                    className={`showFavorite ${
                      showComp.support && "activeMenu"
                    }`}
                  >
                    <i>
                      <img src={icon2} />
                    </i>
                    {t("common.support")}
                  </span>
                </li>
              </ul>

              <div className="logoutName">
                <ul className="listName">
                  <li style={{ cursor: "pointer" }} onClick={handleLogout}>
                    <span>
                      <i className="logoUT">
                        <img src={icon8} />
                      </i>
                      {t("common.logout")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="profDetail">
            {showComp.profile && (
              <Profile
                loading={loading}
                setLoading={setLoading}
                profile={profile}
                setProfile={setProfile}
                FetchData={getProfile}
              />
            )}
            {showComp.address && <MyAddress />}
            {showComp.favorites && <MyFavorite />}
            {showComp.booking && <MyBookings />}
            {showComp.packages && <MyPackage />}
            {showComp.cars && <MyCars />}
            {showComp.pramotion && <PramotionCar />}
            {showComp.support && <ProfileSupport />}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
