import React, { useEffect } from "react";
import "./Cms.scss";
import banner from "../../Assets/img/terms-conditions4.jpeg";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <section className="bodystruct">
        <div className="bannerDesign">
          <img src={banner} />
          <div className="opacity-medium bg-extra-dark-gray"></div>
          <div className="textPosi">
            <div className="row ">
              <h1 className="alt-font text-white font-weight-500 letter-spacing-minus-1 mb-0 font-family-heebo">
                Contact Us
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="big-section bg-light-gray">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 text-center titleText">
              <span className=""></span>
              <h5 className="font-family-heebo">Fill the Form</h5>
            </div>
            <div className="col-12 col-md-8 text-center titleText">
              <form>
                <div className="formInText">
                  <div className="inpuWidth">
                    <label className="font-family-heebo">Full Name</label>
                    <input
                      type="text"
                      name="name"
                      className="font-family-heebo"
                    />
                  </div>
                  <div className="inpuWidth">
                    <label className="font-family-heebo">Last Name</label>
                    <input
                      type="text"
                      name="name"
                      className="font-family-heebo"
                    />
                  </div>
                </div>
                <div className="formInText">
                  <div className="inpuWidth">
                    <div className="formInText mb-0 pb-0">
                      <label className="font-family-heebo">Phone Number</label>
                      <div className="phLeft">
                        <span className="font-family-heebo"> + 91 </span>
                      </div>
                      <div className="pHright">
                        <input
                          type="tel"
                          maxLength={10}
                          name="mobile_no"
                          className="font-family-heebo"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="inpuWidth">
                    <label className="font-family-heebo">
                      Email (Optional)
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="font-family-heebo"
                    />
                  </div>
                </div>
                <div className="subscript">
                  <p className="subIn font-family-heebo">
                    by proceeding, you agree to brand’s{" "}
                    <a href="" className="font-family-heebo">
                      conditions of uses{" "}
                    </a>{" "}
                    and{" "}
                    <a href="" className="font-family-heebo">
                      privacy policy
                    </a>{" "}
                  </p>
                </div>
                <div className="buttonsec">
                  <input
                    type="button"
                    className="font-family-heebo"
                    value="Sign In"
                  />
                </div>
                <div className="textCenter wid100 skipLogin">
                  <a href="" className="font-family-heebo">
                    Skip login & go to dashboard
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="big-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 text-center titleText">
              <span className="font-family-heebo">No helpful answer? </span>
              <h5 className="font-family-heebo">Here are some options</h5>
            </div>
            <div className="col-12 col-lg-6 col-md-9  ansCard">
              <div className="feature-box feature-box-left-icon border-radius-4px bg-neon-orange overflow-hidden padding-4-rem-all">
                <div className="feature-box-icon">
                  <i className="line-icon-Hipster-Headphones fa fa-phone icon-extra-large text-white"></i>
                </div>
                <div className="feature-box-content padding-15px-left">
                  <span className="alt-font text-extra-medium font-weight-500 margin-10px-bottom d-block text-white font-family-heebo">
                    Help desk support
                  </span>
                  <p className="text-white opacity-6 w-90 lg-w-100 margin-20px-bottom font-family-heebo">
                    Lorem ipsum is simply dummy text of the and typesetting
                    industry lorem ipsum.
                  </p>
                  <a
                    href="#"
                    className="btn btn-link btn-large text-fast-blue font-family-heebo"
                  >
                    Browse topics
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-md-9  ansCard">
              <div className="feature-box feature-box-left-icon border-radius-4px bg-neon-orange overflow-hidden padding-4-rem-all">
                <div className="feature-box-icon">
                  <i className="line-icon-Hipster-Headphones fa fa-users icon-extra-large text-white"></i>
                </div>
                <div className="feature-box-content padding-15px-left">
                  <span className="alt-font text-extra-medium font-weight-500 margin-10px-bottom d-block text-white font-family-heebo">
                    Help desk support
                  </span>
                  <p className="text-white opacity-6 w-90 lg-w-100 margin-20px-bottom font-family-heebo">
                    Lorem ipsum is simply dummy text of the and typesetting
                    industry lorem ipsum.
                  </p>
                  <a
                    href="#"
                    className="btn btn-link btn-large text-fast-blue font-family-heebo"
                  >
                    Browse topics
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="big-section bg-light-gray contactImg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 text-center titleText">
              <span className="font-family-heebo">
                Fill out the form and we’ll be in touch soon!{" "}
              </span>
              <h5 className="font-family-heebo">
                On Demand offices round the world
              </h5>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 justify-content-center aboutBoxes">
              <div className="col col-md-4 margin-30px-bottom xs-margin-15px-bottom">
                <div className="feature-box h-100 feature-box-left-icon border-radius-5px   box-shadow-small feature-box-dark-hover overflow-hidden padding-4-rem-all">
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray font-family-heebo">
                      Canada Office
                    </span>
                    <p className="font-family-heebo">
                      600-890 West Pender Street Vancouver, V6C 1J9 British
                      Columbia Canada
                    </p>
                  </div>
                  <div className="feature-box-overlay bg-gradient-magenta-orange-2"></div>
                </div>
              </div>
              <div className="col col-md-4 margin-30px-bottom xs-margin-15px-bottom">
                <div className="feature-box h-100 feature-box-left-icon border-radius-5px   box-shadow-small feature-box-dark-hover overflow-hidden padding-4-rem-all">
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray font-family-heebo">
                      India Office
                    </span>
                    <p className="font-family-heebo">
                      B-214, Empire Business Hub Science City Rd, Ahmedabad
                      Gujarat, India
                    </p>
                  </div>
                  <div className="feature-box-overlay bg-gradient-magenta-orange-2"></div>
                </div>
              </div>
              <div className="col col-md-4 md-margin-30px-bottom xs-margin-15px-bottom">
                <div className="feature-box h-100 feature-box-left-icon border-radius-5px   box-shadow-small feature-box-dark-hover overflow-hidden padding-4-rem-all">
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray font-family-heebo">
                      USA Office
                    </span>
                    <p className="font-family-heebo">
                      8015 41st ave Apt 214, Elmhurst New York 11373, USA
                    </p>
                  </div>
                  <div className="feature-box-overlay bg-gradient-magenta-orange-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="map">
        <iframe
          className="w-100 h-100 filter-grayscale-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.6560651353984!2d72.51114991419142!3d23.073067584927585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b549da07121%3A0x6f3e71bfd6b86647!2sNectarBits%20Pvt%20Ltd%20%3A%20Top%20leading%20%26%20best%20IT%20company%20in%20ahmedabad!5e0!3m2!1sen!2sin!4v1660043798700!5m2!1sen!2sin"
        ></iframe>
      </section>
    </>
  );
};

export default ContactUs;
