import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import car from "../../Assets/img/carIcon.png";
import "./PackageBookingConfirmed.scss";
import { useSelector } from "react-redux";

export default function PackageBookingConfirmed() {
  const navigate = useNavigate();
  const data = useLocation();
  const { packageBookingDetails } = useSelector((state) => state.home);

  return (
    <>
      <section className="container conFirm-booking service-details">
        <div className="service-details-title">
          <i className="fa fa-angle-left" onClick={() => navigate(-1)}></i>
          <span className="service-details-title-text">Booking Confirmed</span>
        </div>

        <div className="row">
          <div className="col-12 col-lg-5 img-box">
            <img
              className="conFirm-booking-img"
              src={require("../../Assets/img/booking.png")}
              alt=""
            />
            <div className="tag-box  mt-5">
              <h3 className="tag-1 m-0">Thank You!!!</h3>
              <h3 className="tag-2 m-0">Booking Confirmed</h3>
            </div>
          </div>
          <div className="col-12 col-lg-7 ">
            <div className="booking-info-box">
              {
                <>
                  <div className="booking-info">
                    <img
                      className="booking-service-img"
                      src={data.state.packageDetail.service.service_image_url}
                      alt=""
                    />
                    <div className="booking-item">
                      <h3 className="booking-service-name">
                        {data.state.packageDetail.service.service_name}
                      </h3>
                      <p className="booking-service-info">
                        {data.state.packageDetail.service.description}
                      </p>
                    </div>
                  </div>
                </>
              }
              <Link to={"/myProfile?page=packages"}>
                {" "}
                <button className="my-booking-button mt-0">
                  Go to My Bookings
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
