import React from "react";
import "./CatgoryBox.scss";
import catImg2 from "../../../Assets/img/tire.png";
import { Link, useNavigate } from "react-router-dom";
const CatgoryBox = ({ category, isSub }) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    if (isSub) navigate(`/subServices/${category.id}`);
  };
  return (
    <>
      <div className="col-md-2 col-12 col-sm-3 CatFilterIn">
        <div
          className="catgoryBoxOut"
          style={{ cursor: "pointer" }}
          onClick={handleRedirect}
        >
          <div className="catgoryBoxIn">
            <img
              src={category.category_image_url}
              className="catImg"
              alt={category.category_name}
            />
          </div>
          <div className="catgoryText">
            <h4 className="cat-item-name">{category.category_name}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatgoryBox;
