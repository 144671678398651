import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../Slices/auth";
import homeReducer from "../Slices/home";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

// const persistConfigAuth = { key: "greenshine", storage, version: 1 };
// const persistedReducerAuth = persistReducer(persistConfigAuth, authReducer);

const persistConfigHome = {
  key: "greenshine_home",
  storage,
  version: 1,
  whitelist: [
    "serviceDetails",
    "landigDetails",
    "packageDetail",
    "bookingDetails",
    "categories",
    "packageBookingDetails",
    "packageServiceBooking",
    "user",
    "dashboardSections",
    "userData",
  ],
};
const persistedReducerHome = persistReducer(persistConfigHome, homeReducer);

const combineReducer = combineReducers({
  auth: authReducer,
  home: persistedReducerHome,
});

const store = configureStore({
  reducer: combineReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
