import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import BASE_URL from "./Config/Config.json";
import axios from "axios";
import "./Assets/scss/Common.scss";
import Home from "./Components/Home";
import AllSpecialities from "./Components/AllSpecialities/AllSpecialities";
import ServiceListing from "./Components/ServiceListing/ServiceListing";
import ServiceSummary from "./Components/ServiceSummary/ServiceSummary";
import MyProfile from "./Components/MyProfile/MyProfile";
import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer/Footer";
import Login from "./Components/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import RegisterForm from "./Components/RegisterForm/RegisterForm";
import Registration from "../src/Components/RegisterForm/Registration";
import OtpForm from "./Components/OtpForm/OtpForm";
import "react-toastify/dist/ReactToastify.css";
import FooterMobileMenu from "./Components/Common/FooterMobileMenu/FooterMobileMenu";
import AboutUs from "./Components/CmsPages/AboutUs";
import { ToastContainer } from "react-toastify";
import SubSpecialities from "./Components/AllSpecialities/SubSpecialities";
import SubServiceListing from "./Components/ServiceListing/SubServiceListing";
import MyAddress from "./Components/MyProfile/components/MyAddress";
import MyBookings from "./Components/MyBookings/MyBookings";
import HelpPage from "./Components/Help&Support/HelpPage";
import { useTranslation } from "react-i18next";
import ServiceDetail from "./Components/ServiceDetail/ServiceDetail";
import ChooseProvider from "./Components/ChooseProvider/ChooseProvider";
import PackageDetailSchedule from "./Components/PackageDetailSchedule/PackageDetailSchedule";
import AddressOptions from "./Components/AddressOptions/AddressOptions";
// import { useTheme } from '@material-ui/core/styles';
// import AboutUs from "./Components/CMSPages/AboutUs";
// import Privacy from './Components/CMSPages/Privacy';
// import Faq from './Components/CMSPages/Faq';

import ServiceDetailChooseCar from "./Components/ServiceDetailChooseCar/ServiceDetailChooseCar";
// import ContactUs from "./Components/CMSPages/ContactUs";
import MyBookingRunning from "./Components/MyBookingUp/MyBookingRunning";
import MyBookRequest from "./Components/MyBookingUp/MyBookRequest";
import PaymentOption from "./Components/PaymentOption/PaymentOption";
import PackageConFirmBooking from "./Components/PackageConFirmBooking/PackageConFirmBooking";
import PackageDetail from "./Components/PackageDetail/PackageDetail";

import ConFirmBooking from "./Components/ConFirmBooking/ConFirmBooking";
import PaymentRemaining from "./Components/PaymentRemaining/PaymentRemaining";
import PackageBooked from "./Components/PackageBooked/PackageBooked";
import PackageListing from "./Components/PackageListing/PackageListing";
import ServiceDetailSchedule from "./Components/ServiceDetailSchedule/ServiceDetailSchedule";
import PackagePaymentOption from "./Components/PackagePaymentOption/PackagePaymentOption";
import PackageBookingConfirmed from "./Components/PackageBookingConfirmed/PackageBookingConfirmed";
import PackageStartDate from "./Components/PackageStartDate/PackageStartDate";
import PackageAddressOptions from "./Components/PackageAddressOptions/PackageAddressOptions";
import BookingScheduleConfirmedPackage from "./Components/BookingScheduleConfirmedPackage/BookingScheduleConfirmedPackage";
import AddAddress from "./Components/AddAddress/AddAddress";
import Progressbar from "./Layouts/Progressbar";
import Landingpage from "./Components/Landing/Landingpage";
import Addcar from "./Components/Addcar/Addcar";
import OfferList from "./Components/OfferList/OfferList";
import Cmd from "./Components/CMS/Cms";
import PackagePurchasedDetail from "./Components/PackagePurchasedDetail/PackagePurchasedDetail";
import { getAllUsersCar, listFavorite } from "./Slices/auth";
import ContactUs from "./Components/CmsPages/ContactUs";
import Cms from "./Components/CMS/Cms";
import RaiseComplain from "./Components/RaiseComplain/RaiseComplain";
axios.defaults.baseURL = BASE_URL.SERVICE_MARKETPLACE;
axios.defaults.headers.post["Content-Type"] = "application/json";

function App() {
  const { showLogin, showRegister, showOTP, isLoggedIn } = useSelector(
    (state) => state.auth
  );
  const [user, setUser] = useState({
    device_token: "FSRSYSYYSY",
    device_type: "ios",
    mobile_no: "",
    isEmailValid: false,
    first_name: "",
    last_name: "",
    email: "",
    country_code: "",
    is_subscribed_news_letter: 1,
    is_subscribed_promotional_offer: 1,
    birth_date: "2000-01-01",
  });
  const initialUserValue = {
    device_token: "FSRSYSYYSY",
    device_type: "ios",
    mobile_no: "",
    country_code: "",
  };
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [location, setLocation] = useState("");
  const { landigDetails } = useSelector((state) => state.home);
  // const { isLoggedIn } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // const theme = useTheme();
  document.body.dir = i18n.dir();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    // theme.direction = i18n.dir();
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    const emailRegex = /\S+@\S+\.\S+/;
    let data = user;
    switch (name) {
      // case 'name':
      //     data.name = value.replace(/[0-9]/g, '');
      //     break;
      case "first_name":
        data.first_name = value.replace(/[0-9]/g, "");
        break;
      case "last_name":
        data.last_name = value.replace(/[0-9]/g, "");
        break;
      case "email":
        if (emailRegex.test(value)) {
          setIsEmailValid(true);
        } else {
          setIsEmailValid(false);
        }
        data.email = value;
        break;
      case "mobile_no":
        data.mobile_no = value.replace(/\D/g, "");
        break;
      case "refer_code":
        data.refer_code = value;
        break;
      default:
        data[name] = value;
        break;
    }
    setUser(() => {
      return { ...user, ...data };
    });
  };

  function getCity(coordinates) {
    var xhr = new XMLHttpRequest();
    var lat = coordinates[0];
    var lng = coordinates[1];

    // Paste your LocationIQ token below.
    fetch(
      `https://nominatim.openstreetmap.org/search?q=${lat},${lng}&format=json&addressdetails=1&limit=1`
    )
      .then((response) => response.json())
      .then((data) => {
        setLocation(
          data[0].address?.state_district + "," + data[0].address?.state
        );
      })
      .catch((error) => {});
    // Geocode.fromLatLng(lat, lng).then(
    //     (response) => {
    //         const address = response.results[0].formatted_address;
    //         console.log(address);
    //     },
    //     (error) => {
    //         console.error(error);
    //     }
    // );
  }
  function getCoordintes() {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos) {
      var crd = pos.coords;
      var lat = crd.latitude.toString();
      var lng = crd.longitude.toString();
      var coordinates = [lat, lng];
      getCity(coordinates);
      return;
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  const validateRoute = () => {
    if (
      landigDetails?.location &&
      landigDetails?.car_brand &&
      landigDetails?.car_type
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getFavoriteList = () => {
    dispatch(listFavorite());
  };
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getAllUsersCar());
      getFavoriteList();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCoordintes();
    dispatch(getAllUsersCar());
  }, [isLoggedIn]);

  // console.log(validateRoute());

  return (
    <Router>
      {/* <div style={{display: "table" }}>
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell",padding: 5}}>
          <button onClick={() => changeLanguage('en')}>English</button>
          </div>
          <div style={{ display: "table-cell",padding: 5}}>
          <button onClick={() => changeLanguage('ar')}>Arabic</button>
            </div>
        </div>
      </div> */}

      <Progressbar />
      <ToastContainer />
      <Header location={location} />
      <Suspense fallback="Loading...">
        {/* {showLogin ? <Login user={user} handleInputChange={handleInputChange} setUser={setUser} initialUserValue={initialUserValue} /> : showOTP ? <OtpForm user={user} handleInputChange={handleInputChange} initialUserValue={initialUserValue} setUser={setUser} /> : "" }
        {showRegister && <RegisterForm user={user} handleInputChange={handleInputChange} setUser={setUser} initialUserValue={initialUserValue} isEmailValid={isEmailValid} />} */}

        {!validateRoute() ? (
          <Routes>
            <Route path="landing" element={<Landingpage />} />
            <Route path="ContactUs" element={<ContactUs />} />
            <Route path="cms/:slug" element={<Cms />} />
            <Route path="*" element={<Navigate to="/landing" />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="allSpecialities" element={<AllSpecialities />} />
            <Route path="subSpecialities/:id" element={<SubSpecialities />} />
            <Route path="serviceListing/:id" element={<ServiceListing />} />
            <Route path="subServices/:id" element={<SubServiceListing />} />
            <Route path="serviceSummary" element={<ServiceSummary />} />
            <Route path="helpPage" element={<HelpPage />} />
            <Route path="cms/:slug" element={<Cms />} />
            <Route path="myProfile" element={<MyProfile />} />
            <Route path="myAddress" element={<MyAddress />} />
            {/* <Route path="cms/:id" element={<Cmd />} /> */}
            <Route path="bokingDetails/:id" element={<MyBookRequest />} />
            <Route path="payment/:id" element={<PaymentRemaining />} />

            <Route path="PackageDetail/:id" element={<PackageDetail />} />
            <Route path="package_booked" element={<PackageBooked />} />

            <Route
              path="login"
              element={
                <Login
                  user={user}
                  handleInputChange={handleInputChange}
                  setUser={setUser}
                  initialUserValue={initialUserValue}
                />
              }
            />
            <Route
              path="registration"
              element={
                <Registration
                  handleInputChange={handleInputChange}
                  user={user}
                  setUser={setUser}
                />
              }
            />
            {/* <Route path='cms/about-us' element={<AboutUs />} />
            <Route path='cms/terms-of-use' element={<ContactUs />} />
            <Route path='cms/privacy-policy' element={<Privacy />} />
            <Route path='cms/policies' element={<Faq />} /> */}
            <Route path="chooseCar" element={<ServiceDetailChooseCar />} />
            <Route path="running" element={<MyBookingRunning />} />
            <Route path="confirm" element={<PackageConFirmBooking />} />
            <Route path="ServiceDetail" element={<ServiceDetail />} />
            <Route path="ChooseProvider" element={<ChooseProvider />} />
            <Route path="ServiceSchedule" element={<ServiceDetailSchedule />} />
            <Route path="address-options" element={<AddressOptions />} />
            <Route path="paymentOption" element={<PaymentOption />} />
            <Route path="booking-confirmed" element={<ConFirmBooking />} />

            <Route path="packageListing" element={<PackageListing />} />
            <Route
              path="packagePurchasedDetail/:id"
              element={<PackagePurchasedDetail />}
            />
            <Route
              path="packageDetailSchedule"
              element={<PackageDetailSchedule />}
            />
            <Route
              path="PackagePaymentOption"
              element={<PackagePaymentOption />}
            />
            <Route
              path="Package-booking-confirmed"
              element={<PackageBookingConfirmed />}
            />
            <Route path="package-start-date" element={<PackageStartDate />} />
            <Route
              path="package-address-options"
              element={<PackageAddressOptions />}
            />
            <Route
              path="booking-schedule-confirmed-package"
              element={<BookingScheduleConfirmedPackage />}
            />
            <Route path="address" element={<AddAddress />} />
            <Route path="Addcar" element={<Addcar />} />
            <Route path="offers/:id" element={<OfferList />} />
            <Route path="ContactUs" element={<ContactUs />} />
            <Route
              path="raise-complain/:bId/:spId"
              element={<RaiseComplain />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </Suspense>
      <FooterMobileMenu />
      <Footer />
    </Router>
  );
}

export default App;

// import { useTranslation, withTranslation, Trans } from 'react-i18next';
// const { t, i18n } = useTranslation();
