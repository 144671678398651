import React from "react";
import "./TopPackages.scss";
import { Link } from "react-router-dom";
import ProductBox from "../ProductBox/ProductBox";
import ProductBoxPackages from "../ProductBox/ProductBoxPackages";
import "react-slick/lib/slider";
import { List } from "react-content-loader";
import Slider from "react-slick";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import PackageBox from "../PackageBox/PackageBox";

const TopPackages = ({
  dashboardSections,
  isDashboardSectionsFetched,
  sections,
}) => {
  // console.log(isDashboardSectionsFetched, "1");
  // console.log(dashboardSections, "2");
  // console.log(sections, "3");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: "pwaSlide",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { t, i18n } = useTranslation();

  return (
    <>
      {sections ? (
        dashboardSections?.length >= 0 ? (
          <>
            {dashboardSections?.length <= 4 ? (
              <>
                <div className="producrCateIn spaceSect container mt-5">
                  {dashboardSections?.length > 0 ? (
                    <>
                      <div className="section-title-box">
                        <h2 className="sectionTitle centerText">
                          {t("common.top_packages")}
                        </h2>
                        <span className="sectionSeeAll centerText">
                          <Link className="see-all-text" to={`/packageListing`}>
                            {t("common.see_all")}
                          </Link>
                        </span>
                      </div>{" "}
                      <hr class="hr" />
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="">
                    <div className="mySlider row">
                      {dashboardSections?.map((packages, key) => (
                        <div className="box-item col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                          <PackageBox packages={packages} key={key} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="producrCateIn spaceSect container mt-5">
                  <div className="section-title-box">
                    <h2 className="sectionTitle centerText">
                      {t("common.top_packages")}
                    </h2>
                    <span className="sectionSeeAll centerText">
                      <Link className="see-all-text" to={`/packageListing`}>
                        {t("common.see_all")}
                      </Link>
                    </span>
                  </div>
                  <hr class="hr" />
                  <div className="container-fluid proItems">
                    <div className="mySlider">
                      <Slider {...settings}>
                        {dashboardSections?.map((packages, key) => (
                          <div className="col-md-rev proBox">
                            <PackageBox packages={packages} key={key} />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default TopPackages;
