import React, { useEffect } from "react";
import "./HelpPage.scss";
// import { getProfile, setShowAddress, setShowProfile } from "../../slices/user";
const HelpPage = () => {
  return (
    <>
      <section className="faq-section section-b-space help_suppoer">
        <div className="container">
          <div className="row helpSuppotBox">
            <div className="col-sm-12 col-md-6 col-lg-6 mt-1">
              <div className="hs_icone borders_set m-md-3">
                <i className="fa fa-phone fa-5x "></i>
                <p className="hs_title">Contact Us</p>
                <hr width="40%" />
                <p className="details-hs">7862939036 </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-1">
              <div className="hs_icone borders_set m-md-3">
                <i className="fa fa-envelope-o fa-5x "></i>
                <p className="hs_title">Email</p>
                <hr width="40%" />
                <a href={"mailto:"}>
                  <p className="details-hs">hello@carwash@gmail.com</p>
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 offset-md-3 mt-1">
              <div className="hs_icone borders_set m-md-3">
                <i className="fa fa-comments-o fa-5x"></i>
                <p className="hs_title">Chat</p>
                <hr width="40%" />
                <p className="details-hs">Have a question?</p>
                <p className="details-hs">Chat Here with our executive</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpPage;
