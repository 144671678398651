import React, { useEffect, useState } from "react";
import { List } from "react-content-loader";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductBox from "../ProductBox/ProductBox";
import { getOfferByIdFun } from "../../Slices/home";

export default function OfferList() {
  const navigate = useNavigate(),
    dispatch = useDispatch();
  let { id } = useParams();
  const { getOfferById } = useSelector((state) => state.home);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(
      getOfferByIdFun({ country_id: user?.country_id || "103", offer_id: id })
    );
  }, []);

  return (
    <>
      <section>
        <div className="service-box">
          <>
            <div className="container">
              <div className="section-title-box">
                <div className="title-box-bax">
                  <i
                    className="fa fa-angle-left"
                    onClick={() => navigate(-1)}
                  ></i>
                  <h2 className="sectionTitle centerText">Offers</h2>
                </div>
              </div>
              <hr className="hr" />
            </div>

            <div className="container">
              <div className="box row">
                {getOfferById.offer_category_or_service ? (
                  <>
                    {getOfferById.offer_category_or_service &&
                      getOfferById.offer_category_or_service.map(
                        (services, key) => (
                          <>
                            {services?.category_or_service &&
                            Object.keys(services?.category_or_service)
                              .length ? (
                              <div
                                className="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2"
                                key={key}
                              >
                                {
                                  <>
                                    <ProductBox
                                      services={services?.category_or_service}
                                      key={key}
                                    />
                                  </>
                                }
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        )
                      )}
                  </>
                ) : (
                  <>
                    <List />
                  </>
                )}
              </div>
            </div>
          </>
        </div>
      </section>
    </>
  );
}
