import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import clock from "../../Assets/img/clock.png";
import {
  handleBookingDetails,
  handlepackageServiceBooking,
  handleResetpackageServiceBooking,
} from "../../Slices/home";
import { toast } from "react-toastify";
import { createpackageServiceBooking, getUserProfile } from "../../Slices/auth";

export default function PackageAddressOptions() {
  const navigate = useNavigate();
  const { serviceDetails, packageServiceBooking } = useSelector(
    (state) => state.home
  );
  const [location, setLocation] = useState("Outlet");
  const [locationType, setLocationType] = useState({
    home: false,
    outlet: true,
  });
  const [profile, setProfile] = useState({});
  const { bookingDetails } = useSelector((state) => state.home);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [selectaddress, setSelectedAddress] = useState("");
  const { user_address } = useSelector((state) => state.auth.user);
  const [paymentDetails, setpaymentDetails] = useState({});

  const handleServiceBooking = () => {
    const data = {
      car_id: profile?.user_car_details_default?.id,
      location: packageServiceBooking?.location,
      location_type:
        packageServiceBooking?.location_type === "outlet"
          ? "at_outlet"
          : "at_home",
      purchase_id: packageServiceBooking?.purchase_id,
      start_date: packageServiceBooking?.start_date,
      start_time: packageServiceBooking?.start_time,
      start_time_full: packageServiceBooking?.start_time_full,
    };
    dispatch(createpackageServiceBooking(data))
      .then((response) => {
        navigate("/booking-schedule-confirmed-package", {
          state: {
            serviceDetails,
            packageServiceBooking,
            bookingDetails,
          },
        });
        dispatch(handleResetpackageServiceBooking());
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const handleNext = () => {
    if (packageServiceBooking.location_type == "outlet") {
      // navigate("/booking-schedule-confirmed-package");
      handleServiceBooking();
    } else {
      if (packageServiceBooking.location != "Outlet") {
        // navigate("/booking-schedule-confirmed-package");
        handleServiceBooking();
      } else {
        toast.error("Select Address");
      }
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserProfile())
        .then((response) => {
          setProfile(response.payload.data.result.userData);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      navigate("/");
    }
  }, [isLoggedIn]);
  return (
    <>
      <section className="container service-details">
        <div className="service-details-title">
          <i
            className="fa fa-angle-left"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <span className="service-details-title-text">Location</span>
        </div>

        <div className="service-details-body">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="">
                <img
                  className="service-details-img"
                  src={serviceDetails?.service_image_url}
                  alt=""
                />
              </div>
              <div className="service-details-information marginTop-19">
                <div className="service-details-info">
                  <h6 className="service-details-name text-capitalize">
                    {serviceDetails?.service_name}
                  </h6>
                  <p className="service-details-description text-capitalize">
                    {serviceDetails?.description}
                  </p>
                </div>
                <div className="service-details-price-box">
                  {serviceDetails?.offer_price ? (
                    <>
                      <span className="service-details-mrp">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.price ? (
                    <>
                      <span className="service-details-price">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.offer_discount_price ? (
                    <>
                      <span className="service-details-save-price">
                        Save {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_discount_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="service-details-time">
                  <img src={clock} alt="" width={15} height={15} />
                  <span className="time">
                    {serviceDetails?.duration} Minutes.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-7 ">
              <div className="choose-provider address-options">
                <div className="schedule-title-box">
                  <div>
                    <span className="schedule-box p-0">Location & Address</span>
                  </div>
                </div>
                <div className="choose-body">
                  <div className="choose-body-title">
                    <span className="choose-car-here p-0">Choose Location</span>
                  </div>
                  <div className="pr-3 pb-3 pl-3">
                    <div className="address-option-list">
                      <div
                        style={{ cursor: "pointer" }}
                        className={`${
                          packageServiceBooking.location_type == "outlet"
                            ? "address-option-item-active"
                            : "address-option-item"
                        }`}
                        onClick={() => {
                          dispatch(
                            handlepackageServiceBooking({
                              location: "Outlet",
                              address_id: "",
                              location_type: "outlet",
                            })
                          );
                        }}
                      >
                        <img
                          width={55}
                          height={55}
                          src={require("../../Assets/img/flat.png")}
                          alt=""
                        />
                        <span
                          className={`${
                            packageServiceBooking.location_type == "outlet"
                              ? "address-option-item-text-active"
                              : "address-option-item-text"
                          }`}
                        >
                          At Outlet
                        </span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className={`${
                          packageServiceBooking?.location_type == "home"
                            ? "address-option-item-active"
                            : "address-option-item"
                        }`}
                        onClick={() => {
                          dispatch(
                            handlepackageServiceBooking({
                              location_type: "home",
                            })
                          );
                        }}
                      >
                        <img
                          width={55}
                          height={55}
                          src={require("../../Assets/img/home.png")}
                          alt=""
                        />
                        <span
                          className={`${
                            packageServiceBooking.location_type === "Home"
                              ? "address-option-item-text-active"
                              : "address-option-item-text"
                          }`}
                        >
                          At Home
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {packageServiceBooking.location_type == "home" ? (
                  <>
                    <div className="p-1"></div>
                    <div className="select-address">
                      <h3 className="select-address-Saved">Saved Address</h3>
                      <h6
                        className="select-address-Add"
                        onClick={() => navigate("/myProfile?page=address")}
                        style={{ cursor: "pointer" }}
                      >
                        + Add New Address
                      </h6>
                    </div>
                    <div className="address-list">
                      {user_address &&
                        user_address.length > 0 &&
                        user_address?.map((list, key) => {
                          return (
                            <>
                              {
                                <div
                                  className={`${
                                    packageServiceBooking?.location ===
                                    list.location
                                      ? "address-item address-item-active"
                                      : ""
                                  } address-item`}
                                >
                                  <div className="d-flex align-items-center radio--box">
                                    <div
                                      className="radio-body"
                                      onClick={() => {
                                        setSelectedAddress(list.id);
                                        dispatch(
                                          handlepackageServiceBooking({
                                            location: list.location,
                                            address_id: list.id,
                                            country_id: "103",
                                            address_type: list.address_type,
                                          })
                                        );
                                      }}
                                    >
                                      {packageServiceBooking?.location ===
                                      list.location ? (
                                        <>
                                          {" "}
                                          <div className="radio-dot"></div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="address-info">
                                      <h3 className="address-title text-capitalize ">
                                        {list.address_type}
                                      </h3>
                                      <p className="address-text text-capitalize">
                                        {list.block_shop_no} , {list.location}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              }
                            </>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {user_address && user_address.length === 0 && (
                  <strong>No Address found.</strong>
                )}
                {packageServiceBooking?.location_type &&
                  packageServiceBooking?.location && (
                    <div className="choose-provider-footer">
                      <span className="choose-provider-price">
                        Total : ₹ {serviceDetails?.price}
                      </span>
                      <button onClick={handleNext} className="next-button">
                        CONTINUE
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
