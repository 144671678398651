import React from "react";
import banner from "../../Assets/img/terms-conditions4.jpeg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams, redirect } from "react-router-dom";
import { getCMSPageContent } from "../../Slices/home";
import { useState } from "react";

const Cms = () => {
  const dispatch = useDispatch();
  let { slug } = useParams();
  const [pageContent, setPageContent] = useState({
    content: [],
    loading: true,
  });
  useEffect(() => {
    getContent();
  }, [slug]);

  const getContent = () => {
    dispatch(getCMSPageContent(slug))
      .then((response) => {
        if (response.payload.status === 200) {
          setPageContent({
            ...pageContent,
            content: response.payload.data.result.data[0],
            loading: false,
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setPageContent({ content: "", loading: false });
        }
      })
      .catch((err) => console.info("err CMS", err));
  };
  return (
    <>
      <section className="bodystruct">
        <div className="bannerDesign">
          <img src={banner} alt="banner" />
          <div className="opacity-medium bg-extra-dark-gray"></div>
          <div className="textPosi">
            <div className="row ">
              <h1 className="alt-font text-white font-weight-500 letter-spacing-minus-1 mb-0">
                {pageContent?.content?.title}
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="big-section bg-light-gray">
        <div
          dangerouslySetInnerHTML={{ __html: pageContent?.content.content }}
        />
      </section>
    </>
  );
};

export default Cms;
