import React from "react";
import "./PackageConFirmBooking.scss";
import file from "../../Assets/img/file.png";
import { useDispatch, useSelector } from "react-redux";
// import { setShowLocationModal, setShowOptionModal } from '../../slices/service';
import PricIncDec from "../Common/PricIncDec/PricIncDec.jsx";

import car from "../../Assets/img/carIcon.png";

import topImg from "../../Assets/img/Group3.png";
import service from "../../Assets/img/service.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { showConfirmBooking } from "../../Slices/home";
const PackageConFirmBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <section className="ServDetailPopOut serviceOpt packconfirm">
      <div className="contianer-fluid ServDetailPop">
        <div
          className="closeIcon"
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(showConfirmBooking(false))}
        >
          <i className="fa fa-times"></i>
        </div>

        <div className="contetTextArea">
          <img src={topImg} className="topImg" />
          <div className="profdetHeadText">
            <span className="textalert">Thank You!!!</span>
            <h2>Booking Confirmed </h2>
          </div>

          <div className="summarLeft">
            <div className="summarLeftIn">
              <div className="summarLeftImgText">
                <div className="summarLeftImg">
                  <img src={service} />
                </div>
                <div className="headerFilterInfo">
                  <strong className="serviceName">
                    Vacuum seats, carpet, floor mat, between...
                  </strong>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy...
                  </p>
                  <div className="contInfo">
                    <div className="contInfoIn">
                      <ul className="startTask">
                        <li>
                          <img src={car} alt="time" />
                          <span className="time flex-grow-1">
                            {" "}
                            30 Times
                            {/* {serviceDetail.service_duration} */}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="summarLeftAdd">
              <ul className="listRadio addlocationradio">
                <li>
                  <ul className="listRadio  locationradioList">
                    <li className="date">
                      <label>
                        <h5>Weekly</h5>
                      </label>
                    </li>
                    <li className="date">
                      <label>
                        <h5>26 August</h5>
                      </label>
                    </li>
                    <li className="date">
                      <label>
                        <h5>02:00 PM</h5>
                      </label>
                    </li>
                    <li className="date">
                      <label>
                        <h5>At Home</h5>
                      </label>
                    </li>
                  </ul>
                </li>
                {/* <li className='addText'>
                            <h4>Address</h4>
                                <label>
                                    <h5>Raj Patel</h5>
                                    <p>G-57, Goving Park, Ghodasar Road, manekchowk,Ahmedabad-380050
                                        <span>Mo : +91 8141000000</span></p>
                                </label>
                            </li> */}
              </ul>
              <ul className="listRadio addlocationradio">
                <li className="addText">
                  <label>
                    <h5>Office</h5>
                    <p>
                      G-57, Goving Park, Ghodasar Road,
                      manekchowk,Ahmedabad-380050
                    </p>
                  </label>
                </li>
              </ul>
              {/* <ul className='listRadio  locationradioList'>
                            <li >
                                <label>
                                    <span>Wed</span>
                                    <h5>25</h5>
                                </label>
                            </li>
                            <li >
                                <label>
                                    <p>07:00 AM</p>
                                </label>
                            </li>
                        </ul> */}
            </div>
            <button
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/myProfile");
                dispatch(showConfirmBooking(false));
              }}
            >
              Go to My Bookings
            </button>
          </div>
        </div>
      </div>
    </section>
    //   <section className='ServDetailPopOut serviceOpt confirm'>

    //     <div className='contianer-fluid ServDetailPop'>
    //             <div className='serveBack'>
    //                 <h6><a className="backbt"><i className="fa fa-angle-left"></i></a>Add Address</h6>
    //                 </div>
    //         <div className='closeIcon' onClick={() => dispatch(setShowOptionModal(false))}>
    //             <i className='fa fa-times'></i>
    //         </div>

    //         <div className='contetTextArea'>
    //                 <img src={topImg} className='topImg'/>
    //                     <div className="profdetHeadText">
    //                         <span className="textalert">Welcome Here !</span>
    //                         <h2>Nancy Henderson </h2>
    //                     </div>

    //             <div className='summarLeft'>
    //                 <div className='summarLeftIn'>
    //                     <div className='summarLeftImgText'>
    //                         <div className='summarLeftImg'>
    //                             <img src={""}/>
    //                         </div>
    //                         <div className='headerFilterInfo'>
    //                             <strong className='serviceName'>
    //                             Delux Body Wash
    //                             </strong>
    //                             <div className='contInfo'>

    //                                 <div className='contInfoIn'>
    //                                     <ul className='startTask'>
    //                                         <li>
    //                                         <a href="">
    //                                   <img src={star} alt="rating"/>
    //                                                 <span className=' rating'>4.5</span>
    //                                             </a>
    //                                         </li>
    //                                         <li>
    //                                   <img src={clock} alt="time"/>
    //                                         <span className='time flex-grow-1'>
    //                                                 30 Min
    //                                             </span>
    //                                         </li>
    //                                     </ul>

    //                                     <ul className='price'>
    //                                         <li>
    //                                         ₹ 599
    //                                         </li>
    //                                         <li>
    //                                             <span className='textCut'>₹ 799</span>
    //                                         </li>
    //                                         <li>
    //                                             <span className='textSave'>
    //                                             Save ₹ 200</span>
    //                                         </li>
    //                                     </ul>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className='summarLeftCount'>
    //                             <h4>x 2</h4>
    //                     </div>
    //                 </div>
    //                 <div className='summarLeftAdd'>
    //                     <ul className='listRadio addlocationradio'>
    //                         <li className='homeLoc'>
    //                             <h4>Location</h4>
    //                             <label>
    //                                 <img src={home}/>
    //                                 <span>At Home</span>
    //                             </label>
    //                         </li>
    //                         <li>
    //                             <h4>Schedule</h4>
    //                             <ul className='listRadio  locationradioList'>
    //                                 <li className='date'>
    //                                     <label>
    //                                         <span>Wed</span>
    //                                         <h5>25</h5>
    //                                     </label>
    //                                 </li>
    //                                 <li className='time'>
    //                                     <label>
    //                                         <p>07:00 AM</p>
    //                                     </label>
    //                                 </li>
    //                             </ul>
    //                         </li>
    //                         {/* <li className='addText'>
    //                         <h4>Address</h4>
    //                             <label>
    //                                 <h5>Raj Patel</h5>
    //                                 <p>G-57, Goving Park, Ghodasar Road, manekchowk,Ahmedabad-380050
    //                                     <span>Mo : +91 8141000000</span></p>
    //                             </label>
    //                         </li> */}
    //                     </ul>
    //                     <h4>Address</h4>
    //                         <ul className='listRadio addlocationradio'>
    //                             <li className='addText'>

    //                                 <label>
    //                                     <h5>Raj Patel</h5>
    //                                     <p>G-57, Goving Park, Ghodasar Road, manekchowk,Ahmedabad-380050
    //                                         <span>Mo : +91 8141000000</span></p>
    //                                 </label>
    //                             </li>
    //                         </ul>
    //                     {/* <ul className='listRadio  locationradioList'>
    //                         <li >
    //                             <label>
    //                                 <span>Wed</span>
    //                                 <h5>25</h5>
    //                             </label>
    //                         </li>
    //                         <li >
    //                             <label>
    //                                 <p>07:00 AM</p>
    //                             </label>
    //                         </li>
    //                     </ul> */}
    //                 </div>
    //                 <button style={{cursor: "pointer"}} >Pay Now</button>
    //             </div>

    //         </div>
    //     </div>
    //     </section>
  );
};

export default PackageConFirmBooking;
