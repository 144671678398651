import React, { useEffect } from "react";
import "./ServiceListing.scss";
import saleTag from "../../Assets/img/icons8-price_tag.png";
import priceTag from "../../Assets/img/icons8-sale_price_tag.png";
import SalonCatSlider from "../Common/SalonCatSlider/salonCatSlider";
import ProductBox from "../ProductBox/ProductBox";
import service from "../../Assets/img/service.png";
import filtIcon from "../../Assets/img/icons8-filter.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCategoryListById, getDashboardById } from "../../Slices/home";
import ServiceDetail from "../ServiceDetail/ServiceDetail";
import ServiceDetailSchedule from "../ServiceDetailSchedule/ServiceDetailSchedule";
import ServiceDetailAddress from "../ServiceDetailAddress/ServiceDetailAddress";
import PaymentOption from "../PaymentOption/PaymentOption";
import ConFirmBooking from "../ConFirmBooking/ConFirmBooking";
import { useState } from "react";
import { List } from "react-content-loader";

const SubServiceListing = () => {
  const {
    showServiceDetails,
    showScheduleOpt,
    showAddressOpt,
    showPaymentOpt,
    showConfirmBooking,
    categories,
    isCategoriesFetched,
  } = useSelector((state) => state.home);
  const { fillterlist } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [discount, setDiscount] = useState("Select Discount");
  const [price, setPrice] = useState("Select Price");

  useEffect(() => {
    const data = {
      category_id: id,
      country_id: "103",
    };
    dispatch(getCategoryListById(data));
    // if(isCategoriesFetched){
    //   console.info("SubCate", categories[0].sub_categories.filter((list) => list.id == id).map((services) => services.services))
    // }
    // eslint-disable-next-line
  }, []);

  // console.log(categories,"categories")
  return (
    <>
      <section>
        {categories[0].sub_categories
          .filter((list) => list.id == id)
          .map((services) => (
            <div className=" headerFilterOut container">
              {/* {console.info("subservice map", services)} */}
              <div className="borderBottomDesign titleHead">
                <div className="spaceSect row mlr0 aligCent jSpaceBetween wid100">
                  <h2 className="sectionTitle centerText">
                    <Link
                      to={"javascript:void(0)"}
                      className="backbt"
                      onClick={() => navigate(-1)}
                    >
                      <i className="fa fa-angle-left"></i>
                      <span className="section--title">
                        {services.category_name}
                      </span>
                    </Link>
                  </h2>

                  {/* <div className='rightCont row '>
                    <div className='mobIcon'>
                      <img src={filtIcon} />
                    </div>
                    <div className='rightIn'>
                    
                    <div className="filter-box">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle custom-dropdown"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={priceTag}
                        alt=""
                        width={20}
                      />
                      <span className="discount-text">
                        {"Discount: " + discount || "Select Discount"}{" "}
                      </span>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        className="dropdown-item discount-text dropdown--item"
                        href="#"
                        onClick={() => {
                          setDiscount("10%-20%");
                        }}
                      >
                        10%-20%
                      </button>
                      <button
                        className="dropdown-item discount-text dropdown--item"
                        href="#"
                        onClick={() => {
                          setDiscount("20%-25%");
                        }}
                      >
                        20%-25%
                      </button>
                      <button
                        className="dropdown-item discount-text dropdown--item"
                        href="#"
                        onClick={() => {
                          setDiscount("25%-30%");
                        }}
                      >
                        25%-30%
                      </button>
                    </div>
                  </div>

                  <div className="">
                    <div className="dropdown">
                      <button
                        className="btn dropdown-toggle custom-dropdown"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={saleTag} alt="" />
                        <span className="discount-text">
                          {"Price Range : " + price}{" "}
                        </span>
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item discount-text dropdown--item"
                          href="#"
                          onClick={() => {
                            setPrice("₹500 - ₹1000");
                          }}
                        >
                          ₹500 - ₹1000
                        </button>
                        <button
                          className="dropdown-item discount-text dropdown--item"
                          href="#"
                          onClick={() => {
                            setPrice("₹1500 - ₹2000");
                          }}
                        >
                          ₹1500 - ₹2000
                        </button>
                        <button
                          className="dropdown-item discount-text dropdown--item"
                          href="#"
                          onClick={() => {
                            setPrice("₹3000 - ₹5000");
                          }}
                        >
                          ₹3000 - ₹5000
                        </button>
                      </div>
                    </div>
                    <span className="price-filter-text"> </span>
                  </div>
                  </div>
                    
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="row headerFilter">
                {fillterlist[0]?.services.map((item, index) => (
                  <div className="col-md-3 col-sm-4 col-xs-6 headerFilterIn">
                    {console.log(item, "item")}
                    <ProductBox services={item} key={index} />
                  </div>
                ))}

                {/* {services && services.services.length > 0 ? services.services.map((services, key) => 
              <div className='col-md-3 col-sm-4 col-xs-6 headerFilterIn'>
                <ProductBox
                  services={services}
                  key={key}
                />
              </div>
              ) : <strong className="font-family-heebo">{"No Data Found"} </strong>} */}
              </div>
            </div>
          ))}

        {/* {showServiceDetails && <ServiceDetail />}
      {showScheduleOpt && <ServiceDetailSchedule />}
      {showAddressOpt && <ServiceDetailAddress />}
      {showPaymentOpt && <PaymentOption />}
      {showConfirmBooking && <ConFirmBooking  />} */}
      </section>
    </>
  );
};

export default SubServiceListing;
