import React, { useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../../Assets/img/logo.png";
import car from "../../../Assets/img/car.png";
import search from "../../../Assets/img/ic_search.png";
import { Link, useNavigate } from "react-router-dom";
import icon1 from "../../../Assets/img/icons8-user.png";
import icon2 from "../../../Assets/img/icons8-maintenance.png";
import icon3 from "../../../Assets/img/icons8-heart.png";
import icon4 from "../../../Assets/img/icons8-home_address.png";
import icon7 from "../../../Assets/img/icons8-help.png";
import icon6 from "../../../Assets/img/icons8-bill.png";
import icon8 from "../../../Assets/img/Path.png";
import {
  addUserCars,
  getAllUsersCar,
  getUserProfile,
  handleBookingDetails,
  listFavorite,
  logoutUser,
  setDefaultCar,
  setLogOut,
  showLogin,
  showRegister,
} from "../../../Slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation, withTranslation, Trans } from "react-i18next";

import $, { isArray } from "jquery";
import {
  getSearch,
  getServiceDetails,
  setLandingDetails,
} from "../../../Slices/home";

const Header = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, userCars, user, user_car_id_default } = useSelector(
    (state) => state.auth
  );
  const {} = useSelector((state) => state.home);
  const navigate = useNavigate();
  const [carDetails, setCarDetails] = useState({});
  const [selectedCar, setSelectedCar] = useState(null);
  const [language, setLanguage] = useState(false);
  const selectedCarId = localStorage.getItem("car_id") || "";
  const [carBrand, setcarBrand] = useState("Select Your Car Brand");
  const { landigDetails } = useSelector((state) => state.home);
  const [searchData, setSearchData] = useState([]);
  const [searchstatus, setSearchstatus] = useState(false);
  const [mobilesearch, setmobilesearch] = useState(false);
  const carlist = [
    { id: 53, name: "Mazda (petrol)" },
    { id: 54, name: "Chevrolet (gas)" },
    { id: 54, name: "Alfa Romeo (Diesel)" },
    { id: 56, name: "Audi A7 (Diesel)" },
    { id: 61, name: "I20 (petrol)" },
  ];

  const handlesearchBar = (e) => {
    const { value } = e.target;
    const data = {
      search: value,
    };

    if (value) {
      dispatch(getSearch(data))
        .then((res) => {
          if (res.payload.data) {
            setSearchData(res.payload.data);
            setSearchstatus(true);
          } else if (res.payload.Result) {
            setSearchData([]);
            setSearchstatus(false);
          } else {
            setSearchData([]);
            setSearchstatus(false);
          }
        })
        .catch((e) => {
          setSearchstatus(false);
        });
    } else {
      setSearchstatus(false);
      setSearchData([]);
    }
  };

  const getservice = (serviceId) => {
    dispatch(
      getServiceDetails({
        service_id: serviceId,
        country_id: 103,
        car_id: landigDetails?.car_brand?.id,
      })
    ).then((data) => {
      console.log(data, "API Data");
    });
    setSearchData([]);
    setmobilesearch(false);
    navigate("/ServiceDetail");
  };

  const handlecarBrand = (brand) => {
    setcarBrand(brand);
  };

  const handleLanguage = () => {
    if (language) {
      setLanguage(false);
      changeLanguage("en");
    } else {
      setLanguage(true);
      changeLanguage("ar");
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    // theme.direction = i18n.dir();
  };

  useEffect(() => {
    $(document).ready(function () {
      $(window).scroll(function () {
        var sticky = $(".headerTop"),
          scroll = $(window).scrollTop();

        if (scroll >= 100) sticky.addClass("fixedTop");
        else sticky.removeClass("fixedTop");
      });
      $("div.selected").on("click", function () {
        var hasActiveClass = $("div.select-box").hasClass("active");

        if (hasActiveClass === false) {
          var windowHeight = $(window).outerHeight();
          var dropdownPosition = $(this).offset().top;
          var dropdownHeight = 95; // dropdown height

          if (dropdownPosition + dropdownHeight + 50 > windowHeight) {
            $("div.select-box").addClass("drop-up");
          } else {
            $("div.select-box").removeClass("drop-up");
          }

          var currentUniversity = $(this).find("text").text().trim();

          $.each($("ul.select-list li"), function () {
            var university = $(this).text().trim();
            if (university === currentUniversity) $(this).addClass("active");
            else $(this).removeClass("active");
          });
        }

        $("div.select-box").toggleClass("active");
      });

      $("ul.select-list li").on("click", function () {
        var university = $(this).html();
        $("span.text").html(university);
        $("div.select-box").removeClass("active");
      });

      $("ul.select-list li").hover(function () {
        $("ul.select-list li").removeClass("active");
      });

      $(document).click(function (event) {
        if ($(event.target).closest("div.searchLocation").length < 1) {
          $("div.select-box").removeClass("active");
        }
      });
    });
    $(document).ready(function () {
      $("div.selectedbmw").on("click", function () {
        var hasActiveClass = $("div.select-boxbmw").hasClass("active");

        if (hasActiveClass === false) {
          var windowHeight = $(window).outerHeight();
          var dropdownPosition = $(this).offset().top;
          var dropdownHeight = 95; // dropdown height

          if (dropdownPosition + dropdownHeight + 50 > windowHeight) {
            $("div.select-boxbmw").addClass("drop-up");
          } else {
            $("div.select-boxbmw").removeClass("drop-up");
          }

          var currentUniversity = $(this).find("text").text().trim();

          $.each($("ul.select-listbmw li"), function () {
            var university = $(this).text().trim();
            if (university === currentUniversity) $(this).addClass("active");
            else $(this).removeClass("active");
          });
        }

        $("div.select-boxbmw").toggleClass("active");
      });

      $("ul.select-listbmw li").on("click", function () {
        var university = $(this).html();
        $("span.text").html(university);
        $("div.select-boxbmw").removeClass("active");
      });

      $("ul.select-listbmw li").hover(function () {
        $("ul.select-listbmw li").removeClass("active");
      });

      $(document).click(function (event) {
        if ($(event.target).closest("div.custom-selectBmw").length < 1) {
          $("div.select-boxbmw").removeClass("active");
        }
      });
    });

    // $(document).on("click"," .showAddMyProf",function() {

    //   $("body").removeClass("logSinoScroll");
    // });

    $(document).ready(function () {
      $("button.menuBar").click(function (e) {
        e.stopPropagation();
        $(".rightSideHead").addClass("intro");
        $("body").addClass("shadow noScroll");
        // if ($(".searchCat").hasClass("addShow")) {
        //   $(".searchCat").removeClass("addShow");
        // };
      });
      $("button.crossBar").click(function () {
        $(".rightSideHead").removeClass("intro");
        $("body").removeClass("shadow noScroll");
      });
      $(".profListIn li ").click(function () {
        $(".rightSideHead").removeClass("intro");
        $("body").removeClass("shadow noScroll");
      });
      $(" .bellNone a").click(function () {
        $(".rightSideHead").removeClass("intro");
        $("body").removeClass("shadow noScroll");
      });

      $(" .bellNone input").click(function () {
        $(".rightSideHead").removeClass("intro");
        $("body").removeClass("shadow noScroll");
      });
      // $("button.searchMob").click(function (e) {
      //   e.stopPropagation();
      //   $(".searchCat").toggleClass("addShow");
      // });
      $(document).click(function (e) {
        var container = $(".rightSideHead");
        if (!container.is(e.target) && !container.has(e.target).length) {
          $(".rightSideHead").removeClass("intro");
          $("body").removeClass("shadow noScroll");
        }
      });
      $(document).click(function (e) {
        var container = $(".searchCat");
        if (!container.is(e.target) && !container.has(e.target).length) {
          // $(".searchCat").removeClass("addShow");
        }
        var search = $(".searchBar.mob");
        if (!search.is(e.target) && !search.has(e.target).length) {
          // $(".searchCat").removeClass("addShow");
        }
      });
      //   $("button.searchMob").click(function(){
      //       $(".searchCat").removeClass("addShow");
      //     });
      // $("button.btn.btn-sm.serFilBTn").click(function () {
      //   $("body").addClass("noScrollBtn");
      //   // $('body').addClass("noScroll");
      //   // $("body").addClass("shadow");
      // });
      // $(".ServDetailPop .closeIcon").click(function () {
      //   $("body").removeClass("noScrollBtn");
      //   // $('body').addClass("noScroll");
      //   // $("body").addClass("shadow");
      // });
    });
    $(document).ready(function () {
      $(function () {
        var $customSelect = $(".custom-selectOption");
        var $resetButton = $("#resetButton");

        $customSelect.each(function () {
          var classes = $(this).attr("class");
          var id = $(this).attr("id");
          var name = $(this).attr("name");

          var template = '<div class="' + classes + '">';
          template += '<span class="custom-select-trigger">';
          template +=
            '<span class="custom-select-trigger-text">' +
            $(this).data("placeholder") +
            "</span>";
          template += "</span>";
          template += '<div class="custom-options">';

          $(this)
            .find("option")
            .each(function () {
              template +=
                '<span class="custom-option" data-value="' +
                $(this).attr("value") +
                '">' +
                $(this).html() +
                "</span>";
            });

          template += "</div></div>";

          var customSelectWrapper = $(
            '<div class="custom-select-wrapper"></div>'
          );
          customSelectWrapper.css({
            "-webkit-user-select": "none",
            "-moz-user-select": "none",
            "-ms-user-select": "none",
            "user-select": "none",
          });

          $(this).wrap(customSelectWrapper);
          $(this).after(template);
        });

        $(document).on("click", function (e) {
          var eTarget = e.target;

          if (
            !$(eTarget)
              .closest(".custom-select-wrapper")
              .hasClass("custom-select-wrapper")
          ) {
            $(".custom-selectOption").removeClass("opened");
            customOptionsClosed();
          }
        });

        $(".custom-select-trigger").on("click", function () {
          $(this).parents(".custom-selectOption").toggleClass("opened");

          var timer;
          if ($(this).parents(".custom-selectOption").hasClass("opened")) {
            clearTimeout(timer);

            $(this)
              .parents(".custom-selectOption")
              .find(".custom-options")
              .stop()
              .css("display", "block")
              .animate(
                {
                  opacity: "1",
                  "margin-top": "15px",
                },
                100
              );
          } else {
            customOptionsClosed();
          }
        });

        $(".custom-option").on("click", function () {
          $(this)
            .parents(".custom-select-wrapper")
            .find("select")
            .val($(this).data("value"));
          $(this)
            .parents(".custom-options")
            .find(".custom-option")
            .removeClass("selection");
          $(this).addClass("selection");
          $(this).parents(".custom-selectOption").removeClass("opened");
          $(this)
            .parents(".custom-selectOption")
            .find(".custom-select-trigger-text")
            .text($(this).text());
          customOptionsClosed();
        });

        $resetButton.on("click", function () {
          $(".custom-select-trigger-text").text(
            $customSelect.data("placeholder")
          );
        });

        function customOptionsClosed() {
          $(".custom-options").stop().animate(
            {
              opacity: 0,
              "margin-top": "0",
            },
            100
          );

          // var t = setTimeout(function(){
          //   $('.custom-options').css('display', 'none');
          // }, 500 );
        }
      });
    });
    $(document).ready(function () {
      if ($(".voucherOut li.input input").val() != "") {
        $(".voucherOut li.input").addClass("applied");
      }
      $(".voucherOut li.input input").blur(function () {
        if ($(".voucherOut li.input input").val() != "") {
          $(".voucherOut li.input").addClass("applied");
        } else {
          $(".voucherOut li.input").removeClass("applied");
        }
      });
    });
  }, []);

  const getFavoriteList = () => {
    dispatch(listFavorite());
  };
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getAllUsersCar());
      getFavoriteList();
    }
    // eslint-disable-next-line
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser())
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message, {
            position: "top-center",
          });
          dispatch(setLogOut());
          setTimeout(() => {
            navigate("/");
            window.location.reload(false);
          }, 1500);
        } else {
          toast.error(response.payload.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleInputChange = (e) => {
    const { value, name, dataset } = e.target;
    switch (name) {
      case "car_name":
        setCarDetails({ ...carDetails, [name]: value });
        break;
      default:
        setCarDetails({ ...carDetails, car_fuel_type: dataset.value });
        break;
    }
  };

  const handleAddCars = () => {
    dispatch(addUserCars(carDetails))
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message);
        } else {
          toast.error("Please Login to Add Cars.");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (userCars?.length == 0) {
        dispatch(
          addUserCars({
            car_name: landigDetails?.car_brand?.car_name,
            car_fuel_type: "gas",
            car_type: 1,
          })
        )
          .then((response) => {
            if (response.payload.status === 200) {
              dispatch(getAllUsersCar());
            }
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    }
  }, [userCars, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserProfile());
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const handleSelectCar = () => {
    dispatch(setDefaultCar({ id: parseInt(selectedCar) }))
      .then((response) => {
        toast.success("Selected As Default");
        localStorage.setItem("usercar", selectedCar);
        dispatch(handleBookingDetails({ car_id: parseInt(selectedCar) }));
        console.log("default", response);
      })
      .catch((error) => toast.error("Sommething Went Wrong"));
  };
  const { t, i18n } = useTranslation();

  const handlemobilesearch = () => {
    console.log(
      mobilesearch,
      "mobilesearchmobilesearchmobilesearchmobilesearch"
    );
    mobilesearch ? setmobilesearch(false) : setmobilesearch(true);
  };

  return (
    <div className="headerTop">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="leftSideHead">
            <button className="menuBar">
              <i className="fa fa-bars"></i>
            </button>
            <Link to={"/"} className="logoOut">
              <img src={logo} alt="logo" />
            </Link>
            <div className="searchBar mob">
              <button
                type="submit"
                className="searchMob"
                onClick={() => {
                  handlemobilesearch();
                }}
              >
                <i className="fa fa-search"></i>
              </button>
            </div>
            <div
              className={`searchCat searchCatMob ${
                mobilesearch ? "addShow" : ""
              }`}
            >
              <div className="searchBar">
                <input
                  type="search"
                  placeholder={t("common.search_for")}
                  onChange={(e) => {
                    handlesearchBar(e);
                  }}
                />
                <button type="submit" className="btnSearch">
                  <img src={search} alt="logo" />
                </button>
                {searchData.length > 0 ? (
                  <>
                    <div className="searchList">
                      <ul>
                        {searchData.map((item, index) => (
                          <li
                            className="pb-1"
                            onClick={() => {
                              getservice(item?.id);
                            }}
                          >
                            {item?.service_name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="bellNone mobile-change-car-icon">
              <span>
                <img src={car} alt="car" />
              </span>
            </div>
          </div>
          <div className="rightSideHead">
            {/* <button className="crossBar">
              <i className="fa fa-times"></i>
            </button> */}
            <div className="searchBar ">
              <input
                type="search"
                placeholder={t("common.search_for")}
                onChange={(e) => {
                  handlesearchBar(e);
                }}
              />
              <button type="submit" className="btnSearch">
                <img src={search} alt="logo" />
              </button>
              {searchData.length > 0 ? (
                <>
                  <div className="searchList">
                    <ul>
                      {searchData.map((item, index) => (
                        <li
                          className="pb-1"
                          onClick={() => {
                            getservice(item?.id);
                          }}
                        >
                          {item?.service_name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="filter-box pc-location-dropdown">
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle custom-dropdown language-dropdown location-deropdown"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={require("../../../Assets/img/icons8-location.png")}
                    width={17}
                    alt=""
                  />
                  <div className="location-box">
                    <span className="location-text"> Location</span>
                    <span className="location-info">
                      {" "}
                      {landigDetails?.location?.location}
                    </span>
                  </div>
                </button>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <span
                    className="dropdown-item discount-text dropdown--item break-spaces"
                    href="#"
                  >
                    {landigDetails?.location?.location}
                  </span>
                  <button
                    className="dropdown-item discount-text dropdown--item"
                    href="#"
                  >
                    {/* <Link to={"/landing"}>Change location</Link> */}
                    <span
                      className="change-location-text"
                      onClick={() => {
                        dispatch(
                          setLandingDetails({
                            location: null,
                            car_brand: null,
                            car_type: null,
                          })
                        );
                      }}
                    >
                      Change location
                    </span>
                  </button>
                </div>
              </div>
              <span className="price-filter-text"> </span>
            </div>

            {/*
            old code
            <div className='searchLocation'>
              <div className='selected'>
                <p className='text'>
                  <b>{t("location")}</b>
                  <span>{landigDetails?.location?.location}</span>
                </p>
              </div>
              <div className='select-box'>
                <ul className='select-list'>
                  <li data-row='0' data-value=''>
                    <p>
                      <b>{t('common.deliver_to')}</b>
                      <h6>{landigDetails?.location?.location}</h6>
                    </p>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="bellNone d-flex align-items-center mobDisnone">
              <span>
                <img src={car} alt="car" />
              </span>
              {userCars.length > 0 && isLoggedIn ? (
                <div className="carMenu">
                  <h5>{t("common.select_car")}</h5>
                  {/* <div className='custom-selectBmw'>
                  </div> */}
                  <select
                    onChange={(e) => {
                      setSelectedCar(e.target.value);
                    }}
                  >
                    <option disabled value={""}>
                      {t("common.select_car_default")}
                    </option>
                    {userCars?.map((list, index) => {
                      return (
                        <option
                          selected={list.id === selectedCarId ? true : false}
                          key={index}
                          value={list.id}
                        >
                          <span className="text-capitalize">
                            {list.car_name +
                              ` (${list?.car_type_data?.car_type}-` +
                              `${list.car_fuel_type})`}
                          </span>
                        </option>
                      );
                    })}
                  </select>
                  {/* <h5>Fuel Type</h5>
                  <ul className='listBrandCar'>
                    {carFuel?.map((list, key) => {
                      return <li key={key}>
                      <span data-value={list.name} onClick={handleInputChange}>{list.name}</span>
                    </li>
                    })}
                  </ul> */}
                  <input
                    type="button"
                    value="Select Car"
                    onClick={() => handleSelectCar()}
                  />
                </div>
              ) : (
                <>
                  <div className="carMenu">
                    {isLoggedIn ? (
                      <>
                        {carlist ? (
                          <>
                            <h5>Select Car d</h5>
                            <select>
                              <option disabled="" value="">
                                No Car Available To Show
                              </option>
                            </select>
                            <input
                              type="button"
                              value="Add Car"
                              onClick={() => navigate("/myProfile?page=cars")}
                            />
                          </>
                        ) : (
                          <>
                            <strong>{"No Cars Found"}</strong>
                            <br />
                            <Link to={"myProfile?page=cars"}>
                              {"Add Cars"}Add Car
                            </Link>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Link to={"/landing"}>
                          You have no car please login
                        </Link>
                      </>
                    )}
                  </div>
                  {/* <div className="carMenu-body">
                      <h5 className="car-brand">Car Brand</h5>
                      <div className="dropdown">
                      <button className="btn dropdown-toggle custom-dropdown language-dropdown"type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="discount-text selected-name border-none">
                      {carBrand}
                      </span>
                      {userCars?.map((list, index) => {
                        <><span>{list.car_name}</span></>
                      
                        })}
                      </button>
                      
                      <div className="dropdown-menu"aria-labelledby="dropdownMenuButton">
                        <button className="dropdown-item discount-text dropdown--item" href="#" 
                        // changeLanguage('en')
                        onClick={ (()=> handlecarBrand ())}></button>
                      </div>
                    </div>
                      <h5 className="fuel-type">Fuel Type</h5>
                     
                    </div> */}

                  {/* <strong>{"No Cars Found"}</strong><br/>
                  <Link to={"myProfile?page=cars"}>{"Add Cars"}asdasdasd</Link> */}
                </>
              )}
            </div>
            <ul className="linkSing mobnone">
              {isLoggedIn ? (
                <>
                  <li>
                    <Link
                      className="text-capitalize"
                      to={"/myProfile?page=profile"}
                    >
                      {t("common.profile")}
                    </Link>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <span onClick={() => handleLogout()}>
                      {t("common.logout")}
                    </span>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="login">{t("common.login")}</Link>
                  </li>
                  <li>
                    <Link to="registration">{t("common.sign_up")}</Link>
                  </li>

                  {/* <li>
                      <a
                        href='javascript:void(0)'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(showLogin(true));
                        }}
                      >
                        {t('common.login')}
                      </a>
                    </li>
                    <li>
                      <a
                        href='javascript:void(0)'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(showRegister(true));
                        }}
                      >
                        {t('common.sign_up')}
                      </a>
                    </li> */}
                </>
              )}

              <li>
                <div className="filter-box">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle custom-dropdown language-dropdown"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={require("../../../Assets/img/language.png")}
                        alt=""
                      />
                      <span className="discount-text selected-name">
                        {language ? "Arabic" : "English"}
                      </span>
                    </button>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        className="dropdown-item discount-text dropdown--item"
                        href="#"
                        // changeLanguage('en')
                        onClick={() => handleLanguage()}
                      >
                        {language ? "English" : "Arabic"}
                      </button>
                    </div>
                  </div>
                  <span className="price-filter-text"> </span>
                </div>
              </li>
            </ul>
            <div className="profList">
              <div className="profListIn">
                {isLoggedIn ? (
                  <div className="nameNum">
                    <h3>{user?.name}</h3>
                    <span>{user?.country_code + " " + user?.mobile_no} </span>
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  <div className="filter-box">
                    <div className="dropdown">
                      <button
                        className="btn dropdown-toggle custom-dropdown language-dropdown"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={require("../../../Assets/img/language.png")}
                          alt=""
                        />
                        <span className="discount-text selected-name">
                          {language ? "Arabic" : "English"}
                        </span>
                      </button>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item discount-text dropdown--item"
                          href="#"
                          onClick={() => handleLanguage()}
                        >
                          {language ? "English" : "Arabic"}
                        </button>
                      </div>
                    </div>
                    <span className="price-filter-text"> </span>
                  </div>
                </div>

                <div className="flexMobCar">
                  <div className="filter-box w-100">
                    <div className="dropdown">
                      <button
                        className="btn dropdown-toggle custom-dropdown language-dropdown location-deropdown"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={require("../../../Assets/img/icons8-location.png")}
                          width={17}
                          alt=""
                        />
                        <div className="location-box">
                          <span className="location-text"> Location</span>
                          <span className="location-info">
                            {" "}
                            {landigDetails?.location?.location}
                          </span>
                        </div>
                      </button>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <span
                          className="dropdown-item discount-text dropdown--item break-spaces"
                          href="#"
                        >
                          {landigDetails?.location?.location}
                        </span>
                        <button
                          className="dropdown-item discount-text dropdown--item"
                          href="#"
                        >
                          <span
                            className="change-location-text"
                            onClick={() => {
                              dispatch(
                                setLandingDetails({
                                  location: null,
                                  car_brand: null,
                                  car_type: null,
                                })
                              );
                            }}
                          >
                            Change location
                          </span>
                        </button>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <span
                            className="dropdown-item discount-text dropdown--item break-spaces"
                            href="#"
                          >
                            {landigDetails?.location?.location}
                          </span>
                          <button
                            className="dropdown-item discount-text dropdown--item"
                            href="#"
                          >
                            {/* <Link to={"/landing"}>Change location</Link> */}
                            <span
                              className="change-location-text"
                              onClick={() => {
                                dispatch(
                                  setLandingDetails({
                                    location: null,
                                    car_brand: null,
                                    car_type: null,
                                  })
                                );
                              }}
                            >
                              Change location
                            </span>
                          </button>
                        </div>
                      </div>
                      <span className="price-filter-text"> </span>
                    </div>
                    {/* <div className='searchLocation'>
                    <div className='selected'>
                      <p className='text'>
                        <b> {t('common.sign_up')}</b>
                        <span>{t('common.empire_business')}</span>
                      </p>
                    </div>
                    <div className='select-box'>
                      <ul className='select-list'>
                        <li data-row='0' data-value=''>
                        <p>
                            <b>{t('common.deliver_to')}</b>
                            <h6>{landigDetails?.location?.location}</h6>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div> */}

                    <div className="bellNone">
                      <span className="mobile_car_icon">
                        <img src={car} />
                      </span>
                      {userCars.length > 0 ? (
                        <div className="carMenu">
                          <h5>{t("common.select_car")}</h5>
                          <select
                            onChange={(e) => setSelectedCar(e.target.value)}
                          >
                            <option value={""}>
                              {t("common.select_car_default")}
                            </option>
                            {userCars?.map((list, index) => {
                              return (
                                <option
                                  selected={
                                    list.id == selectedCarId ? true : false
                                  }
                                  key={index}
                                  value={list.id}
                                >
                                  {list.car_name + ` (${list.car_fuel_type})`}
                                  asd
                                </option>
                              );
                            })}
                          </select>
                          <input
                            type="button"
                            value="Select Car"
                            onClick={() => handleSelectCar()}
                          />
                        </div>
                      ) : (
                        <div className="carMenu">
                          <strong>{"No Cars Found"}</strong>
                          <br />
                          <Link to={"myProfile?page=cars"}>{"Add Cars"}</Link>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="contetTextArea mt-5">
                    <ul className="listName listHeight">
                      {isLoggedIn && (
                        <>
                          <li>
                            <Link to={"/myProfile?page=profile"}>
                              <span className="showProfile">
                                <i>
                                  <img src={icon1} alt="icon" />
                                </i>
                                {t("common.my_profile")}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/myProfile?page=address"}>
                              <span className="showAddress">
                                <i>
                                  <img src={icon4} alt="icon" />
                                </i>
                                {t("common.my_address")}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/myProfile?page=booking"}>
                              <span className="showBooking">
                                <i>
                                  {" "}
                                  <img src={icon6} alt="icon" />
                                </i>
                                {t("common.my_bookings")}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/myProfile?page=packages"}>
                              <span className="showFavorite">
                                <i>
                                  <img src={icon3} alt="icon" />
                                </i>
                                {t("common.my_packages")}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/myProfile?page=favorites"}>
                              <span className="showFavorite">
                                <i>
                                  <img src={icon3} alt="icon" />
                                </i>
                                {t("common.my_favourites")}
                              </span>
                            </Link>
                          </li>
                        </>
                      )}
                      <li>
                        <li>
                          <Link to={"/myProfile?page=pramotion"}>
                            <span className="showFavorite">
                              <i>
                                <img src={icon3} alt="icon" />
                              </i>
                              {t("common.promotion")}
                            </span>
                          </Link>
                        </li>
                        {/* <li>
                        <span className='showFavorite'>
                          <i>
                            <img src={icon3} alt='icon' />
                          </i>
                          My Addresss
                        </span>
                      </li> */}
                        <span>
                          <i>
                            {" "}
                            <img src={icon7} />
                          </i>
                          {t("common.help")}
                        </span>
                      </li>
                      <li>
                        <span>
                          <i>
                            <img src={icon2} />
                          </i>
                          {t("common.promotion")}
                        </span>
                      </li>
                    </ul>

                    {/* <ul className='linkSing'>
                    <li>
                      <a
                        href='javascript:void(0)'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(showLogin(true));
                        }}
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <a
                        href='javascript:void(0)'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(showRegister(true));
                        }}
                      >
                        Sign up
                      </a>
                    </li>
                  </ul> */}
                    <div className="logoutName">
                      <ul className="listName">
                        {isLoggedIn ? (
                          <li style={{ cursor: "pointer" }}>
                            <span onClick={() => handleLogout()}>
                              <i className="logoUT">
                                <img src={icon8} />
                              </i>
                              {t("common.logout")}
                            </span>
                          </li>
                        ) : (
                          <>
                            <li>
                              <Link to="/login">
                                <span
                                  className="showFavorite"
                                  onClick={() => dispatch(showLogin(true))}
                                >
                                  <i>
                                    <img src={icon3} alt="icon" />
                                  </i>
                                  {t("common.login")}
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/registration"}>
                                <span
                                  className="showFavorite"
                                  onClick={() => dispatch(showRegister(true))}
                                >
                                  <i>
                                    <img src={icon3} alt="icon" />
                                  </i>
                                  {t("common.sign_up")}
                                </span>
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
