import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BulletList } from "react-content-loader";
import {
  addUserCars,
  deleteUserCars,
  getAllUsersCar,
  setDefaultCar,
} from "../../../Slices/auth";
import { Link } from "react-router-dom";
import { getBrands, getFuel } from "./../../../Slices/home";
import Geosuggest from "react-geosuggest";
import { useTranslation } from "react-i18next";

const MyCars = () => {
  const dispatch = useDispatch();
  const [allCars, setAllCars] = useState({});
  const [loading, setLoading] = useState(true);
  const [showAddCar, setShowAddCar] = useState(false);
  const [carDetails, setCarDetails] = useState({});
  const { t, i18n } = useTranslation();

  const { carBrand, carFuel, bookingDetails } = useSelector(
    (state) => state.home
  );

  const getCars = () => {
    dispatch(getAllUsersCar())
      .then((response) => {
        if (response.payload.status === 200) {
          setLoading(false);
          // toast.success(response.payload.data.message)
          setAllCars(response.payload.data.result.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const handleDeleteCars = (id) => {
    dispatch(deleteUserCars(id))
      .then((response) => {
        if (response.payload.status === 200) {
          // toast.success(response.payload.data.message)
          getCars();
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleAddCars = () => {
    dispatch(addUserCars(carDetails))
      .then((response) => {
        if (response.payload.status === 200) {
          // toast.success(response.payload.data.message)
          setCarDetails({});
          setShowAddCar(false);
          getCars();
        } else {
          toast.error(t("common.something_wrong"));
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const getLanLngForAddress = (e) => {
    if (e !== undefined) {
      let results = e.gmaps.hasOwnProperty("address_components");
      if (results) {
        let results = e.gmaps.address_components;
        setCarDetails({
          ...carDetails,
          latitude: e.location.lat.toString(),
          longitude: e.location.lng.toString(),
          location: e.label,
        });
      }
    }
  };

  const handleSetDefaultCars = (carId) => {
    dispatch(setDefaultCar({ id: carId }))
      .then((response) => {
        getCars();
        localStorage.setItem("usercar", carId);
        toast.success(t("common.selected_default"));
      })
      .catch((error) => toast.error(t("common.something_wrong")));
  };

  useEffect(() => {
    getCars();
    dispatch(getBrands());
    dispatch(getFuel());
    console.log("change");
    // eslint-disable-next-line
  }, [showAddCar, bookingDetails.car_id]);

  return (
    <div className="profDetailIn pb-4">
      <div className="profdetHead mycar">
        <div className="profdetHeadText">
          <h3>{t("common.my_cars")}</h3>
        </div>
        <Link to={"/Addcar"}>
          <div className="save">
            <span>{t("common.add_car")}</span>
          </div>
        </Link>
      </div>
      <div className="formOut profdet favBox">
        <form>
          <div className="formInText mycars">
            {!loading ? (
              allCars.length > 0 ? (
                allCars.map((list) => (
                  <div className="inpuWidth pb-0" key={list.id}>
                    <div className="summarLeftIn mycarsLeftIN mr-3 ml-3 sm:pb-3">
                      <div className="summarLeftImgText">
                        <ul className="carsInfoList">
                          <li className="mr-3">
                            <h5 className={`pb-0 mb-1 text-capitalize `}>
                              {t("common.car_brand")}
                            </h5>
                            <p
                              className={`pb-0 mb-0 text-capitalize ${
                                list.is_default === 1 ? `text-green` : ``
                              }`}
                            >
                              {list?.car_name}
                            </p>
                          </li>
                          <li className="mr-3">
                            <h5 className="pb-0 mb-1 text-capitalize">
                              {t("common.fuel_type")}
                            </h5>
                            <p
                              className={`pb-0 mb-0 text-capitalize ${
                                list.is_default === 1 ? `text-green` : ``
                              }`}
                            >
                              {list?.car_fuel_type}
                            </p>
                          </li>
                          <li className="mr-3">
                            <h5 className="pb-0 mb-1 text-capitalize">
                              {t("common.car_type")}
                            </h5>
                            <p
                              className={`pb-0 mb-0 text-capitalize ${
                                list.is_default === 1 ? `text-green` : ``
                              }`}
                            >
                              {list?.car_type_data?.car_type}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="summarLeftCount car-list-action-box mt-0">
                        {list.is_default === 1 ? (
                          <>
                            <span className="default-car">
                              {t("common.default_car")}
                            </span>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="mb10 set-default-btn"
                              onClick={() => handleSetDefaultCars(list?.id)}
                            >
                              {t("common.set_default")}
                            </button>
                          </>
                        )}
                        <button
                          type="button"
                          className="mb10 remove--btn"
                          onClick={() => handleDeleteCars(list?.id)}
                        >
                          {t("common.remove")}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span className="no-data-found">
                  {t("common.no_data_Found")}
                </span>
              )
            ) : (
              <BulletList />
            )}
          </div>
        </form>
      </div>

      {showAddCar && (
        <div className="container addForm">
          <section className="ServDetailPopOut">
            <div className="contianer-fluid ServDetailPop">
              <div
                className="closeIcon"
                onClick={() => setShowAddCar(!showAddCar)}
              >
                <i className="fa fa-times"></i>
              </div>
              <div className="serveBack addressRemoveMob">
                <Link to={""} className="backbt">
                  <i className="fa fa-angle-left"></i>
                </Link>
                <h6>{t("common.add_car")}</h6>
              </div>
              <div className="ServDetailHead mobnone">
                <h2>
                  <Link to={"/"} className="backbt">
                    <i className="fa fa-angle-left"></i>
                  </Link>
                  {"Add Car"}
                </h2>
              </div>
              <div className="formOut">
                <form>
                  <div className="formInText">
                    <div className="inpuWidth">
                      <label className="required">
                        {t("common.car_brand")}
                      </label>
                      <select
                        name="car_name"
                        onChange={(e) =>
                          setCarDetails({
                            ...carDetails,
                            car_name: e.target.value,
                          })
                        }
                      >
                        <option value={""} selected>
                          {"Select"}
                        </option>
                        {carBrand?.map((list, key) => {
                          return (
                            <option key={key} value={list.car_name}>
                              {list.car_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="inpuWidth">
                      <label>{t("common.fuel_type")}</label>
                      <select
                        onChange={(e) =>
                          setCarDetails({
                            ...carDetails,
                            car_fuel_type: e.target.value,
                          })
                        }
                      >
                        <option value={""} selected>
                          {"Select"}
                        </option>
                        {carFuel?.map((list, key) => {
                          return (
                            <option key={key} value={list.name}>
                              {list.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* <div className="inputWidth locationIco " style={{flex: "0 0 100%", padding: "10px 15px"}}>
                  <label>Location</label>
                  <Geosuggest
                      placeholder={"Enter Location"}
                      initialValue={carDetails.location && carDetails.location}
                      onSuggestSelect={(e) => getLanLngForAddress(e)}
                      onChange={(e) => {
                          setCarDetails({...carDetails, location: e});
                      }}
                      required
                  />
                </div> */}
                  </div>
                  <div className="buttonsec">
                    <input
                      type="button"
                      value={"+ Add Car"}
                      onClick={handleAddCars}
                    />
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default MyCars;
