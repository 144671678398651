import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDuce50FCBdBsDbjNVNekzoXvcpJNfDLE0",
  authDomain: "green-shine-carwash-9096a.firebaseapp.com",
  projectId: "green-shine-carwash-9096a",
  storageBucket: "green-shine-carwash-9096a.appspot.com",
  messagingSenderId: "195835144335",
  appId: "1:195835144335:web:f101428c19c08c1c75e8fa",
  measurementId: "G-8PGFK90XN0",
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
