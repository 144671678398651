import React, { useEffect } from "react";
import "./AllSpecialities.scss";
import CatgoryBox from "../Common/CatgoryBox/CatgoryBox.jsx";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../Slices/home";
import { List } from "react-content-loader";
import { useTranslation, withTranslation, Trans } from "react-i18next";

const AllSpecialities = () => {
  const { categories, isCategoriesFetched } = useSelector(
    (state) => state.home
  );
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getAllCategories({ type: "main" }));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <section className="spaceSect all-specialities">
        <div className="container cateList">
          <div className="row borderBottomDesign titleHead">
            <h2 className="sectionTitle centerText">
              <Link to={"/"} className="backbt all-specialities-title">
                <i className="fa fa-angle-left"></i>
                {t("common.all")} {t("common.specialities")}
              </Link>
            </h2>
          </div>
          <div className="row headerFilter">
            {isCategoriesFetched ? (
              categories.length !== 0 ? (
                categories?.map((list) => {
                  return (
                    <Link to={`/subSpecialities/${list.id}`}>
                      {" "}
                      <CatgoryBox category={list} />{" "}
                    </Link>
                  );
                })
              ) : (
                <>
                  {/* <strong>{t('common.no_specialities_found')}</strong> */}
                </>
              )
            ) : (
              <List />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllSpecialities;
