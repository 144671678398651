import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Addcar.scss";
import { toast } from "react-toastify";
import { addUserCars, deleteUserCars, getAllUsersCar } from "../../Slices/auth";
import { getBrands, getFuel } from "../../Slices/home";
import { Navigate, useNavigate } from "react-router-dom";
export default function Addcar() {
  const [fuel, setFuel] = useState(-1);
  const dispatch = useDispatch();
  const [allCars, setAllCars] = useState([]);
  const [carDetails, setCarDetails] = useState({ car_type: 1 });
  const { carBrand, carFuel } = useSelector((state) => state.home);
  const [car, setCar] = useState("Select Car");
  const [type, setType] = useState(-1);
  const navigate = useNavigate();
  useEffect(() => {
    getCars();
    dispatch(getBrands());
    dispatch(getFuel());
    // eslint-disable-next-line
  }, []);

  const getCars = () => {
    dispatch(getAllUsersCar())
      .then((response) => {
        if (response.payload.status === 200) {
          // toast.success(response.payload.data.message)
          setAllCars(response.payload.data.result.data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleAddCars = () => {
    dispatch(addUserCars(carDetails))
      .then((response) => {
        console.log(response, "response");
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message);
          // setCarDetails({})
          getCars();
          navigate("/myProfile?page=cars");
        } else {
          toast.error("Something went wrong.");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const [active, setActive] = useState("item");
  const handleClick = (e) => {
    setActive(e);
  };

  return (
    <>
      <div className="container add-car-box">
        <div className="m-5">
          <div className="addCar-box">
            <div className="addCar-box-body">
              <div>
                <div className="addCar-box-input-item">
                  <label htmlFor="" className="addCar-box-input-label">
                    Car Brand
                  </label>
                </div>
                <div className="filter-box">
                  <div className="dropdown w-100">
                    <button
                      className="btn dropdown-toggle custom-dropdown p-2"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="discount-text selected-name">{car}</span>
                    </button>
                    <div
                      className="dropdown-menu add-car-drop-down-body"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {carBrand.map((item, index) => {
                        return (
                          <button
                            className="dropdown-item discount-text dropdown--item"
                            key={index}
                            value={item.car_name}
                            onClick={() => {
                              setCar(item.car_name);
                              setCarDetails(
                                { ...carDetails, car_name: item.car_name },
                                { ...carDetails, car_name: item.car_name }
                              );
                            }}
                          >
                            {" "}
                            {item.car_name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <span className="price-filter-text"> </span>
                </div>
              </div>

              <div className="mt-3">
                <div className="addCar-box-input-item">
                  <label htmlFor="" className="addCar-box-input-label">
                    Car Type
                  </label>
                </div>
                <div className="select-fuel">
                  {[
                    {
                      id: 1,
                      car_type: "Sedan",
                    },
                    {
                      id: 2,
                      car_type: "Hatchback",
                    },
                    {
                      id: 3,
                      car_type: "Suv",
                    },
                  ].map((item, index) => {
                    return (
                      <>
                        <div
                          className={`${
                            type === index
                              ? "car-type-box-active"
                              : "car-type-box"
                          } p-2`}
                          onClick={(e) => {
                            setType(index);
                            setCarDetails({ ...carDetails, car_type: item.id });
                          }}
                        >
                          <h3
                            className={`text-capitalize ${
                              type === index ? "car-type-active" : "car-type"
                            } mb-0`}
                          >
                            {item.car_type}
                          </h3>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              <div className="mt-3">
                <div className="addCar-box-input-item">
                  <label htmlFor="" className="addCar-box-input-label">
                    Fuel Type
                  </label>
                </div>
                <div className="select-fuel">
                  {carFuel.map((item, index) => {
                    return (
                      <>
                        <div
                          className={`${
                            fuel === index ? "active-fuel-item" : "fuel-item"
                          }`}
                          onClick={(e) => {
                            setFuel(index);
                            setCarDetails({
                              ...carDetails,
                              car_fuel_type: item.name,
                            });
                          }}
                        >
                          <h3
                            className={`text-capitalize ${
                              fuel === index ? "fuel-text-active" : "fuel-text"
                            }`}
                          >
                            {item.name}
                          </h3>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="mt-3">
                <button className="add-car-btn" onClick={handleAddCars}>
                  Add Car
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
