import React from "react";
import "./FooterMobileMenu.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const FooterMobileMenu = () => {
  const [selectedMenu, setSelectedMenu] = useState("home");
  const { t, i18n } = useTranslation();

  return (
    <section className="footerMob">
      <div className="container-fluid">
        <ul className="footerMobMenu">
          <li
            className={selectedMenu === "home" && "colorChange"}
            onClick={() => setSelectedMenu("home")}
          >
            <Link to={"/"}>
              <i className="fa fa-home"></i>
              <span className="font-family-heebo">{t("common.home")}</span>
            </Link>
          </li>
          <li
            className={selectedMenu === "specialities" && "colorChange"}
            onClick={() => setSelectedMenu("specialities")}
          >
            <Link to={"/allSpecialities"}>
              <i className="fa fa-list-alt"></i>
              <span className="font-family-heebo">
                {t("common.small_specialities")}
              </span>
            </Link>
          </li>
          {/* <li>
              <Link to={"/"}>
                   <i className='fa fa-shopping-cart'></i>
                   <span>Cart</span>
              </Link>
           </li> */}
          <li
            className={selectedMenu === "booking" && "colorChange"}
            onClick={() => setSelectedMenu("booking")}
          >
            <Link to={"/myProfile?page=booking"}>
              <i className="fa fa-list "></i>
              <span className="font-family-heebo">{t("common.bookings")}</span>
            </Link>
          </li>
          <li
            className={selectedMenu === "profile" && "colorChange"}
            onClick={() => setSelectedMenu("profile")}
          >
            <Link to={"/myProfile?page=profile"}>
              <i className="fa fa-user"></i>
              <span className="font-family-heebo">
                {t("common.first_capital_profile")}
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default FooterMobileMenu;
