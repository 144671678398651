import { Skeleton } from "@mui/material";
import React from "react";
import Button from "../../Common/Button/Button";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import clock from "../../../Assets/img/clock.png";
import { listFavorite, removeServiceToFavorite } from "../../../Slices/auth";
import { useTranslation } from "react-i18next";

const MyFavorite = () => {
  const dispatch = useDispatch();
  const [favoriteList, setFavoriteList] = useState({ loading: true, list: [] });
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    getFavoriteList();
    // eslint-disable-next-line
  }, []);

  const { list } = favoriteList;

  const getFavoriteList = () => {
    console.log(user);
    const data = {
      country_id: user.country_id,
    };
    dispatch(listFavorite(data))
      .then((response) => {
        setFavoriteList({ loading: false, list: response.payload.result.data });
        // toast.success(response.payload.message)
      })
      .catch((err) => {
        setFavoriteList({ ...favoriteList, loading: false });
        console.error(err);
      });
  };

  const handleRemoveFavorite = (e, id) => {
    e.preventDefault();
    dispatch(removeServiceToFavorite(id))
      .then((response) => {
        toast.info(response.payload.message);
        getFavoriteList();
      })
      .catch((err) => toast.error(err));
  };

  return (
    <div className="profDetailIn">
      <div className="profdetHeadText">
        <h3>{t("common.my_favourites")}</h3>
      </div>
      <div className="formOut profdet favBox">
        <form>
          <div className="formInText">
            {!favoriteList.loading ? (
              favoriteList.list && favoriteList.list.length > 0 ? (
                list.map((favService, key) => (
                  <div className="inpuWidth p-0" key={key}>
                    <div className="summarLeftIn p-4">
                      <div className="summarLeftImgText">
                        <div className="summarLeftImg">
                          <img src={favService.service_image_url} alt="img" />
                        </div>
                        <div className="headerFilterInfo">
                          <strong className="serviceName">
                            {favService?.service_name}
                          </strong>
                          <div className="contInfo">
                            <div className="contInfoIn">
                              <ul className="price">
                                <li>
                                  <span className="textSave">
                                    {favService?.symbol}
                                    {favService?.service_price}
                                  </span>
                                </li>
                              </ul>
                              <ul className="startTask">
                                <li>
                                  <img src={clock} alt="time" />
                                  <span className="time flex-grow-1">
                                    {favService.duration + " Min"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summarLeftCount">
                        {/* <button className='mb10 smbtn altsub'
                          onClick={(e) => handleRemoveFavorite(e, favService.service_id)}>
                          REMOVE
                        </button> */}
                        <button
                          onClick={(e) =>
                            handleRemoveFavorite(e, favService.service_id)
                          }
                          className="remove-btn mb-2"
                        >
                          {t("common.remove")}
                        </button>
                        <Button
                          serviceId={favService.service_id || favService.id}
                          data={favService}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <strong>{t("common.no_favourite_list")}</strong>
              )
            ) : (
              <>
                {[1, 2, 3, 4, 5, 6].map((data) => (
                  <div className="inpuWidth p-4 b-0">
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={100}
                      sx={{ margin: "12px", borderRadius: "12px" }}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyFavorite;
