import React, { useEffect, useState } from "react";
import "./ServiceDetailSchedule.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { setShowScheduleModal, getTimeSlots, setServiceValues } from '../../slices/service';
// import calculateDiscount from '../../helper/calculateDiscount';
import car from "../../Assets/img/carIcon.png";
import { toast, ToastContainer } from "react-toastify";
import { showAddressOpt, showServiceDetails } from "../../Slices/home";
import clock from "../../Assets/img/clock.png";
import moment from "moment";
import { handleBookingDetails } from "../../Slices/home";
import { useTranslation } from "react-i18next";
import { handleGetSlots } from "../../Slices/auth";

const ServiceDetailSchedule = () => {
  const { serviceDetails, bookingDetails } = useSelector((state) => state.home);
  const navigate = useNavigate();
  const [date, setDate] = useState(-1);
  const [time, setTime] = useState(-1);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [slotsData, setSlotsData] = useState([]);

  const [timing, setTiming] = useState({
    start_date: "",
    start_time: "",
  });
  const todayDate = new Date().toLocaleDateString("af-ZA");
  const days = [...Array(7).keys()].map((days) =>
    new Date(Date.now() + 86400000 * days).toDateString().split(" ")
  );
  // let serviceDetails=state.serviceDetails.service_details
  const handleNextStep = () => {
    if (timing?.start_date && timing?.start_time) {
      dispatch(handleBookingDetails({ ...timing }));
      navigate("/chooseCar");
    } else {
      toast.error(t("common.select_time_and_date"));
    }
  };
  console.log(serviceDetails);

  useEffect(() => {
    let formData = new FormData();
    console.log("first", timing);
    formData.append(
      "start_date",
      moment(timing.start_date).format("DD/MM/YYYY")
    );
    formData.append("sp_id", bookingDetails?.sp_id);
    dispatch(handleGetSlots(formData))
      .then((response) => {
        console.log(response, "slots");
        // setpaymentDetails({ ...response.payload.result.data });
        setSlotsData(response?.payload?.result?.data || []);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
        // setLoading(false);
      });
  }, [timing]);
  useEffect(() => {
    setTiming({
      start_time: bookingDetails?.start_time,
      start_date: bookingDetails?.start_date,
      start_time_full: bookingDetails?.start_time_full,
    });
  }, []);
  if (!bookingDetails?.sp_id) {
    return navigate("/");
  }
  return (
    <>
      <section className="container service-details">
        <div className="service-details-title">
          <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
          <span className="service-details-title-text">
            {t("common.schedule")}
          </span>
        </div>

        <div className="service-details-body">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="">
                <img
                  className="service-details-img"
                  src={serviceDetails?.service_image_url}
                  alt=""
                />
              </div>
              <div className="service-details-information marginTop-19">
                <div className="service-details-info">
                  <h6 className="service-details-name">
                    {serviceDetails?.service_name}
                  </h6>
                  <p className="service-details-description">
                    {serviceDetails?.description}
                  </p>
                </div>
                <div className="service-details-price-box">
                  {serviceDetails?.offer_price ? (
                    <>
                      <span className="service-details-mrp">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.price ? (
                    <>
                      <span className="service-details-price">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.offer_discount_price ? (
                    <>
                      <span className="service-details-save-price">
                        {t("common.save")} {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_discount_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="service-details-time">
                  <img src={clock} alt="" width={15} height={15} />
                  <span className="time">
                    {serviceDetails?.duration} {t("common.minutes")}.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-7">
                <div className="choose-provider">
                    <div className="schedule-title-box">
                      <div>
                        <span className="schedule-box">{t("common.schedule")}</span>
                      </div>
                      <div className="date-box">
                        <div className="date-bar">
                          <span className="selec-date-text">
                            {t("common.select_date_of_service")}
                          </span>
                          <span className="date">
                            {moment(new Date()).format("MMMM DD")}
                          </span>
                        </div>
                        <div className="date-list">
                          {[...Array(8)].map((item, index) => {
                            return (
                              <div
                                className={`${
                                  moment(
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() + index + 1
                                      )
                                    )
                                  ).format("YYYY-MM-DD") == timing.start_date
                                    ? `date--box-active`
                                    : `date--box`
                                }`}
                                onClick={() => {
                                  setDate(index);
                                  setTiming((old) => {
                                    return {
                                      ...old,
                                      start_date: moment(
                                        new Date(
                                          new Date().setDate(
                                            new Date().getDate() + index + 1
                                          )
                                        )
                                      ).format("YYYY-MM-DD"),
                                    };
                                  });
                                }}
                              >
                                <span className="day-name">
                                  {moment(
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() + index + 1
                                      )
                                    )
                                  ).format("ddd")}
                                </span>
                                <span className="date-day">
                                  {moment(
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() + index + 1
                                      )
                                    )
                                  ).format("DD")}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="time--box">
                        <span className="time-title">
                          {t("common.time_to_start_the_service")}
                        </span>
                        <div className="time-list">
                          {/* {[
                            "07:00",
                            "08:00",
                            "09:00",
                            "10:00",
                            "11:00",
                            "12:00",
                            "13:00",
                            "14:00",
                            "15:00",
                            "16:00",
                            "01:00",
                            "02:00",
                            "03:00",
                            "04:00",
                            "05:00",
                            "06:00",
                          ].map((item, index) => {
                            return (
                              <div
                                className={`${
                                  item === timing?.start_time_full
                                    ? "time-item-active"
                                    : "time-item"
                                }`}
                                onClick={() => {
                                  setTime(index);
                                  setTiming((old) => {
                                    return {
                                      ...old,
                                      start_time: item.split(" ")[0],
                                      start_time_full: item,
                                    };
                                  });
                                }}
                              >
                                {moment(item, ["h:mm A"]).format("HH:mm")}
                              </div>
                            );
                          })} */}
                          {console.log("slos", slotsData)}
                          {slotsData.map((item, index) => {
                            return (
                              <div
                                className={`${
                                  item.slot_start_time === timing?.start_time
                                    ? "time-item-active"
                                    : "time-item"
                                }`}
                                onClick={() => {
                                  setTime(index);
                                  setTiming((old) => {
                                    return {
                                      ...old,
                                      start_time: item.slot_start_time.split(" ")[0],
                                      start_time_full: item.slot_start_time,
                                    };
                                  });
                                }}
                              >
                                {moment(item.slot_start_time, ["h:mm A"]).format(
                                  "HH:mm"
                                )}{" "}
                                -{" "}
                                {moment(item.slot_end_time, ["h:mm A"]).format(
                                  "HH:mm"
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="choose-provider-footer">
                      <span className="choose-provider-price">
                        {t("common.total")} : ₹ {serviceDetails?.price}
                      </span>
                      {/* <Link to="/chooseCar">
                                          <button className='next-button'>CONTINUE</button>
                                      </Link> */}

                      <button
                        className="next-button"
                        onClick={() => handleNextStep()}
                      >
                        {t("common.continue")}
                      </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>

      {/* Old Design */}
      {/*
      <section className='ServDetailPopOut packDetShe'>
        <ToastContainer />
        <div className='contianer-fluid ServDetailPop'>
        <div className='serveBack'>
            <h6 onClick={() => dispatch(showServiceDetails(true))} style={{cursor: "pointer"}}>
                <Link to={""} className="backbt"><i className="fa fa-angle-left"></i></Link>
                Schedule
            </h6>
            </div>
            <div className='closeIcon' onClick={() => dispatch(showServiceDetails(false))}>
                <i className='fa fa-times'></i>
            </div>
            <div className='contetTextArea'>
                <div className='headerFilterInfo'>
                                <strong className='serviceName'>
                                    {/* {serviceDetail.service_name} */}
      {/*  Vacuum seats, carpet, floor mat, between and under...
                                </strong>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book…</p>
                                <div className='contInfo'>
                                    
                                <div className='contInfoIn'>
                                        <ul className='startTask'>
                                                
                                                <li>
                                                    
                                                    <img src={car} alt="time"/>
                                                    <span className='time flex-grow-1'>
                                                    30 Times
                                                        {/* {serviceDetail.service_duration} */}
      {/* </span>
                                            </li>
                                        </ul> 
                                    </div> 
                                </div>
            </div>
            <div className='listMenu aftNone'>
                <ul className='listTex'>
                    <li><span className='active'>Schedule</span></li>
                </ul>
            </div>
            <div className='row listBox mb15'>
                <div className='leftBrandLocation'>
                    <h4>Select Date of Service <span>August 2022</span></h4>
                    <ul className='listRadio locationradio' >
                        
                        <li>
                                <input type="radio"  name="date"/>
                                <label>
                                    <span>wed</span>
                                    <h5>26</h5>
                                </label>
                            </li>
                            <li>
                                <input type="radio"  name="date"/>
                                <label>
                                    <span>Thu</span>
                                    <h5>27</h5>
                                </label>
                            </li>
                            <li>
                                <input type="radio"  name="date"/>
                                <label>
                                    <span>Fre</span>
                                    <h5>28</h5>
                                </label>
                            </li>
                    </ul>
                </div>
            </div>
            <div className='row listBox aftNone mb0'>
                <div className='leftBrandLocation'>
                    <h4>Choose Prefered Time Slot</h4>
                    <ul className='listRadio locationradioList' >
                        
                    
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>07:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>08:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>09:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>10:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>11:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>07:00 AM</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <div className='detOpFoot'>
                 <div className='col priceNum'>
                        {/* 
                        <div className='totalPrice'>Total : <span className='price'> ₹ {total}</span></div> */}

      {/*   <div className='totalPrice'>Total : <span className='price'> ₹ 599</span></div>
                    </div>
                <div className='col rightDet'>
                <button style={{cursor: "pointer"}} onClick={() => dispatch(showAddressOpt(true))} >
                        CONTINUE
                    </button>
                </div>
            </div>
        </div>
        </section>*/}
    </>
  );
};

export default ServiceDetailSchedule;
