import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddAddress from "../../AddAddress/AddAddress";
import dot from "../../../Assets/img/menu_vertical.png";
import {
  deleteUserAddress,
  getAddressList,
  setDefaultaddress,
} from "../../../Slices/auth";
import { toast } from "react-toastify";
import { BulletList } from "react-content-loader";
import { Link } from "react-router-dom";
const MyAddress = () => {
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [address, setAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const getUserAddress = () => {
    dispatch(getAddressList())
      .then((response) => {
        if (response.payload.status === 200) {
          // toast.success(response.payload.data.message)
          setAddress(response.payload.data.result.data);

          setLoading(false);
        } else {
          toast.error(response.payload.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserAddress();
    // eslint-disable-next-line
  }, []);
  console.log(address, "address");
  const setDefault = (addressId) => {
    const data = {
      id: addressId,
    };
    dispatch(setDefaultaddress(data))
      .then((response) => {
        toast.success(response.payload.message);
        getUserAddress();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const deleteAddress = (id) => {
    dispatch(deleteUserAddress(id))
      .then((response) => {
        toast.success(response.payload.data.message);
        getUserAddress();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  console.log(address, "address");

  return (
    <div className="profDetailIn">
      {/* <div className='profdetHeadText'></div> */}
      <div className="profdetHeadText">
        <h3>
          {" "}
          <span></span> My Address
        </h3>
      </div>

      <div className="formOut profdet">
        <form>
          <div className="formInText mb0 pb10">
            <div className="inpuWidth addInput">
              <label>Saved Address</label>
            </div>
            <div className="inpuWidth addInput">
              <Link to={"/address"}>
                <label
                  className="textRight colChange"
                  style={{ cursor: "pointer" }}
                  // onClick={() => setShowAddAddress(!showAddAddress)}
                >
                  <span>+ Add New Address</span>
                </label>
              </Link>
            </div>
          </div>
          <div className="formInText mb0">
            {!loading ? (
              address.length > 0 ? (
                address.map((list) => {
                  return (
                    <div className="inpuWidth">
                      <label className="addresText">
                        <div className="address-default-box">
                          <h3 className="address-title">
                            {list?.address_type}
                          </h3>
                          {list?.is_default ? (
                            <>
                              <span className="default-text mb-0">Default</span>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <p className="address mt-2">{`${
                          list?.block_shop_no +
                          `, ` +
                          list?.location +
                          `, ` +
                          list?.city +
                          `, ` +
                          list?.state +
                          `, ` +
                          list?.country
                        }`}</p>
                        <div className="edit">
                          <img src={dot} alt="" />
                          <ul className="edtDel action-box">
                            <li>
                              <span
                                onClick={() => {
                                  setDefault(list?.id);
                                }}
                              >
                                Set Default
                              </span>
                            </li>
                            <li>
                              <Link to={`/address?id=${list?.id}`}>
                                {" "}
                                <span>Edit</span>
                              </Link>
                            </li>
                            <li>
                              <span onClick={() => deleteAddress(list.id)}>
                                Delete
                              </span>
                            </li>
                          </ul>
                        </div>
                      </label>
                    </div>
                  );
                })
              ) : (
                <strong>No Address Found</strong>
              )
            ) : (
              <BulletList />
            )}
          </div>
        </form>
      </div>

      {/* <div className='formOut profdet'>
          <form>
            <div className='formInText mb0 pb10'>
            <div className='inpuWidth addInput'>
              <label>Saved Address</label>
            </div>
            <div className='inpuWidth addInput'>
            <Link to={"/add-new-address"}>
              <label
                className='textRight colChange'
                style={{ cursor: "pointer" }}
                // onClick={() => setShowAddAddress(!showAddAddress)}
>
                <span>+ Add New Address</span>
              </label>
              </Link>
            </div>
          </div>
          </form>
      </div> */}

      {(showAddAddress || showEditAddress) && (
        <AddAddress
          showAddAddress={showAddAddress}
          showEditAddress={showEditAddress}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          setShowAddress={setShowAddAddress}
          setShowEditAddress={setShowEditAddress}
          getUserAddressList={getUserAddress}
        />
      )}
    </div>
  );
};

export default MyAddress;
