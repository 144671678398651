import React, { useEffect, useRef, useState } from "react";
import "./MyBookingUp.scss";
import file from "../../Assets/img/file.png";
import { useDispatch, useSelector } from "react-redux";
// import { setShowLocationModal, setShowOptionModal } from '../../slices/service';
import PricIncDec from "../Common/PricIncDec/PricIncDec.jsx";
import flat from "../../Assets/img/flat.png";
import home from "../../Assets/img/home.png";
import pic from "../../Assets/img/Bitmap.png";
import star from "../../Assets/img/star.png";
import clock from "../../Assets/img/clock.png";
import qr from "../../Assets/img/qr.png";
import topImg from "../../Assets/img/Group3.png";
import service from "../../Assets/img/service.png";
import direction from "../../Assets/img/navigate.png";

import facebook from "../../Assets/img/facebook.webp";
import instagram from "../../Assets/img/instagram.webp";
import twitter from "../../Assets/img/twitter.webp";

import { Link, useNavigate, useParams } from "react-router-dom";
import { detailsBooking, handleCancelRequst } from "../../Slices/auth";

import { toast } from "react-toastify";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { setLoading } from "../../Slices/home";
import { getCarType } from "../../Slices/home";
import { useTranslation } from "react-i18next";
const MyBookRequest = () => {
  const [show, setShow] = useState(true);
  const [cartype, setCarType] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reason = useRef(null);
  const [details, setDetails] = useState({});
  const [socialLink, setSocialLink] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [dLength, setDlength] = useState(30);
  const { t, i18n } = useTranslation();

  const setCancelRequst = (e) => {
    const formData = new FormData();
    formData.append("cancellation_reason", reason.current.value || "");
    formData.append("code", details.code);

    dispatch(handleCancelRequst(formData))
      .then((response) => {
        console.log("cancel", response);
        if (response.meta.requestStatus != "rejected") {
          toast.success(response.payload.message, {
            position: "top-center",
          });

          getBookigDetails();
          navigate("/myProfile?page=booking");
        } else {
          toast.error(response.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(".....");
      });
  };
  const getBookigDetails = () => {
    setIsLoading(true);
    dispatch(detailsBooking(id))
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setDetails(response.payload.result.data);
        setSocialLink(response?.payload?.result?.socialMediaLinks[0]);
      })
      .catch(() => {
        setIsLoading(false);
        // toast.error("Failed To Fetch Data");
      });
  };
  useEffect(() => {
    getBookigDetails();
    dispatch(getCarType())
      .then((response) => {
        setCarType(response.payload.data.result.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="container">
      <div className="">
        <div className="main_book_section mb-3">
          <div className="book_section_back ">
            <span className="" onClick={() => navigate(-1)}>
              <i class="fa fa-angle-left"></i>
            </span>
            <p className="section-booking-title">
              {t("common.booking_details")}
            </p>
          </div>
          {!isLoading ? (
            <div className="ServDetailPop mt-5">
              <div className="summarLeft">
                <div className="summarLeftIn">
                  <div className="summarLeftImgText">
                    <div className="summarLeftImg">
                      <img src={details?.service_details?.service_image_url} />
                    </div>
                    <div className="headerFilterInfo">
                      <strong className="serviceName primary-font">
                        {details?.service_details?.service_name}
                      </strong>
                      <p className="primary-font">
                        {details?.service_details?.description.substring(
                          0,
                          dLength
                        )}{" "}
                        {details?.service_details?.description.length >
                          dLength && <span>...</span>}
                      </p>
                      {details?.service_details?.description.length > dLength &&
                        (details?.service_details?.description.length ==
                        dLength ? (
                          <span
                            onClick={() => {
                              setDlength(30);
                            }}
                            className="readLesMore primary-font"
                          >
                            {t("common.read_less")}
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              setDlength(
                                details?.service_details?.description.length
                              );
                            }}
                            className="readLesMore primary-font"
                          >
                            {t("common.read_more")}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="bookInfo">
                  <ul className="bookINfoIn">
                    <li>
                      <h5 className="primary-font">{t("common.job_id")}</h5>
                      <span className="primary-font">{details?.code}</span>
                    </li>
                    <li>
                      <h5 className="primary-font">{t("common.car")}</h5>
                      <span className="primary-font">
                        {details?.car_details?.car_name} (
                        {details?.car_details?.car_fuel_type})
                      </span>
                      <h6>
                        {cartype.map((cartype, index) =>
                          cartype.id === details?.car_details?.car_type ? (
                            <>
                              <span
                                className="green-text font-family-heebo"
                                key={index}
                              >{`(${cartype?.car_type})`}</span>
                            </>
                          ) : (
                            <></>
                          )
                        )}
                      </h6>
                    </li>
                    <li>
                      <h5 className="primary-font">{t("common.price")}</h5>
                      <span className="primary-font">
                        ₹ {details?.total_price || 0}
                      </span>
                    </li>
                    <li>
                      <h5 className="primary-font">{t("common.status")}</h5>
                      {details?.status_name == "Accepetd" ? (
                        <span className="accep primary-font">
                          {t("common.accepted")}
                        </span>
                      ) : details?.status_name == "Payment Requested" ? (
                        <span className="payReq primary-font">
                          {t("common.payment_requested")}
                        </span>
                      ) : details?.status_name == "Payment Completed" ? (
                        <span className="payComp primary-font">
                          {t("common.payment_completed")}
                        </span>
                      ) : details?.status_name == "Running" ? (
                        <span className="running primary-font">
                          {details?.status_name}
                        </span>
                      ) : (
                        <span className="req primary-font">
                          {details?.status_name}
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
                <div className="summarLeftAdd">
                  <ul className="listRadio addlocationradio">
                    <li>
                      <ul className="listRadio  locationradioList">
                        <li className="date">
                          <label>
                            <h5 className="primary-font fs-16">
                              {moment(details.start_date).format("DD MMM")}
                            </h5>
                          </label>
                        </li>
                        <li className="date">
                          <label>
                            <h5 className="primary-font fs-15">
                              {moment(
                                details.start_time,
                                "ddd DD-MMM-YYYY, hh A"
                              ).format("hh:mm A")}
                            </h5>
                          </label>
                        </li>
                        <li className="date">
                          <label>
                            <h5 className="primary-font fs-15">
                              {details?.duration} {t("common.minutes")}
                            </h5>
                          </label>
                        </li>
                        <li className="date">
                          <label>
                            <h5 className="primary-font fs-15">
                              {details?.location_type}
                            </h5>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  {/* <ul className="listRadio addlocationradio">
                    {details?.location_type == "outlet" && (
                      <a
                        href={`https://www.google.com/maps/?q=${details?.sp_address?.latitude},${details?.sp_address?.longitude}`}
                        className="navigate_btn"
                      >
                        {" "}
                        <img height={"30px"} width={"30px"} src={direction} />
                        Navigate Now
                      </a>
                    )}

                    <li className="addText">
                      <div> 
                         <h5 className="primary-font">{details?.location_type}</h5>
                          <div>
                            <div className="navigate_btn">
                              <img src={direction} width={30} alt={"/"}/>Navigate Now</div>
                          </div>
                      </div>
                      <label>
                        <p className="primary-font">
                          {details?.location || ""}
                        </p>
                      </label>
                    </li>
                  </ul>  */}

                  <div className="add-location-box">
                    <div className="address-box-title">
                      <h5 className="primary-font address--title">
                        {console.log(console.log(details, "details"))}
                        {details?.location_type}
                      </h5>
                      {details?.location_type == "outlet" && (
                        <div className="navigate_btn mt-0">
                          <img
                            src={direction}
                            width={20}
                            height={20}
                            alt={"/"}
                          />
                          <div>
                            <a
                              href={`https://www.google.com/maps/?q=${details?.sp_address?.latitude},${details?.sp_address?.longitude}`}
                              className="navigate_btn mt-0"
                            >
                              {" "}
                              <span>{t("common.navigate_now")} </span>{" "}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="primary-font address-text">
                        {details?.location || ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="book_right_wrap">
                {show ? (
                  <>
                  <div className="book_right_block">
                    <h2 className="primary-font">
                      {t("common.payment_summary")}
                    </h2>
                    <div className="book_right_sec">
                      <div className="book_right_item">
                        <div className="book_right_inner_item">
                          <p className="primary-font">
                            {t("common.item_total")}
                          </p>
                          <h2 className="primary-font">
                            ₹ {details?.payment_summary_data?.item_total || 0}{" "}
                          </h2>
                        </div>
                        <div className="book_right_inner_item">
                          <p className="primary-font">{t("common.tax")}</p>
                          <h2 className="primary-font">
                            ₹ {details?.payment_summary_data?.tax_amount || 0}
                          </h2>
                        </div>
                        <div className="book_right_inner_item ">
                          <p className="green_p primary-font">
                            {t("common.coupon_discount")}
                          </p>
                          <h2 className="green_h2 primary-font">
                            - ₹{" "}
                            {details?.payment_summary_data?.coupon_discount ||
                              0}
                          </h2>
                        </div>
                      </div>
                      <div className="book_right_item">
                        <div className="book_right_inner_item">
                          <h2 className="primary-font">{t("common.total")}</h2>
                          <h2 className="primary-font">
                            ₹ {details?.payment_summary_data?.final_amount || 0}
                          </h2>
                        </div>
                        {details?.status_id == 9 ? (
                          <>
                            {details.tip_percentage ? (
                              <div className="book_right_inner_item">
                                <p className="primary-font">
                                  {details?.tip_percentage || 0}%{" "}
                                  {t("common.tip_paid")}
                                </p>
                                <h2 className="primary-font">
                                  - ₹ {details?.tip_price || 0}
                                </h2>
                              </div>
                            ) : (
                              <div className="book_right_inner_item">
                                <p className="primary-font">
                                  {details?.tip_price} {t("common.tip_paid")}
                                </p>
                                <h2 className="primary-font">
                                  + ₹ {details?.tip_price || 0}
                                </h2>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="book_right_inner_item">
                            <p className="primary-font">
                              {((details?.payment_summary_data
                                ?.first_installment || 0) /
                                (details?.payment_summary_data?.final_amount ||
                                  1)) *
                                100}
                              % {t("common.amount_paid")}
                            </p>
                            <h2 className="primary-font">
                              - ₹{" "}
                              {details?.payment_summary_data
                                ?.first_installment || 0}
                            </h2>
                          </div>
                        )}
                      </div>
                      <div className="book_right_item">
                        {details?.status_id == 9 ? (
                          <div className="book_right_inner_item">
                            <h2 className="primary-font">
                              {t("common.total_fully_paid")}
                            </h2>
                            <h2 className="primary-font">
                              ₹ {details?.total_price + details?.tip_price}
                            </h2>
                          </div>
                        ) : (
                          <div className="book_right_inner_item">
                            <h2 className="primary-font">
                              {t("common.remaining_amount_to_pay")}
                            </h2>
                            <h2 className="primary-font">
                              ₹{" "}
                              {details?.payment_summary_data
                                ?.second_installment || 0}
                            </h2>
                          </div>
                        )}

                        <div className="book_right_inner_item">
                          <span className="primary-font">
                            ({t("common.you_save")} ₹{" "}
                            {details?.payment_summary_data?.coupon_discount ||
                              0}{" "}
                            {t("common.on_this_booking")})
                          </span>
                        </div>
                      </div>
                    </div>
                    {details?.status_name == "Accepetd" ? (
                      <div className="book_right_cancel">
                        <a
                          className="cancel primary-font"
                          href="#0"
                          onClick={() => setShow(!show)}
                        >
                          {t("common.cancel")}
                        </a>
                      </div>
                    ) : details?.status_name == "Payment Requested" ? (
                      <div className="book_right_cancel">
                        <Link
                          className="remaining primary-font"
                          to={`/payment/${details.code}`}
                        >
                          {t("common.pay_remaining_amount")}
                        </Link>
                      </div>
                    ) : details?.status_name == "Payment Completed" ? (
                      <div className="book_right_cancel">
                        <button className="otp primary-font" href="#0">
                          OTP : {details?.otp}
                        </button>
                      </div>
                    ) : details?.status_id == 10 ? (
                      <div className="book_right_cancel">
                        <Link className={`complain primary-font ${details?.is_complaint == 1 && "disable"}`} to={`/raise-complain/${details?.code}/${details?.sp_id}`}>
                          {details?.is_complaint ==1  ? "Already Raised Complaint" : "Raise a Complaint"}
                        </Link>
                      </div>
                    ) : null}
              
                  </div>
                  <div className="scoial_icon">
                      <ul>
                         <li><a href={socialLink?.facebook_link} target="_blank" ><img src={facebook} alt="icon" /></a></li>
                         <li><a href={socialLink?.twitter_link} target="_blank" > <img src={twitter} alt="icon" /></a></li>
                         <li><a href={socialLink?.instagram_link} target="_blank" ><img src={instagram} alt="icon" /></a></li>
                      </ul>
                  </div>
                  </>
                
                ) : (
                  <div className="book_submit_block">
                    <div className="book_submit_wrap">
                      <div className="book_submit_sec">
                        <p className="primary-font">
                          {t("common.reason_for_cancelation")}
                        </p>
                        <span className="primary-font">
                          {t("common.20_amount_you_paid_will_not_be_rufunded")}
                        </span>
                      </div>
                      <textarea
                        placeholder="Enter Reason Here…"
                        ref={reason}
                      ></textarea>
                      <div
                        className="book_right_cancel"
                        onClick={setCancelRequst}
                      >
                        <a className="primary-font">{t("common.submit")}</a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="ServDetailPop mt-5">
                <div className="summarLeft">
                  <Skeleton
                    variant="rectangular"
                    height={500}
                    sx={{ borderRadius: "12px" }}
                  />
                </div>
                <div className="book_right_wrap">
                  <Skeleton
                    variant="rectangular"
                    height={500}
                    sx={{ borderRadius: "12px" }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyBookRequest;
