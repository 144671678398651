import React, { useEffect } from "react";
import "./ServiceListing.scss";
import saleTag from "../../Assets/img/icons8-price_tag.png";
import priceTag from "../../Assets/img/icons8-sale_price_tag.png";
import SalonCatSlider from "../Common/SalonCatSlider/salonCatSlider";
import ProductBox from "../ProductBox/ProductBox";
import service from "../../Assets/img/service.png";
import filtIcon from "../../Assets/img/icons8-filter.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCategoryListById, getDashboardById } from "../../Slices/home";
import ServiceDetail from "../ServiceDetail/ServiceDetail";
import ServiceDetailSchedule from "../ServiceDetailSchedule/ServiceDetailSchedule";
import ServiceDetailAddress from "../ServiceDetailAddress/ServiceDetailAddress";
import PaymentOption from "../PaymentOption/PaymentOption";
import ConFirmBooking from "../ConFirmBooking/ConFirmBooking";
import { useState } from "react";
import { List } from "react-content-loader";
import { getAllCategories } from "../../Slices/home";
import Slider from "react-slick";
const ServiceListing = () => {
  const {
    showServiceDetails,
    showScheduleOpt,
    showAddressOpt,
    showPaymentOpt,
    showConfirmBooking,
    dashboardSections,
    isDashboardSectionsFetched,
  } = useSelector((state) => state.home);

  const dispatch = useDispatch();
  const { id } = useParams();
  const [dashboardById, setDashboardById] = useState();
  const [dashLoading, setDashLoading] = useState(false);
  const [discount, setDiscount] = useState("Select Discount");
  const [price, setPrice] = useState("Select Price");
  const [category, setCategory] = useState(null);
  const { categories } = useSelector((state) => state.home);
  const [list, setList] = useState([]);
  const [filtterData, setFiltterData] = useState([]);
  const { fillterlist } = useSelector((state) => state.home);
  const navigate = useNavigate();
  const getDashById = () => {
    setDashLoading(true);
    dispatch(getDashboardById({ id: id, data: { country_id: "103" } }))
      .then((response) => {
        setDashboardById(response.payload.data.result.data);
        setDashLoading(false);
      })
      .catch((err) => {
        console.info(err);
        setDashLoading(true);
      });
  };

  useEffect(() => {
    setCategory(null);
    getDashById();
    dispatch(getAllCategories({ type: "main" }));
    // eslint-disable-next-line
    setList(dashboardById);
  }, []);

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const fillter = (id) => {
    setCategory(id);
    console.log(category, "category");
    const data = {
      category_id: id,
      country_id: "103",
    };
    dispatch(getCategoryListById(data));
  };

  return (
    <>
      <section>
        <div>
          <section className="iconSlider servListout">
            <Slider {...settings}>
              {categories.map((item, index) => {
                return (
                  <>
                    <div
                      className={`subImgIn `}
                      onClick={() => fillter(item?.id)}
                    >
                      <span
                        className={`subImg ${
                          category === item?.id ? `border-active` : ``
                        }`}
                      >
                        <img
                          className="categry-img"
                          src={item?.category_image_url}
                          alt="Interior Wash"
                        />
                        <h6 className="categry-name">{item?.category_name}</h6>
                      </span>
                    </div>
                  </>
                );
              })}
            </Slider>
          </section>

          <div className="service-box">
            {!dashLoading ? (
              dashboardById?.map((filteredSection) => (
                <>
                  <div className="container">
                    <div className="section-title-box">
                      <div className="title-box-bax">
                        <i
                          className="fa fa-angle-left"
                          onClick={() => navigate(-1)}
                        ></i>
                        <h2 className="sectionTitle centerText">
                          {filteredSection.section_title}
                        </h2>
                      </div>
                      {/* <div className="filter-box">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle custom-dropdown discount--dropdown"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={priceTag}
                        alt=""
                        width={20}
                      />
                      <span className="discount-text">
                        {"Discount: " + discount || "Select Discount"}{" "}
                      </span>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        className="dropdown-item discount-text dropdown--item"
                        href="#"
                        onClick={() => {
                          setDiscount("10%-20%");
                        }}
                      >
                        10%-20%
                      </button>
                      <button
                        className="dropdown-item discount-text dropdown--item"
                        href="#"
                        onClick={() => {
                          setDiscount("20%-25%");
                        }}
                      >
                        20%-25%
                      </button>
                      <button
                        className="dropdown-item discount-text dropdown--item"
                        href="#"
                        onClick={() => {
                          setDiscount("25%-30%");
                        }}
                      >
                        25%-30%
                      </button>
                    </div>
                  </div>
                  <div className="dropdown">
                      <button
                        className="btn dropdown-toggle custom-dropdown price-dropdown price--dropdown"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={saleTag} alt="" />
                        <span className="discount-text">
                          {"Price Range : " + price}{" "}
                        </span>
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item discount-text dropdown--item"
                          href="#"
                          onClick={() => {
                            setPrice("₹500 - ₹1000");
                          }}
                        >
                          ₹500 - ₹1000
                        </button>
                        <button
                          className="dropdown-item discount-text dropdown--item"
                          href="#"
                          onClick={() => {
                            setPrice("₹1500 - ₹2000");
                          }}
                        >
                          ₹1500 - ₹2000
                        </button>
                        <button
                          className="dropdown-item discount-text dropdown--item"
                          href="#"
                          onClick={() => {
                            setPrice("₹3000 - ₹5000");
                          }}
                        >
                          ₹3000 - ₹5000
                        </button>
                      </div>
                  </div>

                    
                 
                </div> */}
                    </div>
                    <hr className="hr" />
                  </div>

                  {!category ? (
                    <>
                      <div className="container">
                        <div className="box row">
                          {filteredSection?.dashboard_services &&
                            filteredSection?.dashboard_services.map(
                              (services, key) => (
                                <>
                                  <div className="col-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                                    <ProductBox services={services} key={key} />
                                  </div>
                                </>
                              )
                            )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="container">
                        <div className="box row">
                          {fillterlist[0]?.sub_categories.map((item, key) => (
                            <>
                              {
                                <>
                                  {item?.services.map((element, index) => (
                                    <>
                                      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                                        <ProductBox
                                          services={element}
                                          key={index}
                                        />
                                      </div>
                                    </>
                                  ))}
                                </>
                              }
                            </>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))
            ) : (
              <div className="container mt-5">
                <List />
              </div>
            )}
          </div>
        </div>

        {/* {showServiceDetails && <ServiceDetail />}
        {showScheduleOpt && <ServiceDetailSchedule />}
        {showAddressOpt && <ServiceDetailAddress />}
        {showPaymentOpt && <PaymentOption />}
        {showConfirmBooking && <ConFirmBooking />} */}
      </section>
    </>
  );
};

export default ServiceListing;
