import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleBookingDetails } from "../../Slices/auth";
import "./PackageDetailSchedule.scss";
import car from "../../Assets/img/carIcon.png";
import { handlePackageBookingDetails } from "../../Slices/home";
import { toast } from "react-toastify";

const PackageDetailSchedule = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(-1);
  const dispatch = useDispatch();
  const { packageDetail, packageBookingDetails } = useSelector(
    (state) => state.home
  );
  const handleNextStep = () => {
    if (packageBookingDetails.reminder_id) {
      navigate("/PackagePaymentOption");
    } else {
      toast.error("Select Reminder Is");
    }
  };

  return (
    <>
      <section className="container service-details">
        <div className="service-details-title">
          <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
          <span className="service-details-title-text">Schedule</span>
        </div>

        <div className="service-details-body">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="">
                <img
                  className="service-details-img"
                  src={packageDetail?.package_image_url}
                  alt=""
                />
              </div>

              <div className="service-details-information marginTop-19 Schedule bg-transparent">
                <div className="service-details-info">
                  <ul className="price">
                    <li className="price">
                      <h3>
                        {packageDetail?.currency?.symbol}
                        {packageDetail?.final_price}
                      </h3>
                    </li>
                    <>
                      <li>
                        <span className="textCut price">
                          {packageDetail?.total_price}
                        </span>
                      </li>
                      <li>
                        <span className="textSave font-weight-bold save-price">
                          Save
                          {packageDetail?.discount_price}
                          {` (` + packageDetail?.discount_percentage + `%)`}
                        </span>
                      </li>
                    </>
                  </ul>
                  <h6 className="service-details-name">
                    {packageDetail?.package_name}
                  </h6>
                  <p className="Schedule-service-details-description">
                    {packageDetail?.package_description}
                  </p>
                </div>
                <div className="service-details-time">
                  <img src={car} alt="" width={15} height={15} />
                  <span className="time">
                    {packageDetail?.package_service_limit} Time.{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-7 choose-provider">
              <div className="schedule-title-box p-0">
                <div className="padding-top-buttom-12">
                  <span className="schedule-box">Frequency</span>
                </div>
                <div className="date-box">
                  <div className="date-bar">
                    <span className="selec-date-text">
                      When would you like to be reminded?
                    </span>
                  </div>
                  <div className="date-list">
                    {[
                      "Daily",
                      "Weekly",
                      "By weekly",
                      "Monthly",
                      "Quarterly",
                    ].map((item, index) => {
                      return (
                        <div
                          className={`${
                            packageBookingDetails?.reminder_id == index + 1
                              ? `date--box-active`
                              : `date--box`
                          } p-2 flex-fill bd-highlight`}
                          onClick={() => {
                            dispatch(
                              handlePackageBookingDetails({
                                reminder_id: index + 1,
                              })
                            );
                          }}
                        >
                          <span className="package-schedule-time">{item}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="choose-provider-footer">
                  <span className="choose-provider-price">
                    Total : {packageDetail?.currency?.symbol}{" "}
                    {packageDetail?.total_price}
                  </span>

                  <button className="next-button" onClick={handleNextStep}>
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PackageDetailSchedule;
