import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clock from "../../Assets/img/clock.png";
import { listBooking } from "../../Slices/auth";
import { getCarType } from "../../Slices/home";
import home from "../../Assets/img/home.png";
import pic from "../../Assets/img/Bitmap.png";
import star from "../../Assets/img/star.png";
import qr from "../../Assets/img/qr.png";
import service from "../../Assets/img/service.png";
import { Link, useNavigate } from "react-router-dom";
import "./MyBookings.scss";
import MyBookingAccept from "../MyBookingUp/MyBookingUp";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

const MyBookings = () => {
  const [activeTab, setActiveTab] = useState({
    current: true,
    completed: false,
    cancelled: false,
    loading: true,
  });
  console.log(activeTab, "activeTab");
  const [popup, setPopup] = useState("");
  const [detail, Setdetail] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [carType, setCarType] = useState([]);
  const [selectedTabListing, setSelectedTabListing] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleTabChange = (tab) => {
    setisLoading(true);

    switch (tab) {
      case "current":
        setActiveTab({
          current: true,
          completed: false,
          cancelled: false,
          loading: true,
        });
        dispatch(listBooking("current"))
          .then((response) => {
            setisLoading(false);
            setSelectedTabListing(response.payload.result.data);
            setActiveTab({
              current: true,
              completed: false,
              cancelled: false,
              loading: false,
            });
          })
          .catch(() => {
            setisLoading(false);
            setSelectedTabListing([]);
            setActiveTab({
              current: true,
              completed: false,
              cancelled: false,
              loading: false,
            });
          });
        break;
      case "completed":
        setActiveTab({
          current: false,
          completed: true,
          cancelled: false,
          loading: true,
        });
        dispatch(listBooking("completed"))
          .then((response) => {
            setisLoading(false);
            setSelectedTabListing(response.payload.result.data);
            setActiveTab({
              current: false,
              completed: true,
              cancelled: false,
              loading: false,
            });
          })
          .catch(() => {
            setisLoading(false);
            setActiveTab({
              current: false,
              completed: true,
              cancelled: false,
              loading: false,
            });
            setSelectedTabListing([]);
          });
        break;
      case "cancelled":
        setActiveTab({
          current: false,
          completed: false,
          cancelled: true,
          loading: false,
        });
        dispatch(listBooking("cancelled"))
          .then((response) => {
            setisLoading(false);
            setSelectedTabListing(response.payload.result.data);
            setActiveTab({
              current: false,
              completed: false,
              cancelled: true,
              loading: false,
            });
          })
          .catch(() => {
            setSelectedTabListing([]);
            setisLoading(false);
            setActiveTab({
              current: false,
              completed: false,
              cancelled: true,
              loading: false,
            });
          });
        break;
      default:
        break;
    }
  };

  const handleClick = (id) => {
    setPopup(id);
  };

  useEffect(() => {
    dispatch(listBooking("current"))
      .then((response) => {
        setisLoading(false);
        setActiveTab({ ...activeTab, loading: false });
        setSelectedTabListing(response.payload.result.data);
        dispatch(getCarType())
          .then((response) => {
            setCarType(response.payload.data.result.data);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch(() => {
        setisLoading(false);
        setSelectedTabListing([]);
        setActiveTab({ ...activeTab, loading: false });
      });
    // eslint-disable-next-line
  }, []);

  // console.log(selectedTabListing,"selectedTabListing")

  // console.log(carType,"carTypecarTypecarType")
  // console.log(selectedTabListing,"selectedTabListing")

  return (
    <div className="myProfileOut pt0">
      <div className="profDetailIn">
        <div className="profdetHead profdetHeadNoRev ">
          <h3>{t("common.my_bookings")}</h3>
          <ul className="rightTabBar">
            <li onClick={() => handleTabChange("current")}>
              <Link
                to={"#"}
                className={`upcoming ${activeTab.current && "active"}`}
              >
                {t("common.current")}
              </Link>
            </li>

            <li onClick={() => handleTabChange("completed")}>
              <Link
                to={"#"}
                className={`completed ${activeTab.completed && "active"}`}
              >
                {t("common.completed")}
              </Link>
            </li>
            <li onClick={() => handleTabChange("cancelled")}>
              <Link
                to={"#"}
                className={`expire ${activeTab.cancelled && "active"}`}
              >
                {t("common.canceled")}
              </Link>
            </li>
          </ul>
        </div>

        {/* 
        <div className='profdetHeadText'>


          {popup === '' ? "" :

            <MyBookingAccept handleClick={handleClick} Details={selectedTabListing} Key={popup} />

          }
        </div> */}

        <div div className="formOut profdet">
          <form>
            <div
              className="formInText bookForm booking--list"
              style={{ cursor: "pointer" }}
            >
              {!isLoading ? (
                selectedTabListing?.map((singleBooking) => (
                  <div
                    className="inpuWidth sumWid"
                    onClick={() =>
                      navigate(`/bokingDetails/${singleBooking.code || 1}`)
                    }
                  >
                    <div className="sumWidIn">
                      <div className="box--box">
                        <div className="summarLeftImgText">
                          <div className="summarLeftImg">
                            <img
                              src={
                                singleBooking?.service_details
                                  ?.service_image_url
                              }
                              alt=""
                            />
                          </div>

                          <div className="headerFilterInfo">
                            <strong className="serviceName">
                              {singleBooking?.service_details?.service_name}
                            </strong>
                            <div
                              className="textEllips"
                              dangerouslySetInnerHTML={{
                                __html:
                                  singleBooking?.service_details?.description,
                              }}
                            />
                            <div className="contInfo">
                              <div className="contInfoIn">
                                <ul className="startTask">
                                  <li>
                                    <img src={clock} alt="time" />
                                    <span className="time flex-grow-1">
                                      {singleBooking?.duration + "min"}
                                    </span>
                                    {/* <span>30 Min.</span> */}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {singleBooking.is_package ? (
                          <>
                            <div className="package-teg">
                              <span className="package-text">Package</span>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* <h4 className="stretched-linksssss---" style={{'cursor':"pointer"}} key={"__"+key} onClick={() => setPopup(key)}> Details </h4> */}
                      {/* <h4 className="stretched-linksssss---" style={{ 'cursor': "pointer" }} > Details </h4> */}
                      <div className="bookInfo">
                        <ul className="bookINfoIn">
                          <li>
                            <h5>{t("common.job_id")}</h5>
                            <span>{singleBooking.code}</span>
                          </li>
                          <li>
                            <h5>{t("common.car")} </h5>
                            {singleBooking.car_details != null ? (
                              <div>
                                <span>
                                  {singleBooking.car_details.car_name +
                                    singleBooking.car_details.car_type +
                                    `(${singleBooking.car_details.car_fuel_type})`}
                                </span>

                                <span>
                                  {carType.map((cartype, index) =>
                                    cartype.id ===
                                    singleBooking?.car_details?.car_type ? (
                                      <>
                                        <span
                                          className="text-green"
                                          key={index}
                                        >{`(${cartype?.car_type})`}</span>
                                      </>
                                    ) : (
                                      <></>
                                    )
                                  )}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </li>
                          <li>
                            <h5>{t("common.price")}</h5>
                            <span>₹ {singleBooking.total_price}</span>
                            {/* <span>₹ 430</span> */}
                          </li>
                          <li>
                            <h5>{t("common.status")}</h5>
                            {singleBooking?.status_name == "Accepetd" ? (
                              <span className="green_span">
                                {t("common.accepted")}
                              </span>
                            ) : singleBooking?.status_name ==
                              "Payment Requested" ? (
                              <span className="parpel_span">
                                {t("common.payment_requested")}
                              </span>
                            ) : singleBooking?.status_name ==
                              "Payment Completed" ? (
                              <span className="completed_span">
                                {t("common.payment_completed")}
                              </span>
                            ) : (
                              <span className="req status">
                                {singleBooking?.status_name}
                              </span>
                            )}
                            {/* <span className="yellow_span">Requested</span> */}
                          </li>
                        </ul>
                      </div>
                      <div className="summarLeftAdd">
                        <ul className="listRadio addlocationradio">
                          <li className="">
                            <ul className="listRadio  locationradioList">
                              <li className="date">
                                <label>
                                  <h5>
                                    {moment(singleBooking.start_date).format(
                                      "DD MMMM"
                                    )}
                                  </h5>
                                  {/* <h5> 26 August</h5> */}
                                </label>
                              </li>
                              <li className="date">
                                <label>
                                  <h5>
                                    {moment(
                                      singleBooking.start_time,
                                      "ddd DD-MMM-YYYY, hh A"
                                    ).format("hh:mm A")}
                                  </h5>
                                  {/* <h5>02:00 PM</h5> */}
                                </label>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={400}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={400}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={400}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={400}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={400}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyBookings;
