import React, { useEffect, useState } from "react";
import "./ChooseProvider.scss";
import { useDispatch, useSelector } from "react-redux";
import clock from "../../Assets/img/clock.png";
import { useNavigate, Link } from "react-router-dom";
// import { GetSpList } from "../../../Slices/auth";
import { toast } from "react-toastify";
import { GetSpList, handleServiceProvider } from "../../Slices/auth";
import { Skeleton } from "@mui/material";
import { handleBookingDetails } from "../../Slices/home";
import { useTranslation } from "react-i18next";

export default function ChooseProvider() {
  const [SPlist, SelectSP_list] = useState([]);
  const [provider, setProvider] = useState();
  const [loading, setLoading] = useState(true);
  const { serviceDetails, bookingDetails } = useSelector((state) => state.home);

  const navigate = useNavigate();

  const handleNextStep = () => {
    console.log(bookingDetails, "book");
    if (bookingDetails.sp_id) {
      // dispatch(handleBookingDetails({ ...timing }));
      navigate("/ServiceSchedule");
    } else {
      toast.error(t("common.select_provider_error"));
    }
  };

  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    const formData = new FormData();
    formData.append("service_id", serviceDetails.id);
    dispatch(GetSpList(formData))
      .then((response) => {
        console.log("hello", response);
        SelectSP_list([...response.payload.result.data]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(t("common.no_provider_found"));
      });
  }, []);
  if (!serviceDetails?.id) {
    return navigate("/");
  }
  console.log(SPlist, "serviceDetails");
  return (
    <section className="container service-details">
      <div className="service-details-title">
        <i className="fa fa-angle-left" onClick={() => navigate(-1)}></i>
        <span className="service-details-title-text">
          {t("common.service_provider")}
        </span>
      </div>

      <div className="service-details-body">
        <div className="row">
          <div className="col-12 col-lg-5">
            <div className="">
              <img
                className="service-details-img"
                src={serviceDetails?.service_image_url}
                alt=""
              />
            </div>
            <div className="service-details-information marginTop-19">
              <div className="service-details-info">
                <h6 className="service-details-name">
                  {serviceDetails?.service_name}
                </h6>
                <p className="service-details-description">
                  {serviceDetails?.description}
                </p>
              </div>
              <div className="service-details-price-box">
                {serviceDetails?.offer_price ? (
                  <>
                    <span className="service-details-mrp">
                      {serviceDetails?.currency?.symbol}{" "}
                      {serviceDetails?.offer_price}
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {serviceDetails?.price ? (
                  <>
                    <span className="service-details-price">
                      {serviceDetails?.currency?.symbol} {serviceDetails?.price}
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {serviceDetails?.offer_discount_price ? (
                  <>
                    <span className="service-details-save-price">
                      Save {serviceDetails?.currency?.symbol}{" "}
                      {serviceDetails?.offer_discount_price}
                  </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="service-details-time">
                <img src={clock} alt="" width={15} height={15} />
                <span className="time">
                  {serviceDetails?.duration} {t("common.minutes")}.
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7">
              <div className="choose-provider">
                  <div className="choose-provider-item-list">
                    {SPlist && SPlist.length === 0 ? (
                      loading ? (
                        <>
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={150}
                            sx={{ margin: "12px", borderRadius: "12px" }}
                          />
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={150}
                            sx={{ margin: "12px", borderRadius: "12px" }}
                          />
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={150}
                            sx={{ margin: "12px", borderRadius: "12px" }}
                          />
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={150}
                            sx={{ margin: "12px", borderRadius: "12px" }}
                          />
                        </>
                      ) : (
                        <h3 className="name">{t("common.no_provider")}</h3>
                      )
                    ) : (
                      SPlist.map((item, index) => {
                        return (
                          <div
                            className={`choose-provider-item ${
                              item.users.id === bookingDetails.sp_id
                                ? `active-border`
                                : `white-border`
                            }`}
                            onClick={() => {
                              setProvider(item.users.id);
                              dispatch(
                                handleBookingDetails({ sp_id: item.users.id, is_advance_payment_on: item?.users?.is_advance_payment_on })
                              ); 
                            }}
                            key={item?.id}
                          >
                            <img
                              src={item?.users?.business_id_url}
                              alt=""
                              className="choose-provider-item-img"
                            />
                            <div className="choose-provider-item-info">
                              <h3 className="name">{item?.users?.name}</h3>
                              {/* <p className="info">{item?.users?.email}</p> */}
                              <p className="info">{item?.sp_address?.block_shop_no} {item?.sp_address?.location} {item?.sp_address?.city} {item?.sp_address?.state} {item?.sp_address?.country}</p>
                              <span className="distance">
                                {item?.users?.country_code} {item?.users?.mobile_no}
                              </span>
                              
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div className="choose-provider-footer">
                    <span className="choose-provider-price">
                      {t("common.total")} : {serviceDetails?.currency?.symbol}{" "}
                      {serviceDetails?.price}
                    </span>
                    <a
                      // className={`${bookingDetails?.sp_id ? "" : "btn-disabled"}`}
                      onClick={handleNextStep}
                    >
                      <button className={`next-button`}>{t("common.next")}</button>
                    </a>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}
