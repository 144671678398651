import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import car from "../../Assets/img/carIcon.png";
export default function BookingScheduleConfirmedPackage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const packageServiceBooking = state?.packageServiceBooking;
  const serviceDetails = state?.serviceDetails;
  const bookingDetails = state?.bookingDetails;
  console.log(packageServiceBooking, "packageServiceBooking");
  console.log(serviceDetails, "serviceDetails");
  console.log(bookingDetails, "bookingDetails");
  return (
    <>
      <section className="container conFirm-booking service-details">
        <div className="service-details-title">
          <i className="fa fa-angle-left" onClick={() => navigate(-1)}></i>
          <span className="service-details-title-text">Booking Confirmed</span>
        </div>

        <div className="row">
          <div className="col-12 col-lg-5 img-box">
            <img
              className="conFirm-booking-img"
              src={require("../../Assets/img/booking.png")}
              alt=""
            />
            <div className="tag-box  mt-5">
              <h3 className="tag-1 m-0">Thank You!!!</h3>
              <h3 className="tag-2 m-0">Booking Confirmed</h3>
            </div>
          </div>
          <div className="col-12 col-lg-7 ">
            <div className="booking-info-box">
              <div className="booking-info">
                <img
                  className="booking-service-img"
                  src={
                    packageServiceBooking?.package_details?.package_image_url
                  }
                  alt=""
                />
                <div className="booking-item">
                  <h3 className="booking-service-name">
                    {packageServiceBooking?.package_details?.package_name}
                  </h3>
                  <p className="booking-service-info">
                    {
                      packageServiceBooking?.package_details
                        ?.package_description
                    }
                  </p>
                  <div className="carbox mt-2">
                    <img src={car} width={20} height={20} alt={""} />
                    <span>{packageServiceBooking?.service_usage} Times</span>
                  </div>
                </div>
              </div>

              <div className="booking-info">
                <img
                  className="booking-service-img"
                  src={serviceDetails?.service_image_url}
                  alt=""
                />
                <div className="booking-item">
                  <h3 className="booking-service-name">
                    {serviceDetails?.service_name}
                  </h3>
                  <p className="booking-service-info">
                    {serviceDetails?.description}
                  </p>
                </div>
              </div>

              <div className="booking--info mt-0">
                {[
                  moment(packageServiceBooking.start_date).format(
                    "DD MMMM YYYY"
                  ),
                  moment(
                    packageServiceBooking?.start_time,
                    "ddd DD-MMM-YYYY, hh A"
                  ).format("hh:mm A"),
                  serviceDetails?.duration + " Min",
                  "At " + packageServiceBooking?.address_type,
                ].map((item, index) => (
                  <>
                    <div className="booking--info-item" key={index}>
                      <span className="booking--info-text">{item}</span>
                    </div>
                  </>
                ))}
              </div>
              <div className="booking-address mt-0">
                <h3 className="booking-address-title">
                  At {packageServiceBooking?.address_type}
                </h3>
                <p className="booking-address-info">
                  {packageServiceBooking?.location}
                </p>
              </div>
              <Link to={"/myProfile?page=packages"}>
                <button className="my-booking-button">Go to My Bookings</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
