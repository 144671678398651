import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import star from "../../Assets/img/star.png";
import car from "../../Assets/img/carIcon.png";
import packageImg from "../../Assets/img/package3.png";
import MyBookingUp from "../MyBookingUp/MyBookingUp";
import { useState } from "react";
import "./PramotionCar.scss";
import { getAllCoupons, getAllOffers } from "../../Slices/home";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

let colorArray = ["", "cuponBook_yello", "cuponBook_green"];
const OfferCar = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [is_coupons, set_is_coupons] = useState(1);
  const { isgetAllCouponsFetched, coupons, isgetAllOffersFetched, offers } =
    useSelector((state) => state.home);
  // console.log(isgetAllCouponsFetched, coupons, isgetAllOffersFetched, offers);
  useEffect(() => {
    dispatch(getAllCoupons({ country_id: "103" }));
    dispatch(getAllOffers({ country_id: "103" }));
  }, []);
  return (
    <div className="myProfileOut pt0 pramotion">
      <div className="profDetailIn">
        <div className="profdetHead  profdetHeadNoRev ">
          <h3>{t("common.promotions")}</h3>
        </div>

        <div className="formOut profdet ">
          <div className="profdetHeadText">{t("common.coupons_for_you")}</div>
          <form>
            <div className="formInText bookForm offerPack">
              {isgetAllCouponsFetched ? (
                coupons?.map((singleCoupon, idx) => (
                  <div className="inpuWidth sumWid">
                    <div className="sumWidIn greenBack">
                      <div className="summarLeftImgText">
                        <div className="summarLeftImg">
                          <img src={singleCoupon?.coupon_image_url} alt="img" />
                        </div>
                      </div>
                      <div className={`cuponBook ${colorArray[idx]}`}>
                        <div className="cuponBook_inner">
                          <h3>
                            {singleCoupon?.coupon_description}
                            <br />{" "}
                            <span>
                              {singleCoupon?.coupon_discount}% {t("common.off")}{" "}
                              *
                            </span>
                          </h3>
                          <a href="#0">{singleCoupon?.coupon_code}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={200}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={200}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={200}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={200}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={200}
                    sx={{ margin: "12px", borderRadius: "12px" }}
                  />
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default OfferCar;
