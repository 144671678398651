import axios from "axios";
import React, { useEffect, useState } from "react";
import authService from "../../Services/auth.service";

const ProfileSupport = () => {
  const [content, setContent] = useState("");
  const getProfilePage = () => {
    authService.getSingleCms("support").then((res) => {
      setContent(res.result.data[0]?.content);
    });
  };
  useEffect(() => {
    getProfilePage();
  }, []);
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
};

export default ProfileSupport;
