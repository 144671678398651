import React from "react";
import "./TopSerices.scss";
import { Link } from "react-router-dom";
import ProductBox from "../ProductBox/ProductBox";
import "react-slick/lib/slider";
import { List } from "react-content-loader";
import { useTranslation } from "react-i18next";

const TopSerices = ({
  dashboardSections,
  isDashboardSectionsFetched,
  sections,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {sections && (
        <section className="mb-5">
          {!isDashboardSectionsFetched ? (
            <div className="container">
              {" "}
              <List />{" "}
            </div>
          ) : dashboardSections.length === 0 ? (
            <strong>{/* {t('common.no_specialities_found')} */}</strong>
          ) : (
            dashboardSections?.map((section, key) => {
              return (
                <div
                  className="producrCateIn spaceSect container mb-2 p-0"
                  key={key}
                >
                  <div className="section-title-box">
                    <h2 className="sectionTitle centerText">
                      {" "}
                      {section.section_title}
                    </h2>
                    <span className="sectionSeeAll centerText">
                      <Link
                        className="see-all-text"
                        to={`/serviceListing/${section}`}
                      >
                        {t("common.see_all")}
                      </Link>
                    </span>
                  </div>
                  <div className="">
                    <div className="mySlider row">
                      {section.dashboard_services?.map((services, key) => (
                        <div className="box-item col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                          <ProductBox services={services} key={key} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </section>
      )}

      {!sections && (
        <section className="mb-5 mt-5 trending_service_mg">
          {!isDashboardSectionsFetched ? (
            <div className="container">
              <List />
            </div>
          ) : dashboardSections.dashboard_services.length === 0 ? (
            <strong>{/* {t('common.no_specialities_found')} */}</strong>
          ) : (
            <div className="producrCateIn spaceSect container">
              <div className="container">
                <div className="section-title-box">
                  <h2 className="sectionTitle centerText">
                    {dashboardSections.section_title}
                  </h2>
                  <span className="sectionSeeAll centerText">
                    <Link
                      className="see-all-text"
                      to={`/serviceListing/${dashboardSections.id}`}
                    >
                      {t("common.see_all")}
                    </Link>
                  </span>
                </div>
                <hr className="hr" />
              </div>
              <div className="">
                <div className="row">
                  {dashboardSections.dashboard_services?.map(
                    (services, key) => (
                      <div className="box-item col-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2 demo">
                        <ProductBox services={services} key={key} />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default TopSerices;
