import React from "react";
import "./ProductBox.scss";
import Button from "../Common/Button/Button";
import clock from "../../Assets/img/clock.png";
const ProductBox = ({ services, key }) => {
  return (
    <>
      <div className="product" key={key}>
        <div className="product-img-box">
          <img
            src={services?.service_image_url}
            className="product-img"
            alt="img1"
          />
        </div>

        <div className="title-box ">
          <h3 className="title">{services?.service_name}</h3>
          <div
            className="card-info"
            dangerouslySetInnerHTML={{ __html: services?.description }}
          />
        </div>

        <div className="headerFilterInfo">
          <div className="contInfo">
            <div className="price-box">
              <span className="price">
                {services?.currency?.symbol} {services?.price}
              </span>
              {services?.mrp ? (
                <>
                  <span className="mrp">₹ {services?.mrp}</span>
                </>
              ) : (
                <></>
              )}
              {services?.save_price ? (
                <>
                  <span className="save-price">₹ {services?.save_price}</span>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="book-box">
              <div className="time-box">
                <img src={clock} alt="" />
                <span className="time">{services?.duration} Minutes</span>
              </div>
              <div>
                <Button
                  serviceId={services?.service_id || services?.id}
                  data={services}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductBox;
