import React, { useState } from "react";
import "./RaiseComplain.scss";
// import '../MyBookings/MyBookings.scss'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { raiseComplain } from "../../Slices/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const RaiseComplain = () => {
  const { bId, spId } = useParams();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const [complain, setComplain] = useState("");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("first", user);

  const handleSubmit = (e) => {
    e.preventDefault()
    let body = new FormData();
    body.append("user_id", user?.id);
    body.append("sp_id", spId);
    body.append("booking_code", bId);
    body.append("complaint_text", complain);
    dispatch(raiseComplain(body))
      .then((response) => {
        console.log(response);
        if(!response?.error) {

            toast.success(response?.payload?.message)
            navigate(`/bokingDetails/${bId}`)
        }else {
            toast.error(response?.payload?.message)
        }
        // setIsLoading(false);
        // setDetails(response.payload.result.data);
      })
      .catch((err) => {
        // setIsLoading(false);
        toast.error(err.message)
      });
  };
  return (
    <>
      {/* hello
        booking id = {bId}
        sp id = {spId}
        {t("common.logout")} */}

      <div className="raise_complain">
        <div className="container">
          <div className="raise_complain_title">
            <h2>Complaints</h2>
          </div>
          <div className="raise_complain_wrap">
            <div className="raise_complain_block">
              <div className="raise_complain_info">
                <h4>Raise a Complaint</h4>
                <p>
                  Feel free to raise a complaint regarding experience on
                  greenshine. we’ll get back to you via email you provided here.
                </p>
              </div>
            </div>
            <div className="raise_complain_conte">
            <div className="formOut profdet">
            <form>
             
              <div className="formInText mt-3">
                <div className="inpuWidth fullwidth">
                  <label>Complaint Message</label>
                  <textarea
                    type="text"
                    name="complaint_text"    
                    placeholder="Type here…" 
                    value={complain}         
                    onChange={(e) => setComplain(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-3 mb-2">
                  <div className="line-h"></div>
                </div>

              <div className="col-12 p-0">
                  <button className="get-otp-button"  onClick={handleSubmit}>
                    <span className="button-text" >
                      Send
                    </span>
                  </button>
                  {/* <div className='skip-login'>
                    <Link to={"#"} className='skip-login-text'>Skip login & go to dashboard</Link>
                  </div> */}
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RaiseComplain;
