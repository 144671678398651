import React, { useEffect, useState } from "react";
import "./PaymentOption.scss";
import { useDispatch, useSelector } from "react-redux";
import clock from "../../Assets/img/clock.png";

import {
  createBooking,
  handlePaymentSummaryDetails,
  handleCouponApply,
} from "../../Slices/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  handleResetBookingDetails,
  setLoading,
  setLoadingFalse,
} from "../../Slices/home";
import { useTranslation } from "react-i18next";
const stripe = require("stripe")(
  "sk_test_51MmLQgLI1vtejtJzBW1HwSSewq8BWWn2KLsRooRfPSL8TlMoDPEolNu0IlUTsp1IdaXYroMIArATLwTHtbvv1zsy007HUlb9j1"
);

const payments = [
  {
    id: 1,
    name: "Stripe",
    url: require("../../Assets/img/stripe.png"),
    color: "stripe-color",
  },
];

const couponLabel = ["Apply", "Applying", "Applied"];

const PaymentOption = () => {
  const dispatch = useDispatch();
  const [paymentDetails, setpaymentDetails] = useState({});
  const [couponState, setCouponState] = useState(0);
  const [count, setCount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponDetails, SetcouponDetails] = useState({});
  const { serviceDetails } = useSelector((state) => state.home);
  const { bookingDetails } = useSelector((state) => state.home);
  const [payment, setPayment] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    PaymentSummaryDetails();
  }, [couponDetails]);

  const PaymentSummaryDetails = () => {
    const formData = new FormData();
    formData.append("country_id", 103);
    formData.append(
      "total_price",
      serviceDetails.offer_price != ""
        ? serviceDetails.offer_price
        : serviceDetails.price
    );
    formData.append("sp_id", bookingDetails?.sp_id)

    if (couponDetails && couponDetails.coupon_code) {
      console.log(couponDetails, "yh");
      const coupon_value = {
        total_price: serviceDetails.price,
        discount_amount: couponDetails.discount_amount || "",
        final_amount: couponDetails.final_amount,
        coupon_code: couponDetails.coupon_code,
        coupon_name: couponDetails.coupon_name,
        // "coupon_id": couponDetails.id
      };
      formData.append("coupon_array", JSON.stringify(coupon_value));
    }
    console.log(formData, "cpp");
    dispatch(handlePaymentSummaryDetails(formData))
      .then((response) => {
        console.log(response);
        setpaymentDetails({ ...response.payload.result.data });
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
        // setLoading(false);
      });
  };

  const couponApply = () => {
    const formData = new FormData();
    formData.append("country_id", 103);
    formData.append("service_id", serviceDetails.id);
    formData.append("coupon_code", coupon);
    setCouponState(1);
    dispatch(handleCouponApply(formData))
      .then((response) => {
        console.log(response, "details");
        SetcouponDetails({ ...response.payload.result.data });
        if (response.error) {
          toast.error(response.payload.message);
          setCouponState(0);
        } else {
          toast.success(t("common.coupon_applied"));
          setCouponState(2);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
        setCouponState(0);

        // setLoading(false);
      });
  };
  // console.log(bookingDetails,serviceDetails)

  const placeOrder = (token, paymentObject) => {
    let body = {
      ...serviceDetails,
      ...{ payment_summary: JSON.stringify(paymentDetails) },
      ...bookingDetails,
      total_price: paymentDetails?.total,
      service_id: serviceDetails?.id,
      token: token,
      payment_object: paymentObject,
      payment_type: 0,
    };

    if (payment) {
      dispatch(createBooking(body))
        .then((response) => {
          console.log(response, "booked");
          if (response.meta.rejectedWithValue) {
            toast.error(response.payload.message);
          } else {
            toast.success(response.payload.message);

            navigate("/booking-confirmed", {
              state: {
                bookingDetails,
                serviceDetails,
              },
            });
            dispatch(handleResetBookingDetails());
          }
        })
        .catch((err) => console.log("Booking Err", err));
    } else {
      toast.error(t("common.no_payment_selected"));
    }
  };

  const handleBookService = () => {
    if (payment == "Stripe") {
      var handler = window.StripeCheckout.configure({
        key: "pk_test_51MmLQgLI1vtejtJzcRScx636BqnL6YAFqBv8DKmJYrAKMnphr5MzZ732vTzOQORg2Tg3uuiMlgQME4LoSKQf1iSP000rsavaEU", // pk_test_glxk17KhP7poKIawsaSgKtsL
        locale: "auto",
        token: async (token) => {
          console.log("stripe token", token);
          const token2 = token.id;

          const charge2 = await stripe.charges.create({
            amount: paymentDetails?.first_installment * 100,
            currency: serviceDetails?.currency?.code,
            source: token.id,
            description: "",
          });
          // const chargeList = await stripe.charges.list({
          //   limit: 1,
          //   source: token2,
          // });
          // const charge2 = chargeList.data[0];
          console.log("stripe res", charge2);

          console.log("stripe status", payment);
          placeOrder(token.id, charge2);
        },
      });
      handler.open({
        name: "",
        description: "car wash",
        amount: paymentDetails?.first_installment * 100,
        currency: serviceDetails?.currency?.code,

        close: function () {
          // window.location.reload();
          console.log("hello");
        },
      });
    } else {
      placeOrder();
    }
  };

  const is_cuponeadded = () => {
    return (
      <>
        {paymentDetails.is_coupon_applied == true ? (
          <li key="dsadsad">
            <span key="dsadsad">{t("common.amount_after_service")}</span>
            <b key="item_total2">- ₹ {paymentDetails.total ?? ""}</b>
          </li>
        ) : (
          ""
        )}
      </>
    );
  };
  const navigate = useNavigate();

  // if (!bookingDetails?.location_type) {
  //   return navigate("/");
  // }

  return (
    <>
      <section className="container service-details">
        <div className="service-details-title">
          <i
            className="fa fa-angle-left"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <span className="service-details-title-text">
            {t("common.location")}
          </span>
        </div>

        <div className="service-details-body">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="">
                <img
                  className="service-details-img"
                  src={serviceDetails?.service_image_url}
                  alt=""
                />
              </div>
              <div className="service-details-information marginTop-19">
                <div className="service-details-info">
                  <h6 className="service-details-name">
                    {serviceDetails?.service_name}
                  </h6>
                  <p className="service-details-description">
                    {serviceDetails?.description}
                  </p>
                </div>
                <div className="service-details-price-box">
                  {serviceDetails?.offer_price ? (
                    <>
                      <span className="service-details-mrp">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.price ? (
                    <>
                      <span className="service-details-price">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.offer_discount_price ? (
                    <>
                      <span className="service-details-save-price">
                        {t("common.save")} {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_discount_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="service-details-time">
                  <img src={clock} alt="" width={15} height={15} />
                  <span className="time">
                    {serviceDetails?.duration} {t("common.minutes")}.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-7 ">
              <div className="choose-provider address-options">
                <div className="schedule-title-box m-0">
                  <div>
                    <span className="schedule-box p-0">
                      {t("common.payment")}
                    </span>
                  </div>
                </div>
                <div className="choose-body">
                  <div className="choose-body-title">
                    <span className="choose-car-here p-0">
                      {t("common.payment_options")}
                    </span>
                  </div>
                </div>

                <div className="payment-box row m-0">
                  {payments.map((item, index) => {
                    return (
                      <>
                        <div
                          className={`payment-list ${item.color}`}
                          onClick={() => {
                            setPayment(item?.name);
                          }}
                        >
                          <div className={`payment-item`}>
                            <img
                              className="payment-icon"
                              src={item?.url}
                              alt=""
                            />
                            <h3 className="payment-name">{item?.name}</h3>
                          </div>
                          <div className="radio-body-pink">
                            {payment === item?.name ? (
                              <>
                                {" "}
                                <div className="radio-dot-pink"></div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="row coupon-box m-3 p-2">
                  <div className="col-12 col-sm-4  p-0">
                    <div className="title-box mt-0">
                      <img
                        src={require("../../Assets/img/voucher.png")}
                        alt=""
                        className="img-voucher"
                      />
                      <h3 className="title-box-text">
                        {t("common.apply_coupon")} :
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6  pe-0 pe-sm-1 pl-0 mt-4 mt-sm-0">
                    <input
                      type="text"
                      placeholder="Enter coupon code"
                      className="coupon-input w-100"
                      defaultValue={coupon}
                      onChange={(e, value, d) => {
                        setCoupon(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-2  p-0  mt-4 mt-sm-0">
                    <button className="apply-coupon-btn" onClick={couponApply}>
                      {" "}
                      {couponLabel[couponState]}
                    </button>
                  </div>
                </div>

                <div className="payment-info-box">
                  <h3 className="payment-main-title">
                    {t("common.payment_summary")}
                  </h3>
                  <div className="payment-item">
                    <span className="payment-title">
                      {t("common.item_total")}
                    </span>
                    <span className="payment-price">
                      ₹ {paymentDetails ? paymentDetails.item_total : ""}
                    </span>
                  </div>
                  <div className="payment-item">
                    <span className="payment-title">{t("common.tax")}</span>
                    <span className="payment-price">
                      ₹ {paymentDetails.tax_amount || 0}
                    </span>
                  </div>
                  <div className="payment-item">
                    <span className="payment-title-green">
                      {t("common.coupon_discount")} ({" "}
                      {paymentDetails.is_coupon_applied &&
                        paymentDetails?.coupon_data?.coupon_code}
                      )
                    </span>
                    <span className="payment-title-green">
                      - ₹ {paymentDetails.coupon_discount || 0}
                    </span>
                  </div>
                  <hr />
                  <div className="payment-item">
                    <span className="payment-title-total">
                      {t("common.total")}
                    </span>
                    <span className="payment-price">
                      ₹ {paymentDetails.total}
                    </span>
                  </div>
                  <div className="payment-item">
                    <span className="payment-title">
                      {t("common.amount_after_service")}
                    </span>
                    <span className="payment-price">
                      {" "}
                      - ₹ {paymentDetails.second_installment || ""}{" "}
                    </span>
                  </div>
                  <hr />
                  <div className="payment-item">
                    <span className="payment-title-total">
                      {t("common.amount_to_be_pay_now")}
                    </span>
                    <span className="payment-price">
                      ₹ {paymentDetails.first_installment || 0}
                    </span>
                  </div>
                </div>
                <div className="choose-provider-footer">
                  <span className="choose-provider-price">
                    {t("common.total")} :{" "}
                    {serviceDetails.currency.symbol +
                      " " +
                      paymentDetails.total}
                  </span>

                  <button className="next-button" onClick={handleBookService}>
                    {t("common.continue")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="ServDetailPopOut">
      <div className="contianer-fluid ServDetailPop servPay">
        <div className="serveBack">
          <h6
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(showAddressOpt(true))}
          >
            <span className="backbt">
              <i className="fa fa-angle-left"></i>
            </span>
            Payments
          </h6>
        </div>

        <div
          className="closeIcon"
          style={{ cursor: "pointer" }}
          onClick={() => handleClose()}
        >
          <i className="fa fa-times"></i>
        </div>
        <div className="headerFilterInfo">
          <strong className="serviceName">{serviceDetails?.service_name}</strong>
          <div className="contInfo">
            <div className="contInfoIn">
              <ul className="startTask">
                <li>
                  <img src={clock} alt="time" />
                  <span className="time flex-grow-1">
                    {serviceDetails?.duration + "min"}
                  </span>
                </li>
              </ul>

              <ul className="price">
                <li>
                  {serviceDetails?.currency.symbol}
                  {serviceDetails?.offer_price != ""
                    ? serviceDetails?.offer_price
                    : serviceDetails?.price}
                </li>
                {serviceDetails?.offer_price != "" ? (
                  <>
                    <li>
                      <span className="textCut">
                        
                        {serviceDetails?.offer_price != ""
                          ? serviceDetails?.price
                          : serviceDetails?.offer_price}
                      </span>
                    </li>
                    <li>
                      <span className="textSave">
                        Save {serviceDetails?.offer_discount_price}
                      </span>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>
            {/* <PricIncDec /> */}
      {/* </div>
        </div>
        <div className="contetTextArea">
          <div className="listMenu">
            <ul className="listTex paym">
              <li>
                <a href="" className="active">
                  Payment
                </a>
              </li>
            </ul>
          </div>

          <div className="row listBox">
            <div className="leftBrandLocation">
              <h4>Payment Options</h4>
              <ul className="listRadio locationradio payMentOpt">
                <li>
                  <input type="radio" name="payment" />
                  <label className="strip">
                    <img src={stripe} />
                    <span>Stripe</span>
                  </label>
                </li>
                <li>
                  <input type="radio" name="payment" />
                  <label className="paytm">
                    <img src={paytm} />
                    <span>Paytm</span>
                  </label>
                </li>
                <li>
                  <input type="radio" name="payment" />
                  <label className="payu">
                    <img src={payU} />
                    <span>PayU Money</span>
                  </label>
                </li>
                <li>
                  <input type="radio" name="payment" />
                  <label className="cod">
                    <img src={cashon} />
                    <span>C.O.D</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="row listBox">
            <ul className="voucherOut">
              <li className="img">
                <img src={voucher} />
              </li>
              <li className="text">Type Promo Code :</li>
              <li className="input">
                <input
                  type="text"
                  name="last_name"
                  defaultValue={coupon}
                  onChange={(e, value, d) => {
                    setCoupon(e.target.value);
                  }}
                />
              </li>

              <li className="btnClass">
                <button
                  onClick={(e) => {
                    couponApply();
                  }}
                >
                  Apply
                </button>
              </li>
            </ul>
          </div>

          <div className="summarRight">
            <h3>Payment Summary</h3>
            <ul>
              <li>
                <span>Item total</span>
                <b key="item_total">
                  ₹ {paymentDetails ? paymentDetails?.item_total : ""}
                </b>
              </li>
              {paymentDetails?.is_tax_applied == true ? (
                <li>
                  <span>Tax</span>
                  <b key="item_total1">₹ {paymentDetails?.tax_amount || ""}</b>
                </li>
              ) : (
                ""
              )}

              {paymentDetails?.is_coupon_applied == true ? (
                <li key={paymentDetails.total}>
                  <span key={paymentDetails.total}>
                    {"Coupon Discount ("+ paymentDetails?.coupon_data.coupon_code+")"} 
                  </span>
                  <b key={paymentDetails?.total}>
                    - ₹ {paymentDetails?.total ?? ""}
                  </b>
                </li>
              ) : (
                ""
              )}

              <li className="totalPrice">
                <span>Total</span>
                <b>₹ {paymentDetails?.total}</b>
              </li>
              <li>
                <span>{"80% amount after service"}</span>
                <b>- ₹ {paymentDetails?.second_installment ?? ""} </b>
              </li>

              <li className="totalPrice">
                <span>Amount to be Pay Now</span>
                <b>₹ {paymentDetails?.first_installment ?? ""} </b>
              </li>
            </ul>
          </div>
          <div className="detOpFoot">
            <div className="col priceNum">
              <div className="totalPrice">
                Total :
                <span className="price" key="item_total5">
                  {serviceDetails?.currency?.symbol + " " + paymentDetails?.total}
                </span>
              </div>
            </div>
            <div className="col rightDet">
              <button
                style={{ cursor: "pointer" }}
                // onClick={() => dispatch(showConfirmBooking(true))}
                onClick={() => handleBookService()}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    </>
  );
};

export default PaymentOption;
