const authHeader = () => {
  const auth = sessionStorage.getItem("token");
  // const auth = localStorage.getItem("token");
  if (auth) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth}`,
    };
  } else {
    return {};
  }
};

export default authHeader;
