import React from "react";
import Banners from "./Banners/Banners";
import Specialites from "./Specialites/Specialites";
import TopSerices from "./TopSerices/TopSerices";
import TopPackages from "./TopPackages/TopPackages";
import DowloadApp from "./DowloadApp/DowloadApp";
import MidSlider from "./MidSlider/MidSlider";
import OffersSlider from "./OffersSlider/OffersSlider";
import ServiceDetail from "./ServiceDetail/ServiceDetail";
import { useSelector, useDispatch } from "react-redux";
import ServiceDetailSchedule from "./ServiceDetailSchedule/ServiceDetailSchedule";
import ServiceDetailAddress from "./ServiceDetailAddress/ServiceDetailAddress";
import ServiceDetailChooseCar from "./ServiceDetailChooseCar/ServiceDetailChooseCar";
import PaymentOption from "./PaymentOption/PaymentOption";
import ConFirmBooking from "./ConFirmBooking/ConFirmBooking";
import { useEffect } from "react";
import { getAllOffers, getDashboard } from "../Slices/home";
import { getUserProfile, listFavorite } from "../Slices/auth";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import Landingpage from "./Landing/Landingpage";
import { isArray } from "jquery";

const Home = () => {
  const {
    dashboardSections,
    isDashboardSectionsFetched,
    offers,
    landigDetails,
  } = useSelector((state) => state.home);

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const carType = (carType) => {
    if (carType === "suv") {
      return 1;
    } else if (carType === "Hatchback") {
      return 2;
    } else if (carType === "Sedan") {
      return 3;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    if (!isDashboardSectionsFetched) {
      if (isLoggedIn) {
        dispatch(
          getDashboard({
            country_id: "103",
            latitude: landigDetails?.location?.latitude,
            car_type: carType(landigDetails.car_type),
            longitude: landigDetails?.location?.longitude,
            car_id:
              user?.user_car_details_default?.id || landigDetails.car_brand.id,
          })
        );
      } else {
        dispatch(
          getDashboard({
            country_id: "103",
            car_type: carType(landigDetails.car_type),
            country: landigDetails?.country,
            latitude: landigDetails?.location?.latitude,
            longitude: landigDetails?.location?.longitude,
            car_id:
              user?.user_car_details_default?.id || landigDetails.car_brand.id,
          })
        );
      }

      dispatch(
        getAllOffers({
          country_id: user?.country_id || "103",
          country: landigDetails?.country,
        })
      );
    }

    navigator.geolocation.getCurrentPosition(function (position) {});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserProfile());
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  return (
    <div>
      <Banners offers={offers} />

      <Specialites
        categories={dashboardSections.speciality}
        isDashboardSectionsFetched={isDashboardSectionsFetched}
      />
      <TopSerices
        dashboardSections={dashboardSections.trending_services}
        isDashboardSectionsFetched={isDashboardSectionsFetched}
        sections={false}
      />
      <MidSlider />
      <TopPackages
        dashboardSections={dashboardSections.packages}
        isDashboardSectionsFetched={isDashboardSectionsFetched}
        sections={true}
      />

      {dashboardSections?.section?.map((item, index) => (
        <>
          <TopSerices
            dashboardSections={item}
            isDashboardSectionsFetched={isDashboardSectionsFetched}
            sections={false}
          />
        </>
      ))}
      <DowloadApp />

      {/* {showServiceDetails && <ServiceDetail/>}
            {showScheduleOpt && <ServiceDetailSchedule/>}
            {ServiceChooseCar && <ServiceDetailChooseCar/>}
            {showAddressOpt && <ServiceDetailAddress/>}
            {showPaymentOpt && <PaymentOption/>}
            {showConfirmBooking && <ConFirmBooking/>} */}
    </div>
  );
};

export default Home;
