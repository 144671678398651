import React, { useEffect, useState } from "react";
import "./PackagePurchasedDetail.scss";
import ServDetailSlider from "../PackageDetailSlider/PackageDetailSlider";
import car from "../../Assets/img/clock.png";
import { useDispatch, useSelector } from "react-redux";
import {
  showScheduleOpt,
  showServiceDetails,
  showAddressOpt,
  handleBookingDetails,
  getServiceDetails,
  setLoadingFalse,
  handlepackageServiceBooking,
} from "../../Slices/home";
import { toast } from "react-toastify";
import service from "../../Assets/img/service.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { packagePurchasedDetail } from "../../Slices/auth";
import { Skeleton } from "@mui/material";
import moment from "moment";
const PackagePurchasedDetail = () => {
  let { id } = useParams();
  const { user, user_car_id_default } = useSelector((state) => state.auth);
  const [packageDetail, setPackageDetail] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(packagePurchasedDetail(id))
      .then((response) => {
        // toast.success(response.payload.message);
        setPackageDetail(response.payload.result.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePackageServiceBooking = (packageId, serviceId) => {
    dispatch(
      getServiceDetails({
        service_id: serviceId,
        country_id: user?.country_id,
        car_id: user_car_id_default,
      })
    )
      .then((response) => {
        if (response?.payload?.status === 200) {
          dispatch(
            handlepackageServiceBooking({
              purchase_id: packageDetail?.id,
              ...packageDetail,
            })
          );
          navigate("/package-start-date");
        } else {
          toast.error(response?.payload?.message);
        }
        dispatch(setLoadingFalse({ status: 1 }));
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <section className="package_details">
      <div className="container">
        <div className="package_wrap">
          <div className="package_title">
            <h3>
              {" "}
              <span className="">
                <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
              </span>{" "}
              My Bookings
            </h3>
          </div>

          {packageDetail ? (
            <>
              <div className="package_sec">
                <div className="package_left">
                  <div className="summarLeftImgText">
                    <div className="summarLeftImg">
                      <img
                        src={packageDetail?.package_details?.package_image_url}
                        alt="package-image"
                      />
                    </div>
                    <div className="contInfoIn">
                      <h3>{packageDetail?.package_details?.package_name}</h3>
                      <p>
                        {packageDetail?.package_details?.package_description}
                      </p>
                    </div>
                  </div>
                  <div className="package_time">
                    <div>
                      <div className="package_timein">
                        <span>Service Usage</span>
                        <h3>{packageDetail?.service_usage} Times</h3>
                      </div>
                    </div>
                    <div>
                      <div className="package_timein">
                        <span>Remaining </span>
                        <h3>{packageDetail?.service_usage_remaining} Times</h3>
                      </div>
                    </div>
                  </div>
                  <div className="packge_body_wash">
                    <div className="packge_body_img">
                      <div className="packge_img1">
                        <img
                          src={
                            packageDetail?.service_details?.service_image_url
                          }
                        />
                      </div>
                      <div className="packge_conte1">
                        <h3>{packageDetail?.service_details?.service_name}</h3>
                        <p>{packageDetail?.service_details?.description}</p>
                        <a href="#0">Read less</a>
                      </div>
                    </div>
                  </div>
                  <div className="packge_price packge_box">
                    <div>
                      <h6 className="packge_box_title">Package Id</h6>
                      <span className="packge_box_info">
                        {packageDetail?.code}
                      </span>
                    </div>
                    <div>
                      <h6 className="packge_box_title">Package Frequency</h6>
                      <span className="packge_box_info">
                        {packageDetail?.reminder_name}
                      </span>
                    </div>
                    <div>
                      <h6 className="packge_box_title">CAR</h6>
                      <span className="packge_box_info">
                        {packageDetail?.car_details?.car_name}-
                      </span>
                      <span className="packge_box_info">
                        {packageDetail?.car_details?.car_fuel_type}
                      </span>
                      <span className="packge_box_info"></span>
                    </div>
                    <div>
                      <h6 className="packge_box_title">PRICE</h6>
                      <span className="packge_box_info">
                        {packageDetail?.payment_summary_data?.currency?.symbol}{" "}
                        {packageDetail?.total_price}
                      </span>
                    </div>
                  </div>
                  {/* <div className="packge_office">
                <div className="packge_office_block">
                  <h2>Office (Padding)</h2>
                  <p>
                    B-214, Empire Business Hub, Opp. Shakti Farm, Science City,
                    Ahmedabad - 380060 |(Padding)
                  </p>
                </div>
              </div> */}
                </div>
                <div className="package_right">
                  <div className="package_summary">
                    <h3>Payment Summary</h3>
                    <ul>
                      <li>
                        <div>
                          <p>Item total</p>
                          <span>
                            {
                              packageDetail?.payment_summary_data?.currency
                                ?.symbol
                            }{" "}
                            {packageDetail?.payment_summary_data?.item_total}{" "}
                          </span>
                        </div>
                        <div>
                          <p>
                            Tax
                            {packageDetail?.payment_summary_data?.tax_data.map(
                              (item, index) => (
                                <>
                                  <span key={index}>
                                    {" "}
                                    ({item.name} {item.value}%)
                                  </span>
                                </>
                              )
                            )}
                          </p>
                          <span>
                            {
                              packageDetail?.payment_summary_data?.currency
                                ?.symbol
                            }{" "}
                            {packageDetail?.payment_summary_data?.tax_amount}{" "}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <h3>Total</h3>
                          <span>
                            {
                              packageDetail?.payment_summary_data?.currency
                                ?.symbol
                            }{" "}
                            {packageDetail?.payment_summary_data?.total}
                          </span>
                        </div>
                      </li>
                    </ul>
                    <div className="package_history">
                      {packageDetail?.booking_details ? (
                        <>
                          <h3>History</h3>
                          <ul>
                            <li>
                              <div>
                                <p>Date & Time</p>
                                <p>Status</p>
                              </div>
                            </li>
                            {packageDetail?.booking_details.map(
                              (item, index) => (
                                <>
                                  <li>
                                    <div>
                                      <h6>
                                        {moment(item?.created_at).format("LLL")}
                                      </h6>
                                      <h4>{item?.status_name}</h4>
                                    </div>
                                  </li>
                                </>
                              )
                            )}
                          </ul>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    {packageDetail?.service_usage_remaining >=
                    packageDetail?.service_usage ? (
                      <>
                        <div className="package_btn">
                          <span className="finished-btn">
                            Your Packe Services Finished
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="package_btn">
                          <button
                            onClick={() => {
                              // package-start-date
                              handlePackageServiceBooking(
                                packageDetail?.code,
                                packageDetail?.service_id
                              );
                            }}
                            className="book-now-button"
                          >
                            Book Now
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className="package_sec">
                  <div className="package_left">
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={500}
                      sx={{ margin: "12px", borderRadius: "12px" }}
                    />
                  </div>
                  <div className="package_right">
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={500}
                      sx={{ margin: "12px", borderRadius: "12px" }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default PackagePurchasedDetail;
