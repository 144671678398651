import React, { useEffect, useState } from "react";
import "./Footer.scss";
import fb from "../../../Assets/img/icons8-facebook.png";
import insta from "../../../Assets/img/icons8-instagram.png";
import tweet from "../../../Assets/img/icons8-twitter.png";
import youtube from "../../../Assets/img/icons8-youtube.png";
import logo from "../../../Assets/img/logo.png";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { getCMSPageList } from "../../../Slices/home";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const dispatch = useDispatch();
  const [cmsPages, setCMSPages] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    $(document).ready(function () {
      $("h3").click(function (e) {
        e.preventDefault();

        let $this = $(this);

        if ($this.next().hasClass("show")) {
          $this.next().removeClass("show");
          $this.next().slideUp(350);
        } else {
          $this.parent().parent().find(".list").removeClass("show");
          $this.parent().parent().find(".list").slideUp(350);
          $this.next().toggleClass("show");
          $this.next().slideToggle(350);
        }
      });
    });
  }, []);

  useEffect(() => {
    dispatch(getCMSPageList())
      .then((response) => {
        setCMSPages(response.payload.data.result.data);
        //  console.log(response.payload.data.result.data,"response.payload.data.result.data")
      })
      .catch((err) => {
        setCMSPages();
      });
    // eslint-disable-next-line
  }, []);

  return (
    <footer>
      <div className="container footer">
        <div className="row">
          <div className="col">
            <a href="" className="footLog">
              <img src={logo} alt="logo" />
            </a>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book
            </p>
          </div>
          <div className="col-md-2">
            <h3 id="fmenu1">
              {t("common.know_more")}{" "}
              <span className="rightDown">
                <i className="fa fa-angle-down"></i>
              </span>
            </h3>
            <ul className="list fmenu1">
              {cmsPages?.map((list, key) => {
                return (
                  <li key={key}>
                    <Link to={`cms/${list.slug}`}>{list.title}</Link>
                  </li>
                );
              })}
              {/* <li>
                <Link to={'/cms/about-us'}>About Us </Link>
              </li>
              <li>
                <Link to={'/ContactUs'}>Contact Us </Link>
              </li>
              <li>
                <Link to={'/ContactUs'}>Help & Support </Link>
              </li> */}
              <li
                onClick={() => {
                  window.open("https://play.google.com/store/apps", "_blank");
                }}
              >
                <span className="link-text">Android app</span>
              </li>
              <li
                onClick={() => {
                  window.open("https://www.apple.com/in/app-store/", "_blank");
                }}
              >
                <span className="link-text">iOS app </span>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <h3 id="fmenu3">
              {t("common.happy_to_serve_you")}{" "}
              <span className="rightDown">
                <i className="fa fa-angle-down"></i>
              </span>
            </h3>
            <ul className="list fmenu3">
              <li>
                <Link to={"/myProfile?page=profile"}>
                  {t("common.your_account")}
                </Link>
              </li>
              <li>
                <Link to={"/myProfile?page=booking"}>
                  {t("common.your_bookings")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <ul className="socialIcon">
            <li>
              <img
                src={fb}
                alt="fb"
                className="cursor-pointer"
                onClick={() => {
                  window.open("https://www.facebook.com/", "_blank");
                }}
              />
            </li>
            <li>
              <img
                src={tweet}
                alt="twetter"
                className="cursor-pointer"
                onClick={() => {
                  window.open("https://twitter.com/", "_blank");
                }}
              />
            </li>
            <li>
              <img
                src={youtube}
                alt="youtube"
                className="cursor-pointer"
                onClick={() => {
                  window.open("https://www.youtube.com/", "_blank");
                }}
              />
            </li>
            <li>
              <img
                src={insta}
                alt="insta"
                className="cursor-pointer"
                onClick={() => {
                  window.open("https://www.instagram.com", "_blank");
                }}
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="container copyRight">
        <p>@2023 , ALL COPYRIGHTS RESERVED BY GREENSHINE</p>
      </div>
    </footer>
  );
};

export default Footer;
