import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./AddAddress.scss";
import { Link, useNavigate } from "react-router-dom";
// import { setShowAddAddress, addAddress } from "../../slices/user";
import { toast } from "react-toastify";
import { addUserAddress, getUserProfile } from "../../Slices/auth";
import { editUserAddress } from "./../../Slices/auth";
import Geosuggest from "react-geosuggest";
import { getAddressList } from "../../Slices/auth";
import { List } from "react-content-loader";
import { useTranslation } from "react-i18next";

const AddAddress = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const addressId = queryParameters.get("id");
  const [address, setAddress] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [blockNo, setBlockNo] = useState();
  const { t, i18n } = useTranslation();

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (addressId) {
      getUserAddress();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setBlockNo(address.block_shop_no);
  }, [address]);

  const getUserAddress = () => {
    dispatch(getAddressList())
      .then((response) => {
        if (response.payload.status === 200) {
          // toast.success(response.payload.data.message)
          setAddress(
            response.payload.data.result.data.find(
              (item) => item.id == addressId
            )
          );
          setLoading(false);
        } else {
          toast.error(response.payload.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const handleAddAddress = () => {
    if (addressId) {
      //  Edit
      dispatch(editUserAddress(address))
        .then((response) => {
          console.info("edit add", response);
          if (response.payload.status === 200) {
            response.payload.data.message
              .split(",")
              .map((err) => toast.success(err));
            navigate("/myProfile?page=address");
          } else {
            response.payload.message.split(",").map((err) => toast.error(err));
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      //  save
      dispatch(addUserAddress(address))
        .then((response) => {
          if (response.payload.status === 200) {
            response.payload.data.message
              .split(",")
              .map((err) => toast.success(err));
            dispatch(getUserProfile());
            navigate("/myProfile?page=address");
          } else {
            response.payload.message.split(",").map((err) => toast.error(err));
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const getLanLngForAddress = (e) => {
    if (e !== undefined) {
      let results = e.gmaps.hasOwnProperty("address_components");
      if (results) {
        var city = "";
        var state = "";
        var country = "";
        var postal_code = "";
        var block_no = "";

        let results = e.gmaps.address_components;
        console.info("Geo Results", results);

        for (var i = 0; i < results.length; i++) {
          if (results[i].types[0] === "country") {
            country = results[i].long_name;
          }
          if (results[i].types[0] === "administrative_area_level_1") {
            state = results[i].long_name;
          }
          if (results[i].types[0] === "locality") {
            city = results[i].long_name;
          }
          if (results[i].types[0] === "postal_code") {
            postal_code = results[i].long_name;
          }
        }
        setAddress({
          ...address,
          latitude: e.location.lat.toString(),
          longitude: e.location.lng.toString(),
          location: e.label,
          city: city,
          state: state,
          country: country,
          pin_code: postal_code,
        });
      }
    } else {
      setAddress({
        ...address,
        city: "",
        state: "",
        country: "",
        pin_code: "",
      });
    }
  };

  return (
    <>
      <section className="container conFirm-booking service-details mb-3">
        <div className="service-details-title">
          <i className="fa fa-angle-left" onClick={() => navigate(-1)}></i>
          <span className="service-details-title-text">
            {addressId ? "Edit Address" : "Add New Address"}
          </span>
        </div>
      </section>
      {loading ? (
        <>
          <div className="container addForm mb-5">
            <div className="row min-600">
              <div className="col-12">
                <List />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container addForm mb-5">
            <div className="row min-600">
              <div className="col-12 col-xl-6">
                <div className="formOut">
                  {address && (
                    <form className="p-2 mb-5">
                      <div className="formInText">
                        <div className="inpuWidth locationIco">
                          <label className="required">
                            {t("common.location")}{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <Geosuggest
                            placeholder={"Enter address"}
                            initialValue={
                              address?.location && address?.location
                            }
                            onSuggestSelect={(e) => getLanLngForAddress(e)}
                            onChange={(e) => {
                              setAddress({ ...address, location: e });
                            }}
                            required
                          />
                        </div>
                        <div className="inpuWidth">
                          <label className="input--label">
                            {"Block No/Shop No."}{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            placeholder="Home, Office etc"
                            name="block_shop_no"
                            // value={address?.block_shop_no}
                            value={blockNo}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="formInText">
                        <div className="inpuWidth">
                          <label className="input--label">
                            {t("common.province_optional")}
                          </label>
                          <input
                            type="text"
                            placeholder="Opposite Shakti Farm"
                            name="province"
                            value={address?.province}
                            className="addIcon"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="inpuWidth ">
                          <label className="input--label">
                            {t("common.city")}{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            placeholder="Surat"
                            type={"text"}
                            value={address?.city}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="formInText">
                        <div className="inpuWidth ">
                          <label className="input--label">
                            {t("common.state")}{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type={"text"}
                            value={address?.state}
                            onChange={handleInputChange}
                            placeholder="Gujarat"
                          />
                        </div>
                        <div className="inpuWidth">
                          <label className="input--label">
                            {t("common.country")}
                            <strong className="text-danger">*</strong>
                          </label>

                          <input
                            type={"text"}
                            value={address?.country}
                            onChange={handleInputChange}
                            placeholder="INDIA"
                          />

                          {/* <select>
                            <option> {t("common.usa")}</option>
                            <option> {t("common.india")}</option>
                            <option> {t("common.uk")}</option>
                          </select> */}
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div
                          className={
                            address?.address_type === "home"
                              ? "addressCapsule testClass"
                              : "addressCapsule"
                          }
                          onClick={() =>
                            setAddress({ ...address, address_type: "home" })
                          }
                        >
                          <strong>{t("common.home")}</strong>
                        </div>
                        <div
                          className={
                            address?.address_type === "office"
                              ? "addressCapsule testClass"
                              : "addressCapsule"
                          }
                          onClick={() =>
                            setAddress({ ...address, address_type: "office" })
                          }
                        >
                          <strong>{t("common.office")}</strong>
                        </div>
                      </div>

                      <div className="buttonsec">
                        <input
                          type="button"
                          value={addressId ? "Edit Address" : "Add Address"}
                          onClick={() => handleAddAddress()}
                        />
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddAddress;

// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import "./AddAddress.scss";
// import { Link, useNavigate } from "react-router-dom";
// // import { setShowAddAddress, addAddress } from "../../slices/user";
// import { toast } from "react-toastify";
// import { addUserAddress, getUserProfile } from "../../Slices/auth";
// import { editUserAddress } from "./../../Slices/auth";
// import Geosuggest from "react-geosuggest";
// import { getAddressList } from "../../Slices/auth";
// import { List } from "react-content-loader";

// const AddAddress = () => {
//   const queryParameters = new URLSearchParams(window.location.search);
//   const addressId = queryParameters.get("id");
//   const [address, setAddress] = useState();
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(true);
//   const handleInputChange = (e) => {
//     const { value, name } = e.target;
//     setAddress({ ...address, [name]: value });
//   };
//   const navigate = useNavigate();

//   useEffect(() => {
//     if(addressId){
//       getUserAddress();
//     }else{
//       setLoading(false)
//     }
//   }, []);

//   const getUserAddress = () => {
//     dispatch(getAddressList())
//       .then((response) => {
//         if (response.payload.status === 200) {
//           // toast.success(response.payload.data.message)
//           setAddress(
//             response.payload.data.result.data.find(
//               (item) => item.id == addressId
//             )
//           );
//           setLoading(false);
//         } else {
//           toast.error(response.payload.data.message);
//           setLoading(false);
//         }
//       })
//       .catch((err) => {
//         toast.error(err);
//         setLoading(false);
//       });
//   };

//   const handleAddAddress = () => {
//     if (addressId) {
//        //  Edit
//        dispatch(editUserAddress(address))
//          .then((response) => {

//            if (response.payload.status === 200) {
//              response.payload.data.message
//                .split(",")
//                .map((err) => toast.success(err));
//                navigate("/myProfile?page=address");
//            } else {
//              response.payload.message.split(",").map((err) => toast.error(err));
//            }
//          })
//          .catch((err) => {
//            toast.error(err);
//          });

//     } else {
//       //  save
//       dispatch(addUserAddress(address))
//         .then((response) => {
//           if (response.payload.status === 200) {
//             response.payload.data.message.split(",").map((err) => toast.success(err));
//             dispatch(getUserProfile());
//             navigate("/myProfile?page=address");
//           } else {
//             response.payload.message.split(",").map((err) => toast.error(err));
//           }
//         })
//         .catch((err) => {
//           toast.error(err);
//         });
//     }
//   };

//   const getLanLngForAddress = (e) => {
//     if (e !== undefined) {
//       let results = e.gmaps.hasOwnProperty("address_components");
//       if (results) {
//         var city = "";
//         var state = "";
//         var country = "";
//         var postal_code = "";
//         var block_no = "";

//         let results = e.gmaps.address_components;

//         for (var i = 0; i < results.length; i++) {
//           if (results[i].types[0] === "country") {
//             country = results[i].long_name;
//           }
//           if (results[i].types[0] === "administrative_area_level_1") {
//             state = results[i].long_name;
//           }
//           if (results[i].types[0] === "locality") {
//             city = results[i].long_name;
//           }
//           if (results[i].types[0] === "postal_code") {
//             postal_code = results[i].long_name;
//           }
//           if (results[i].types[0] === "sublocality_level_1") {
//             block_no = results[i].long_name;
//           }
//         }
//         setAddress({
//           ...address,
//           latitude: e.location.lat.toString(),
//           longitude: e.location.lng.toString(),
//           block_shop_no: block_no,
//           location: e.label,
//           city: city,
//           state: state,
//           country: country,
//           pin_code: postal_code,
//         });
//       }
//     } else {
//       setAddress({
//         ...address,
//         city: "",
//         state: "",
//         country: "",
//         pin_code: "",
//       });
//     }
//   };

//   return (
//     <>
//       <section className="container conFirm-booking service-details mb-3">
//         <div className="service-details-title">
//           <i className="fa fa-angle-left" onClick={() => navigate(-1)}></i>
//           <span className="service-details-title-text">
//             {addressId ? "Edit Address" : "Add New Address"}
//           </span>
//         </div>

//         <div className="row">
//         <div className="col-12">
//             <h3 className="add-addre">
//               {addressId ? "Edit Address" : "Add New Address"}
//             </h3>
//           </div>
//         </div>
//       </section>
//       {loading ? (
//         <>
//           <div className="container addForm mb-5">
//             <div className="row min-600">
//               <div className="col-12">
//                 <List />
//               </div>
//             </div>
//           </div>
//         </>
//       ) : (
//         <>
//           <div className="container addForm mb-5">
//             <div className="row min-600">
//               <div className="col-12">
//                 <div className="formOut">
//                   {address && (
//                     <form className="p-2 mb-5">
//                       <div className="formInText">
//                         <div className="inpuWidth locationIco">
//                           <label className="required">
//                             Location <strong className="text-danger">*</strong>
//                           </label>
//                           <Geosuggest
//                             placeholder={"Enter address"}
//                             initialValue={address?.location && address?.location}
//                             onSuggestSelect={(e) => getLanLngForAddress(e)}
//                             onChange={(e) => {
//                               setAddress({ ...address, location: e });
//                             }}
//                             required
//                           />
//                         </div>
//                         <div className="inpuWidth">
//                           <label className="input--label">
//                             {"Block No/Shop No."}{" "}
//                             <strong className="text-danger">*</strong>
//                           </label>
//                           <input
//                             type="text"
//                             placeholder="Home, Office etc"
//                             name="block_shop_no"
//                             value={address?.block_shop_no}
//                             onChange={handleInputChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="formInText">
//                         <div className="inpuWidth">
//                           <label className="input--label">
//                             Province (Optional)
//                           </label>
//                           <input
//                             type="text"
//                             placeholder="Opposite Shakti Farm"
//                             name="province"
//                             value={address?.province}
//                             className="addIcon"
//                             onChange={handleInputChange}
//                           />
//                         </div>
//                         <div className="inpuWidth ">
//                           <label className="input--label">
//                             City <strong className="text-danger">*</strong>
//                           </label>
//                           <input
//                             placeholder="Surat"
//                             type={"text"}
//                             value={address?.city}
//                             onChange={handleInputChange}
//                           />
//                         </div>
//                       </div>

//                       <div className="formInText">
//                         <div className="inpuWidth ">
//                           <label className="input--label">
//                             State <strong className="text-danger">*</strong>
//                           </label>
//                           <input
//                             type={"text"}
//                             value={address?.state}
//                             onChange={handleInputChange}
//                             placeholder="Gujarat"
//                           />
//                         </div>
//                         <div className="inpuWidth">
//                           <label className="input--label">
//                             Country<strong className="text-danger">*</strong>
//                           </label>
//                           <select>
//                             <option> USA</option>
//                             <option> India</option>
//                             <option> UK</option>
//                           </select>
//                         </div>
//                       </div>
//                       <div className="d-flex ">
//                         <div
//                           className={
//                             address?.address_type === "home"
//                               ? "addressCapsule testClass"
//                               : "addressCapsule"
//                           }
//                           onClick={() =>
//                             setAddress({ ...address, address_type: "home" })
//                           }
//                         >
//                           <strong>Home</strong>
//                         </div>
//                         <div
//                           className={
//                             address?.address_type === "office"
//                               ? "addressCapsule testClass"
//                               : "addressCapsule"
//                           }
//                           onClick={() =>
//                             setAddress({ ...address, address_type: "office" })
//                           }
//                         >
//                           <strong>Office</strong>
//                         </div>
//                       </div>

//                       <div className="buttonsec">
//                         <input
//                           type="button"
//                           value={addressId ? "Edit Address" : "Add Address"}
//                           onClick={() => handleAddAddress()}
//                         />
//                       </div>
//                     </form>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </>
//   );
// };

// export default AddAddress;
