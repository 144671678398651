import React, { useEffect } from "react";
import "./AllSpecialities.scss";
import CatgoryBox from "../Common/CatgoryBox/CatgoryBox.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../Slices/home";
import { List } from "react-content-loader";
import { useTranslation } from "react-i18next";

const SubSpecialities = () => {
  const { categories, isCategoriesFetched } = useSelector(
    (state) => state.home
  );

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // if(categories.length === 0)
    dispatch(getAllCategories({ type: "sub", action: { category_id: id } }));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <section className="spaceSect">
        <div className="container cateList">
          <div className="row borderBottomDesign titleHead">
            <h2 className="sectionTitle centerText">
              <Link
                to={"javascript:void(0)"}
                onClick={() => navigate(-1)}
                className="backbt"
              >
                <i className="fa fa-angle-left"></i>{" "}
                <span className="section--title">{t("common.sub")}</span>{" "}
                <span className="section--title">
                  {t("common.specialities")}
                </span>
              </Link>
            </h2>
          </div>
          <div className="row headerFilter">
            {isCategoriesFetched ? (
              categories.length !== 0 ? (
                categories?.map((list) =>
                  list?.sub_categories?.map((subCat) => {
                    return <CatgoryBox category={subCat} isSub={true} />;
                  })
                )
              ) : (
                <strong className="section--title">
                  {t("common.no_specialities_found")}
                </strong>
              )
            ) : (
              <List />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubSpecialities;
