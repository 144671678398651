import React, { useEffect, useState } from "react";
import "./ServiceDetailChooseCar.scss";
import clock from "../../Assets/img/clock.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleBookingDetails } from "../../Slices/home";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ServiceDetailChooseCar = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const { serviceDetails, bookingDetails } = useSelector((state) => state.home);
  const { userCars } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const handleNextClick = () => {
    if (!bookingDetails?.car_id) {
      toast.error(t("common.select_car_to_continue"));
    } else {
      navigate("/address-options");
    }
  };

  const handleSelectCar = (car, carData) => {
    dispatch(handleBookingDetails({ car_id: carData.id, car }));
  };

  if (!bookingDetails?.start_date && !bookingDetails?.start_time) {
    return navigate(-1);
  }

  return (
    <>
      <section className="container service-details">
        <div className="service-details-title">
          <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
          <span className="service-details-title-text">
            {t("common.choose_car")}
          </span>
        </div>

        <div className="service-details-body">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="">
                <img
                  className="service-details-img"
                  src={serviceDetails?.service_image_url}
                  alt=""
                />
              </div>
              <div className="service-details-information marginTop-19">
                <div className="service-details-info">
                  <h6 className="service-details-name">
                    {serviceDetails?.service_name}
                  </h6>
                  <p className="service-details-description">
                    {serviceDetails?.description}
                  </p>
                </div>
                <div className="service-details-price-box">
                  {serviceDetails?.offer_price ? (
                    <>
                      <span className="service-details-mrp">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.price ? (
                    <>
                      <span className="service-details-price">
                        {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {serviceDetails?.offer_discount_price ? (
                    <>
                      <span className="service-details-save-price">
                        {t("common.save")} {serviceDetails?.currency?.symbol}{" "}
                        {serviceDetails?.offer_discount_price}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="service-details-time">
                  <img src={clock} alt="" width={15} height={15} />
                  <span className="time">
                    {serviceDetails?.duration} {t("common.minutes")}.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-7 ">
              <div className="choose-provider">
                <div className="schedule-title-box">
                  <div>
                    <span className="schedule-box">
                      {t("common.choose_your_car")}
                    </span>
                  </div>
                </div>
                <div className="choose-body">
                  <div className="choose-body-title">
                    <span className="choose-car-here">
                      {t("common.choose_car_here")}
                    </span>
                    <span
                      className="add-new-car"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/myProfile?page=cars")}
                    >
                      + {t("common.add_new_car")}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="filter-box add-car-filter-box">
                    <div class="dropdown car-dropworn">
                      <button
                        class="btn dropdown-toggle custom-dropdown"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="discount-text">
                          {bookingDetails?.car || "Select your Car"}
                        </span>
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {userCars?.map((data) => (
                          <button
                            class="dropdown-item car-item"
                            onClick={() => {
                              handleSelectCar(
                                data?.car_name +
                                  " (" +
                                  data?.car_fuel_type +
                                  ")" +
                                  " (" +
                                  data?.car_type_data?.car_type +
                                  ")",
                                data
                              );
                            }}
                          >
                            {data?.car_name} ( {data?.car_fuel_type} ) (
                            {data?.car_type_data?.car_type})
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="choose-provider-footer">
                  <span className="choose-provider-price">
                    {t("common.total")} : ₹ {serviceDetails?.price}
                  </span>

                  <button className="next-button" onClick={handleNextClick}>
                    {t("common.continue")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='ServDetailPopOut servLoc'>
    <div className='contianer-fluid ServDetailPop'>
      <div className='serveBack'>
        
        <h6>
          <span className='backbt'>
            <i className='fa fa-angle-left'></i>
          </span>
          Location
        </h6>
      </div>
       <div
        style={{ cursor: "pointer",zIndex:200 }}
        className='closeIcon'
        onClick={() =>handleClose() }
      >
        <i className='fa fa-times'></i>
      </div> 
      <div
        style={{ cursor: "pointer" }}
        className='closeIcon'
      >
        <i className='fa fa-times'></i>
      </div>
      <div className='contetTextArea' >
        <div className='headerFilterInfo'>
          <strong className='serviceName'>
          
            {serviceDetails.service_name}
            <p> {serviceDetails.description}</p>
          </strong>
          
          <div className='contInfo'>
            <div className='contInfoIn'>
              <ul className='startTask'>
                <li>
                  <img src={clock} alt='time' />
                  <span className='time flex-grow-1'>
                     {serviceDetails.duration + " Minutes"} 
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='listMenu'>
        <ul className='listTex paym'>
          <li>
            <a href='' className='active'>
            Choose your car
            </a>
          </li>
        </ul>
      </div>
      
        <div className='listMenu listLOc'>
          <div className="row choosecar">
              <h4>Choose Car Here</h4> <Link className="choosecarR" to={"myProfile?page=cars"}> <i className="fa fa-plus">{"Add New Car"}</i></Link>
          </div>
          <div className="choseCarSecOut">
          <select  className="choseCarSec" defaultValue={selectedCar} onChange={(e) => setSelectedCar(e.target.value)}>
            <option>Select Default Car</option>
            {(userCars && userCars.length > 0) && userCars?.map((list, key) => { 
            return  <option selected={list.is_default == 1?true:false} value={list.id} >{list.car_name}</option>
            })}
          </select>
          </div>
        </div>

        <div className='detOpFoot'>
          <div className='col priceNum'>
            <div className='totalPrice'>
              Total : <span className='price'>₹ {serviceDetails.price}</span>
              <span className='price'>{serviceDetails.currency.symbol +" "+ serviceDetails.price}</span> */}
      {/*</div>
          </div>
          <div className='col rightDet'>
            {/* <button
              style={{ cursor: "pointer" }}
              onClick={() => handleNextClick()}
            >
              Continue
            </button> */}

      {/* <button className='continueBtn' style={{cursor: "pointer"}} onClick={() => handleNextClick()} >
                        CONTINUE
                </button>
          </div>
        </div>
      </div>
    </div>
   </section> */}
    </>
  );
};

export default ServiceDetailChooseCar;
