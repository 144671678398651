import React, { useEffect, useState } from "react";
import "./PaymentRemaining.scss";
import { useDispatch, useSelector } from "react-redux";
import clock from "../../Assets/img/clock.png";
import paytm from "../../Assets/img/paytm.png";
import stripe2 from "../../Assets/img/stripe.png";
import cashon from "../../Assets/img/cashon.png";
import payU from "../../Assets/img/payU.png";
import voucher from "../../Assets/img/voucher.png";
import {
  showAddressOpt,
  showConfirmBooking,
  handleResetBookingDetails,
  showPaymentOpt,
} from "../../Slices/home";
import {
  createBooking,
  handlePaymentSummaryDetails,
  editUserProfile,
  getUserProfile,
  addCouponApply,
  handleCouponApply,
  detailsBooking,
  payRemainPayment,
} from "../../Slices/auth";
import { toast } from "react-toastify";

import $ from "jquery";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const stripe = require("stripe")(
  "sk_test_51MmLQgLI1vtejtJzBW1HwSSewq8BWWn2KLsRooRfPSL8TlMoDPEolNu0IlUTsp1IdaXYroMIArATLwTHtbvv1zsy007HUlb9j1"
);
const PaymentRemaining = () => {
  const dispatch = useDispatch();
  const [paymentDetails, setpaymentDetails] = useState({});
  const [count, setCount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [payment, setPayment] = useState("");
  const [couponDetails, SetcouponDetails] = useState({});
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [dLength, setDlength] = useState(30);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [tipValue, setTipValue] = useState({
    percentage: 0,
    value: 0,
    type: 0,
  });
  const { serviceDetails } = useSelector((state) => state.home);
  const { bookingDetails } = useSelector((state) => state.auth);
  const { id } = useParams();

  const placeOrder = (token, paymentObject) => {
    let body = {
      code: id,

      token: token,
      payment_object: paymentObject,
      payment_new: payment == "stripe" ? 0 : 1,
      payment_type: 1,
      is_tip_applied: tipValue.value != 0 || tipValue.percentage != 0 ? 1 : 0,
      tip_data: {
        tip_amount: tipValue.value,
        type: tipValue.type == 1 && tipValue.percentage,
        percentage: tipValue.percentage,
      },
    };
    console.log(body);
    if (payment) {
      dispatch(createBooking(body))
        .then((response) => {
          console.log(response, "booked");
          if (response.meta.rejectedWithValue) {
            toast.error(response.payload.message);
          } else {
            toast.success(response.payload.message);

            navigate("/booking-confirmed", {
              state: {
                bookingDetails,
                serviceDetails,
              },
            });
            dispatch(handleResetBookingDetails());
          }
        })
        .catch((err) => console.log("Booking Err", err));
    } else {
      toast.error(t("common.no_payment_selected"));
    }
  };
  const handleBookService = () => {
    if (!payment) {
      return toast.error(t("common.no_payment_selected"));
    }
    if (payment == "stripe") {
      var handler = window.StripeCheckout.configure({
        key: "pk_test_51MmLQgLI1vtejtJzcRScx636BqnL6YAFqBv8DKmJYrAKMnphr5MzZ732vTzOQORg2Tg3uuiMlgQME4LoSKQf1iSP000rsavaEU", // pk_test_glxk17KhP7poKIawsaSgKtsL
        locale: "auto",
        token: async (token) => {
          console.log("stripe token", token);
          const token2 = token.id;

          const charge2 = await stripe.charges.create({
            amount:
              (
                Number(details?.payment_summary_data?.second_installment || 0) +
                Number(
                  ((details?.payment_summary_data?.final_amount || 0) *
                    tipValue.percentage) /
                    100 || tipValue.value
                )
              )?.toFixed(2) * 100,
            currency: details?.payment_summary_data?.currency?.code,
            source: token.id,
            description: "",
          });
          // const chargeList = await stripe.charges.list({
          //   limit: 1,
          //   source: token2,
          // });
          // const charge2 = chargeList.data[0];
          console.log("stripe res", charge2);

          console.log("stripe status", payment);
          handleRemainingPayment(token.id, charge2);
        },
      });
      handler.open({
        name: "",
        description: "car wash",
        amount:
          (
            Number(details?.payment_summary_data?.second_installment || 0) +
            Number(
              ((details?.payment_summary_data?.final_amount || 0) *
                tipValue.percentage) /
                100 || tipValue.value
            )
          )?.toFixed(2) * 100,
        currency: details?.payment_summary_data?.currency?.code,

        close: function () {
          // window.location.reload();
          console.log("hello");
        },
      });
    } else {
      handleRemainingPayment();
    }
  };

  // console.log(serviceDetails, bookingDetails)
  // useEffect(() => {
  //   PaymentSummaryDetails();
  // }, []);

  // const PaymentSummaryDetails = () => {
  //   const formData = new FormData();
  //   formData.append("country_id", 103);
  //   formData.append("total_price", serviceDetails.offer_price != ""
  //     ? serviceDetails.offer_price
  //     : serviceDetails.price
  //   );
  //   if (couponDetails && couponDetails.coupon_code) {
  //     const coupon_value = {
  //       total_price: serviceDetails.price,
  //       discount_amount: couponDetails.discount_amount || "",
  //       final_amount: couponDetails.final_amount,
  //       coupon_code: couponDetails.coupon_code,
  //       coupon_name: couponDetails.coupon_name,
  //       // "coupon_id": couponDetails.id
  //     };
  //     formData.append("coupon_array", JSON.stringify(coupon_value));
  //   }
  //   dispatch(handlePaymentSummaryDetails(formData))
  //     .then((response) => {
  //       console.log(response)
  //       setpaymentDetails({ ...response.payload.result.data });
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       toast.error(err);
  //       // setLoading(false);
  //     });
  // };

  // const couponApply = () => {
  //   const formData = new FormData();
  //   formData.append("country_id", 103);
  //   formData.append("service_id", serviceDetails.id);
  //   formData.append("coupon_code", coupon);
  //   dispatch(handleCouponApply(formData))
  //     .then((response) => {
  //       SetcouponDetails({ ...response.payload.result.data });

  //       setTimeout(() => {
  //         PaymentSummaryDetails();
  //       }, 100);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       toast.error(err);
  //       // setLoading(false);
  //     });
  // };

  // const handleBookService = () => {
  //   const formData = new FormData();
  //   const data = { ...serviceDetails, ...bookingDetails };

  //   const PaymentDetails = { payment_summary: JSON.stringify(paymentDetails) };

  //   var dataDetails = {
  //     ...data,
  //     ...PaymentDetails,
  //     car_id: bookingDetails.car_id,
  //   };

  //   dispatch(createBooking(dataDetails))
  //     .then((response) => {
  //       if (response.meta.rejectedWithValue) {
  //         toast.error(response.payload.message);
  //       } else {
  //         toast.success(response.payload.message);
  //         dispatch(showConfirmBooking(true));
  //       }
  //     })
  //     .catch((err) => console.info("Booking Err", err));
  // };

  // const handleClose = () => {
  //   dispatch(showPaymentOpt(false));
  //   dispatch(handleResetBookingDetails());
  // };

  // const is_cuponeadded = () => {
  //   return (
  //     <>
  //       {paymentDetails.is_coupon_applied == true ? (
  //         <li key="dsadsad">
  //           <span key="dsadsad">{"80% amount after service"}</span>
  //           <b key="item_total2">- ₹ {paymentDetails.total ?? ""}</b>
  //         </li>
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   );
  // };
  const getBookigDetails = () => {
    dispatch(detailsBooking(id))
      .then((response) => {
        console.log(response, "payment remaining");
        setIsLoading(false);
        setDetails(response.payload.result.data);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Failed To Fetch Data");
      });
  };
  const handleTip = (tipvalue, type) => {
    if (type) {
      setTipValue((old) => {
        return {
          ...old,
          value: tipvalue,
          percentage: 0,
          type: 1,
        };
      });
    } else {
      setTipValue((old) => {
        return {
          ...old,
          percentage: tipvalue,
          type: 0,
        };
      });
    }
  };

  const handleRemainingPayment = (token, paymentObject) => {
    let body = {
      code: id,
      is_tip_applied:
        ((details?.payment_summary_data?.final_amount || 0) *
          tipValue.percentage) /
          100 ||
        tipValue.value ||
        0
          ? true
          : false,
      tip_data: JSON.stringify({
        tip_amount:
          ((details?.payment_summary_data?.final_amount || 0) *
            tipValue.percentage) /
            100 ||
          tipValue.value ||
          0,
        type: !tipValue?.type ? "percentage" : "",
        percentage: tipValue?.percentage,
        total: details?.payment_summary_data?.item_total || 0,

        final_amount: details?.payment_summary_data?.total || 0,
      }),
      token: token,
      payment_object: paymentObject,
      payment_new: payment == "stripe" ? 0 : 1,
      payment_type:1,
    };
    dispatch(payRemainPayment(body))
      .then((response) => navigate("/myProfile?page=booking"))
      .catch((err) => toast.error(err.payload.message));
  };
  useEffect(() => {
    getBookigDetails();
  }, []);
  return (
    <div className="payment_wrapper">
      <div className="container">
        <div className="listMenu">
          <span className="book_section_back_icon" onClick={() => navigate(-1)}>
            <i class="fa fa-angle-left"></i>
          </span>
          <a href="" className="active">
            <span className="font-family-heeb"> {t("common.payment")}</span>
          </a>
        </div>
        <div className="paymeny_section">
          <div className="payment_left">
            <div className="contetTextArea">
              <div className="payment_body_wash">
                <h2>{details?.service_details?.service_name}</h2>
                <p className="primary-font">
                  {details?.service_details?.description.substring(0, dLength)}{" "}
                  {details?.service_details?.description.length > dLength && (
                    <span>...</span>
                  )}
                </p>
                {details?.service_details?.description.length > dLength &&
                  (details?.service_details?.description.length == dLength ? (
                    <span
                      onClick={() => {
                        setDlength(30);
                      }}
                      className="readLesMore primary-font"
                    >
                      {t("common.read_less")}
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setDlength(
                          details?.service_details?.description.length
                        );
                      }}
                      className="readLesMore primary-font font-family-heebo"
                    >
                      {t("common.read_more")}
                    </span>
                  ))}
                <p>
                  <img src={clock} alt="" width={15} />
                  <span className="font-family-heebo ml-1">
                    {" "}
                    {details?.duration} {t("common.minutes")}
                  </span>
                </p>
              </div>

              <div className="listBox">
                <p className="font-family-heebo">{t("common.payment")}</p>
                <div className="leftBrandLocation">
                  <h4>{t("common.payment_options")}</h4>
                  <ul className="listRadio locationradio payMentOpt">
                    <li onClick={() => setPayment("stripe")}>
                      <input type="radio" name="payment" />
                      <label className="strip">
                        <img src={stripe2} />
                        <span>Stripe</span>
                      </label>
                    </li>

                    <li>
                      <input type="radio" name="payment" />
                      <label className="cod">
                        <img src={cashon} />
                        <span>C.O.D</span>
                      </label>
                    </li>
                  </ul>
                  <div className="listBox">
                    <ul className="voucherOut">
                      <li>
                        <p className="font-family-heebo">
                          {t("common.add_tip")}
                        </p>
                      </li>
                      <li>
                        <div className="payment_pr">
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTip(10, 0)}
                            className={`${
                              tipValue.type == 0 &&
                              tipValue.percentage == 10 &&
                              "active"
                            }`}
                          >
                            10%
                          </p>
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTip(15, 0)}
                            className={`${
                              tipValue.type == 0 &&
                              tipValue.percentage == 15 &&
                              "active"
                            }`}
                          >
                            15%
                          </p>
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTip(20, 0)}
                            className={`${
                              tipValue.type == 0 &&
                              tipValue.percentage == 20 &&
                              "active"
                            }`}
                          >
                            20%
                          </p>
                        </div>
                      </li>
                      <li className="input">
                        <input
                          type="text"
                          name="last_name"
                          placeholder="Enter Amount Here…"
                          defaultValue={coupon}
                          onChange={(e, value, d) => {
                            setCoupon(e.target.value);
                          }}
                        />
                      </li>

                      <li className="btnClass">
                        <button
                          onClick={(e) => {
                            handleTip(coupon, 1);
                          }}
                        >
                          {t("common.add")}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="payment_right">
            <div className="summarRight">
              <h3>{t("common.payment_summary")}</h3>
              <ul>
                <li>
                  <span>{t("common.item_total")}</span>
                  <b key="item_total">
                    ₹ {details?.payment_summary_data?.item_total || 0}
                  </b>
                </li>
                <li>
                  <span>{t("common.tax")}</span>
                  <b key="item_total">
                    ₹ {details?.payment_summary_data?.tax_amount || 0}
                  </b>
                </li>
                <li className="green_li">
                  <span>{t("common.coupon_discount")} </span>
                  <b key="item_total">
                    ₹ {details?.payment_summary_data?.coupon_discount || 0}
                  </b>
                </li>
                {paymentDetails.is_tax_applied == true ? (
                  <li>
                    <span>{t("common.tax")}</span>
                    <b key="item_total1">₹ {paymentDetails.tax_amount || ""}</b>
                  </li>
                ) : (
                  ""
                )}

                <li className="totalPrice">
                  <span>{t("common.total")}</span>
                  <b>₹{details?.payment_summary_data?.final_amount || 0}</b>
                </li>
                <li>
                  <span>
                    {((details?.payment_summary_data?.first_installment || 0) /
                      (details?.payment_summary_data?.final_amount || 1)) *
                      100}
                    % {t("common.amount_paid")}
                  </span>
                  <b key="item_total" style={{ color: "red" }}>
                    - ₹ {details?.payment_summary_data?.first_installment || 0}
                  </b>
                </li>
                <li>
                  <span>
                    TIP{" "}
                    {tipValue.type == 0
                      ? "(" + tipValue.percentage + "% of Total)"
                      : ""}
                  </span>
                  <b key="item_total">
                    ₹{" "}
                    {((details?.payment_summary_data?.final_amount || 0) *
                      tipValue.percentage) /
                      100 ||
                      tipValue.value ||
                      0}
                  </b>
                </li>

                <li className="totalPrice">
                  <span>{t("common.remaining_amount_to_pay")}</span>
                  <b>
                    {details?.payment_summary_data?.currency?.symbol}{" "}
                    {(
                      Number(
                        details?.payment_summary_data?.second_installment || 0
                      ) +
                      Number(
                        ((details?.payment_summary_data?.final_amount || 0) *
                          tipValue.percentage) /
                          100 || tipValue.value
                      )
                    )?.toFixed(2)}{" "}
                  </b>
                </li>
                <li>
                  <span className="small_green_span">
                    ({t("common.you_save")} ₹{" "}
                    {details?.payment_summary_data?.coupon_discount || 0}{" "}
                    {t("common.on_this_booking")})
                  </span>
                </li>
              </ul>
            </div>
            <div className="detOpFoot">
              <div className="col priceNum">
                {/* <div className="totalPrice">
                  {t("common.total")} :
                  <span className="price" key="item_total5">
                    {serviceDetails.currency.symbol + " " + paymentDetails.total}
                  </span>
                </div> */}
              </div>
              <div className="col rightDet">
                <button
                  style={{ cursor: "pointer" }}
                  // onClick={() => dispatch(showConfirmBooking(true))}
                  // onClick={() => handleBookService()}
                  onClick={handleBookService}
                >
                  {t("common.continue")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentRemaining;
