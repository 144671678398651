import React from "react";
import "./Banners.scss";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

const Banners = ({ topSlider, offers }) => {
  const { user } = useSelector((state) => state.auth);

  var settings = {
    dots: true,
    className: "slideIn",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // infinite: true,
    // slidesToShow: 1,
    autoplay: true,
    // speed: 1500,
    // autoplaySpeed: 3500,
    // cssEase: "ease-out",
    // pauseOnHover: true,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true
    //     }
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide:1
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     }
    //   }
    // ]
  };

  return (
    <section>
      <Slider {...settings}>
        {offers &&
          offers.map((offers, index) => (
            <>
              <div className="slideIn" key={index}>
                <Link to={`/offers/${offers?.id}`}>
                  <img
                    src={offers?.offer_banner_url}
                    className="img"
                    alt="banner"
                  />
                </Link>
              </div>
            </>
          ))}
      </Slider>
    </section>
  );
};
export default Banners;
