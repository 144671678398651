import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import star from "../../Assets/img/star.png";
import car from "../../Assets/img/carIcon.png";
import packageImg from "../../Assets/img/package3.png";
import MyBookingUp from "../MyBookingUp/MyBookingUp";
import { useState } from "react";
import { packagelistBooking } from "../../Slices/auth";
import moment from "moment";

import clock from "../../Assets/img/clock.png";
import service from "../../Assets/img/service.png";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import "../MyBookings/MyBookings.scss";
import "./MyPackage.scss";
import { useNavigate } from "react-router";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

const MyPackage = () => {
  const [activeTab, setActiveTab] = useState({
    current: true,
    completed: false,
    expired: false,
    loading: true,
  });
  const [popup, setPopup] = useState("");
  const [detail, Setdetail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTabListing, setSelectedTabListing] = useState([]);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(useSelector((state) => state));

  const handleTabChange = (tab) => {
    switch (tab) {
      case "current":
        setActiveTab({
          current: true,
          completed: false,
          expired: false,
          loading: true,
        });
        dispatch(packagelistBooking("current"))
          .then((response) => {
            setSelectedTabListing(response.payload.result.data);
            setActiveTab({
              current: true,
              completed: false,
              expired: false,
              loading: false,
            });
          })
          .catch(() => {
            setSelectedTabListing([]);
            setActiveTab({
              current: true,
              completed: false,
              expired: false,
              loading: false,
            });
          });
        break;
      case "completed":
        setActiveTab({
          current: false,
          completed: true,
          expired: false,
          loading: true,
        });
        dispatch(packagelistBooking("completed"))
          .then((response) => {
            setSelectedTabListing(response.payload.result.data);
            setActiveTab({
              current: false,
              completed: true,
              expired: false,
              loading: false,
            });
          })
          .catch(() => {
            setActiveTab({
              current: false,
              completed: true,
              expired: false,
              loading: false,
            });
            setSelectedTabListing([]);
          });
        break;
      case "expired":
        setActiveTab({
          current: false,
          completed: false,
          expired: true,
          loading: false,
        });
        dispatch(packagelistBooking("expired"))
          .then((response) => {
            setSelectedTabListing(response.payload.result.data);
            setActiveTab({
              current: false,
              completed: false,
              expired: true,
              loading: false,
            });
          })
          .catch(() => {
            setSelectedTabListing([]);
            setActiveTab({
              current: false,
              completed: false,
              expired: true,
              loading: false,
            });
          });
        break;
      default:
        break;
    }
  };

  const handleClick = (id) => {
    setPopup(id);
  };

  useEffect(() => {
    dispatch(packagelistBooking("current"))
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setActiveTab({ ...activeTab, loading: false });
        setSelectedTabListing(response.payload.result.data);
      })
      .catch(() => {
        setSelectedTabListing([]);
        setIsLoading(false);
        setActiveTab({ ...activeTab, loading: false });
      });
    // eslint-disable-next-line
  }, []);
  return (
    <div className="myPakageOut pt0 myPack">
      <div className="profDetailIn">
        <div className="profdetHead profdetHeadNoRev">
          <h3>{t("common.my_packages")}</h3>
          {/* <ul className='rightTabBar'>
            <li onClick={() => handleTabChange("current")}>
              <a
                href='javascript:void(0)'
                className={`upcoming ${activeTab.current && "active"}`}
              >
                Current
              </a>
            </li>

            <li onClick={() => handleTabChange("completed")}>
              <a
                href='javascript:void(0)'
                className={`completed ${activeTab.completed && "active"}`}
              >
                Completed
              </a>
            </li>
            <li onClick={() => handleTabChange("expired")}>
              <a
                href='javascript:void(0)'
                className={`expire ${activeTab.expired && "active"}`}
              >
                 Cancelled
              </a>
            </li>
          </ul> */}
        </div>
        {/* <div className='profdetHeadText'></div> */}
        {/* <div className='formOut profdet '>
          <form>
            <div className='formInText bookForm'>

            {!activeTab.loading ? (
                selectedTabListing && selectedTabListing.length > 0 ? (
                  selectedTabListing && selectedTabListing.map((list, key) => 
              <div className='inpuWidth sumWid'>
                <div className='sumWidIn'>
                  <div className='summarLeftImgText'>
                    <div className='summarLeftImg'>
                      <img src={list.package_details.package_image_url} alt='img' /> 
                    </div>
                    <div className='headerFilterInfo'>
                      <strong className='serviceName'>{list.package_details.package_name}</strong>
                      <p>
                      {list.package_details.package_description}
                      </p>

                      <div className='contInfo'>
                        <div className='contInfoIn'>
                          <ul className='startTask'>
                            <li>
                            <a href=''>
                              <img src={star} alt='rating' />
                              <span className=' rating'>{list.service_rating}</span>
                            </a>
                          </li>
                            <li>
                              <img src={car} alt='time' />
                              <span className='time flex-grow-1'>{list.service_details.duration} Min</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                   <div className='bookInfo'>
                    <ul className='bookINfoIn'>
                      <li>
                        <h5>Job Id</h5>
                        <span></span>
                      </li>
                      <li>
                        <h5>CAR</h5>
                        <span>{list.car_details.car_name}({list.car_details.car_fuel_type})</span>
                      </li>
                      <li>
                        <h5>PRICE</h5>
                        <span>{list.payment_summary_data.currency.symbol}{list.total_price}</span>
                      </li>
                      <li>
                        <h5>STATUS</h5>
                        <span className='req status'>Requested</span>
                      </li>
                    </ul>
                  </div> 
                  <div className='summarLeftAdd'>
                    <ul className='listRadio addlocationradio'>
                      <li className='quant'>
                      <h4>Quantity</h4>
                      <label>
                        
                        <span>12</span>
                      </label>
                    </li>
                      <li className=''>
                        <ul className='listRadio  locationradioList'>
                          <li className='date'>
                            <label>
                              <h5>{moment(list.service_details.created_at).format(' Do  MMMM ')}</h5>
                            </label>
                          </li>
                          <li className='date'>
                            <label>
                              <h5>{moment(list.service_details.created_at).format('h:mm a')}</h5>
                            </label>
                          </li>
                          <li className='time'>
                          <label>
                            <p>02:00 PM</p>
                          </label>
                        </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                  )
                  ) : (
                    <strong>No Data Found</strong>
                  )
                ) : (
                  <strong>Loading</strong>
                )}
          
              {(activeTab.upcoming && fileredBookingList.upcoming.length == 0) && <strong>No Data Found</strong> }
            {(activeTab.expired && fileredBookingList.expired.length == 0) && <strong>No Data Found</strong> }
            {(activeTab.completed && fileredBookingList.completed.length == 0) && <strong>No Data Found</strong> }
            </div>
          </form>
        </div> */}
        {/* <MyBookingUp /> */}
        {/* {showBookingDetails && <MyBookingUp />} */}

        <div className="formOut profdet">
          <Tabs defaultActiveKey="Purchased" id="uncontrolled-tab-example">
            <Tab eventKey="Purchased" title="Purchased">
              <form>
                <div
                  className="formInText bookForm"
                  style={{ cursor: "pointer" }}
                >
                  {!isLoading ? (
                    selectedTabListing?.map((list, key) => (
                      <div
                        className="inpuWidth sumWid"
                        style={{ marginTop: "18px" }}
                      >
                        <div
                          className="sumWidIn"
                          onClick={() =>
                            navigate(`/packagePurchasedDetail/${list.code}`)
                          }
                        >
                          <div className="summarLeftImgText">
                            <div className="summarLeftImg">
                              <img
                                src={list?.package_details?.package_image_url}
                              />
                            </div>
                            <div className="headerFilterInfo">
                              <strong className="serviceName">
                                {list?.package_details?.package_name}
                              </strong>
                              {/* <div className="textEllips"
                                dangerouslySetInnerHTML={{__html: list.service_details.description}} /> */}
                              <div className="contInfo">
                                <div className="contInfoIn">
                                  <p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          list?.package_details
                                            ?.package_description,
                                      }}
                                    />
                                  </p>
                                  <ul className="startTask">
                                    <li>
                                      <img src={clock} alt="time" />
                                      <span className="time flex-grow-1">
                                        {list?.service_usage + " Times"}
                                      </span>
                                      {/* <span>30 Min.</span> */}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <h4 className="stretched-linksssss---" style={{'cursor':"pointer"}} key={"__"+key} onClick={() => setPopup(key)}> Details </h4> */}
                          {/* <h4 className="stretched-linksssss---" style={{ 'cursor': "pointer" }} > Details </h4> */}
                          <div className="summarLeftAdd">
                            <ul className="listRadio addlocationradio">
                              <li className="">
                                <ul className="listRadio  locationradioList">
                                  <li className="date">
                                    <label>
                                      {/* <h5>{list.start_date}</h5> */}
                                      <span>{t("common.service_usage")}</span>
                                      <h5>
                                        {list?.service_usage}{" "}
                                        {t("common.times")}
                                      </h5>
                                    </label>
                                  </li>
                                  <li className="date">
                                    <label>
                                      {/* <h5>{list.start_time}</h5> */}
                                      <span>{t("common.remaining")}</span>
                                      <h5>
                                        {list?.service_usage_remaining}{" "}
                                        {t("common.times")}
                                      </h5>
                                    </label>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                    </>
                  )}
                </div>
              </form>
            </Tab>
            <Tab eventKey="Booked" title="Booked">
              <form>
                <div
                  className="formInText bookForm"
                  style={{ cursor: "pointer" }}
                >
                  {!isLoading ? (
                    selectedTabListing?.map((list, key) => (
                      <div
                        className="inpuWidth sumWid"
                        style={{ marginTop: "18px" }}
                      >
                        <div
                          className="sumWidIn"
                          onClick={() => navigate(`/package_booked`)}
                        >
                          <div className="summarLeftImgText">
                            <div className="summarLeftImg">
                              <img
                                src={list.package_details.package_image_url}
                              />
                            </div>
                            <div className="headerFilterInfo">
                              <strong className="serviceName">
                                {list.package_details.package_name}
                              </strong>
                              {/* <div className="textEllips"
                                dangerouslySetInnerHTML={{__html: list.service_details.description}} /> */}
                              <div className="contInfo">
                                <div className="contInfoIn">
                                  <p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          list.package_details
                                            .package_description,
                                      }}
                                    />
                                  </p>
                                  <ul className="startTask">
                                    <li>
                                      <img src={clock} alt="time" />
                                      <span className="time flex-grow-1">
                                        {list?.service_usage + " Times"}
                                      </span>
                                      {/* <span>30 Min.</span> */}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <h4 className="stretched-linksssss---" style={{'cursor':"pointer"}} key={"__"+key} onClick={() => setPopup(key)}> Details </h4> */}
                          {/* <h4 className="stretched-linksssss---" style={{ 'cursor': "pointer" }} > Details </h4> */}
                          <div className="summarLeftAdd">
                            <ul className="listRadio addlocationradio">
                              <li className="">
                                <ul className="listRadio  locationradioList">
                                  <li className="date">
                                    <label>
                                      {/* <h5>{list.start_date}</h5> */}
                                      <span>{t("common.service_usage")}</span>
                                      <h5>
                                        {list?.service_usage}{" "}
                                        {t("common.times")}
                                      </h5>
                                    </label>
                                  </li>
                                  <li className="date">
                                    <label>
                                      {/* <h5>{list.start_time}</h5> */}
                                      <span>{t("common.remaining")}</span>
                                      <h5>
                                        {list?.service_usage_remaining}{" "}
                                        {t("common.times")}
                                      </h5>
                                    </label>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={400}
                        sx={{ margin: "12px", borderRadius: "12px" }}
                      />
                    </>
                  )}
                </div>
              </form>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
export default MyPackage;
