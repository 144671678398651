import React from "react";
import "./PackageBox.scss";
import { Link, useNavigate } from "react-router-dom";
import Price from "../Common/Price/Price.jsx";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Common/Button/Button";
import car from "../../Assets/img/carIcon.png";
import { GetpackageService } from "../../Slices/auth";
import {
  getPackageDetails,
  handleResetBookingDetails,
  handleResetPackageBookingDetails,
  handleResetpackageServiceBooking,
  setLoading,
  setLoadingFalse,
} from "../../Slices/home";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const PackageBox = (packages) => {
  const dispatch = useDispatch();
  const { user_car_id_default, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const data = {
    package_id: packages.packages.package_id,
    car_id: user_car_id_default || localStorage.getItem("car_id"), // changed,
    country_id: 103,
  };

  const handleNext = () => {
    dispatch(handleResetBookingDetails());
    dispatch(handleResetPackageBookingDetails());
    dispatch(handleResetpackageServiceBooking());
    dispatch(getPackageDetails(data)).then((data) => {
      console.log(data, "dispatch(getPackageDetails(data))");
    });
    if (Object.keys(user).length == 0 || !sessionStorage.getItem("token")) {
      toast.error("Please Login Before Book Service");
    } else if (!user_car_id_default) {
      toast.error("Select Default Car");
    } else {
      navigate(`/packageDetail/${data?.package_id}`);
      dispatch(setLoading({ status: 1 }));
      dispatch(getPackageDetails(data))
        .then((data) => {
          dispatch(setLoadingFalse({ status: 1 }));
        })
        .catch(() => setLoadingFalse({ status: 1 }));
    }
  };

  return (
    <div className="headerFilterSec packgebox p-3">
      <div className="headerFilterImg min-w-100">
        <img
          src={packages?.packages?.package?.package_image_url}
          className="packages-img"
          alt="packages-img"
        />
      </div>
      <div className="headerFilterInfo">
        <strong className="serviceName">
          {packages.packages?.package?.package_name}
        </strong>
        <p className="contText">
          {packages.packages?.package?.package_description}
        </p>
        <div className="contInfo">
          <ul className="price ">
            <li className="price mt-1">
              <span className="font-15">
                {" "}
                {packages.packages?.package?.currency?.symbol}{" "}
                {packages.packages?.package?.final_price}
              </span>
              <del className="ml-1">
                {packages.packages?.package?.currency?.symbol}{" "}
                <span className="font-15">
                  {packages.packages?.package?.total_price}
                </span>
              </del>
              <span className="ml-2 font-15 text-green">
                {" "}
                Save {packages.packages?.package?.currency?.symbol}
                {packages.packages?.package?.discount_price}{" "}
                {"(" + packages.packages?.package?.discount_percentage + "%)"}
              </span>
            </li>
          </ul>
          <div className="contInfoIn">
            <div className="contInfoInTime">
              <img src={car} />
              <span>
                {packages.packages?.package?.package_validity}{" "}
                {t("common.times")}
              </span>
            </div>

            <button
              className="purchase-button"
              onClick={() => {
                handleNext();
              }}
            >
              {t("common.purchase")}
            </button>

            {/* <Button /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageBox;
