import React, { useEffect, useState } from "react";
import "./PackagePaymentOption.scss";
import $ from "jquery";
import flat from "../../Assets/img/flat.png";
import home from "../../Assets/img/home.png";
import { useDispatch, useSelector } from "react-redux";
// import { setShowAddressModal, setShowLocationModal } from '../../slices/service';
import PricIncDec from "../Common/PricIncDec/PricIncDec.jsx";
import clock from "../../Assets/img/clock.png";
import paytm from "../../Assets/img/paytm.png";
// import stripe from "../../Assets/img/stripe.png";
import cashon from "../../Assets/img/cashon.png";
import payU from "../../Assets/img/payU.png";
import voucher from "../../Assets/img/voucher.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  handleResetPackageBookingDetails,
  handleResetpackageServiceBooking,
  showAddressOpt,
  showConfirmBooking,
  showPaymentOpt,
} from "../../Slices/home";
import {
  createPackageBooking,
  handleBookingDetails,
  handlePaymentSummaryDetails,
} from "../../Slices/auth";
import { toast } from "react-toastify";
import car from "../../Assets/img/carIcon.png";

const stripe = require("stripe")(
  "sk_test_51MmLQgLI1vtejtJzBW1HwSSewq8BWWn2KLsRooRfPSL8TlMoDPEolNu0IlUTsp1IdaXYroMIArATLwTHtbvv1zsy007HUlb9j1"
);

const PaymentOption = () => {
  const { t } = useTranslation();
  const { bookingDetails } = useSelector((state) => state.home);

  const dispatch = useDispatch();
  const [payment, setPayment] = useState("");
  const [paymentDetails, setpaymentDetails] = useState({});

  const {
    packageDetail,
    packageBookingDetails,
    user_car_details_default,
    dashboardSections,
    isDashboardSectionsFetched,
    offers,
  } = useSelector((state) => state.home);

  // const { user_car_id_default } = useSelector((state) => state.auth);
  const { user_car_id_default, landigDetails } = useSelector(
    (state) => state.home
  );
  const navigate = useNavigate();
  const PaymentSummaryDetails = () => {
    const formData = new FormData();
    formData.append("country_id", 103);
    formData.append("total_price", packageDetail.final_price);
    dispatch(handlePaymentSummaryDetails(formData))
      .then((response) => {
        setpaymentDetails({ ...response.payload.result.data });
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleNextStep = () => {
    let body = {
      ...packageDetail,
      ...{ payment_summary: JSON.stringify(paymentDetails) },
      ...packageBookingDetails,
      sp_id: 2,
      package_id: packageDetail?.id,
      car_id: user_car_id_default,
    };
    console.log(body);
    dispatch(createPackageBooking(body))
      .then((response) => {
        console.log(response);
        if (response.meta.rejectedWithValue) {
          toast.error(response.payload.message);
        } else {
          toast.success(response.payload.message);
          navigate("/Package-booking-confirmed");
        }
      })
      .catch((err) => console.log("Booking Err", err));
    // navigate('/PackagePaymentOption')
  };

  const handleBookService = () => {
    if (payment == "Stripe") {
      var handler = window.StripeCheckout.configure({
        key: "pk_test_51MmLQgLI1vtejtJzcRScx636BqnL6YAFqBv8DKmJYrAKMnphr5MzZ732vTzOQORg2Tg3uuiMlgQME4LoSKQf1iSP000rsavaEU", // pk_test_glxk17KhP7poKIawsaSgKtsL
        locale: "auto",
        token: async (token) => {
          console.log("stripe token", token);
          const token2 = token.id;
          const charge2 = await stripe.charges.create({
            amount: paymentDetails?.first_installment * 100,
            currency: packageDetail?.currency?.code,
            source: token.id,
            description: "",
          });
          placeOrder(token.id, charge2);
        },
      });
      handler.open({
        name: "",
        description: "Car Wash",
        amount: paymentDetails?.item_total * 100,
        currency: packageDetail?.currency?.code,

        close: function () {},
      });
    } else {
      placeOrder();
    }
  };

  const placeOrder = (token, paymentObject) => {
    const payment_summary = {
      item_total: 400,
      tax_data: paymentDetails.tax_data,
      tax_amount: paymentDetails.tax_amount,
      is_tax_applied: paymentDetails.is_tax_applied,
      total: paymentDetails.item_total,
      currency: paymentDetails.currency,
    };

    const packageslist = dashboardSections?.packages;
    let selected_package = packageslist.find((o) => o.id === packageDetail.id);
    const userData = JSON.parse(sessionStorage.getItem("user"));
    let body = {
      package_id: packageDetail.id,
      reminder_id: packageBookingDetails.reminder_id,
      sp_id: selected_package.sp_id,
      payment_summary: JSON.stringify(payment_summary),
      car_id: userData.user_car_details_default.id,
      token: token,
      payment_object: JSON.stringify(paymentObject),
      payment_type: 0,
    };

    if (payment) {
      dispatch(createPackageBooking(body))
        .then((response) => {
          if (response.meta.rejectedWithValue) {
            toast.error(response.payload.message);
          } else {
            toast.success(response.payload.message);
            navigate("/Package-booking-confirmed", {
              state: {
                bookingDetails,
                packageDetail,
              },
            });
            dispatch(handleBookingDetails());
            dispatch(handleResetPackageBookingDetails());
            dispatch(handleResetpackageServiceBooking());
          }
        })
        .catch((err) => console.log("Booking Err", err));
    } else {
      toast.error(t("common.no_payment_selected"));
    }
  };

  useEffect(() => {
    PaymentSummaryDetails();
  }, []);

  const payments = [
    {
      id: 1,
      name: "Stripe",
      url: require("../../Assets/img/stripe.png"),
      color: "stripe-color",
    },
  ];

  return (
    <section className="container service-details">
      <div className="service-details-title">
        <i
          className="fa fa-angle-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <span className="service-details-title-text">Payment</span>
      </div>

      <div className="service-details-body">
        <div className="row">
          <div className="col-12 col-lg-5">
            <div className="">
              <img
                className="service-details-img"
                src={packageDetail?.package_image_url}
                alt=""
              />
            </div>

            <div className="service-details-information marginTop-19 Schedule bg-transparent">
              <div className="service-details-info">
                <ul className="price">
                  <li className="price">
                    <h3>
                      {packageDetail?.currency?.symbol}
                      {packageDetail?.final_price}
                    </h3>
                  </li>
                  <>
                    <li>
                      <span className="textCut price">
                        {packageDetail?.total_price}
                      </span>
                    </li>
                    <li>
                      <span className="textSave font-weight-bold save-price">
                        Save
                        {packageDetail?.discount_price}
                        {` (` + packageDetail?.discount_percentage + `%)`}
                      </span>
                    </li>
                  </>
                </ul>
                <h6 className="service-details-name">
                  {packageDetail?.package_name}
                </h6>
                <p className="Schedule-service-details-description">
                  {packageDetail?.package_description}
                </p>
              </div>
              <div className="service-details-time">
                <img src={car} alt="" width={15} height={15} />
                <span className="time">
                  {packageDetail?.package_service_limit} Time.{" "}
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-7 ">
            <div className="choose-provider address-options">
              <div className="schedule-title-box m-0">
                <div>
                  <span className="schedule-box p-0">Payment</span>
                </div>
              </div>
              <div className="choose-body">
                <div className="choose-body-title">
                  <span className="choose-car-here p-0">Payment Options</span>
                </div>
              </div>

              <div className="payment-box row m-0">
                {payments.map((item, index) => {
                  return (
                    <>
                      <div
                        className={`payment-list ${item.color}`}
                        onClick={() => {
                          setPayment(item?.name);
                        }}
                      >
                        <div className={`payment-item`}>
                          <img
                            className="payment-icon"
                            src={item?.url}
                            alt=""
                          />
                          <h3 className="payment-name">{item?.name}</h3>
                        </div>
                        <div className="radio-body-pink">
                          {payment === item?.name ? (
                            <>
                              {" "}
                              <div className="radio-dot-pink"></div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>

              {/* <div className="row coupon-box m-3 p-2">
                <div className="col-12 col-xl-4 p-0 title-box-item">
                  <div className="title-box mt-0">
                    <img src={require("../../Assets/img/voucher.png")} alt="" className="img-voucher" />
                    <h3 className="title-box-text">Apply Coupon :</h3>
                  </div>
                </div>
                <div className="col-12 col-xl-6 pe-1 pl-0 title-box-item">
                  <input type="text" placeholder="Enter coupon code" className="coupon-input w-100" maxlength="15" />
                  <span className="applied-text">Applied</span>
                </div>
                <div className="col-12 col-xl-2 p-0 title-box-item"><button className="apply-coupon-btn"> Apply </button></div>
              </div> */}

              <div className="payment-info-box">
                <h3 className="payment-main-title">Payment Summary</h3>
                <div className="payment-item">
                  <span className="payment-title">Item total</span>
                  <span className="payment-price">
                    {packageDetail?.currency?.symbol}{" "}
                    {packageDetail?.total_price}
                  </span>
                </div>
                <div className="payment-item">
                  <span className="payment-title">Tax</span>
                  <span className="payment-price">
                    {packageDetail?.currency?.symbol}{" "}
                    {packageDetail?.tax_amount || 0}
                  </span>
                </div>
                <div className="payment-item">
                  <span className="payment-title">Discount Price</span>
                  <span className="payment-price">
                    {packageDetail?.currency?.symbol}{" "}
                    {packageDetail?.discount_price}
                  </span>
                </div>
                {/* <div className="payment-item">
                  <span className="payment-title-green">Coupon Discount (GET100OFF)</span>
                  <span className="payment-title-green">- ₹ 100</span>
                </div> */}
                <hr />
                {/* <div className="payment-item">
                  <span className="payment-title-total">Total</span>
                  <span className="payment-price">₹ 430</span>
                </div> */}
                {/* <div className="payment-item">
                  <span className="payment-title">80% amount after service</span>
                  <span className="payment-price"> - ₹ 344</span>
                </div> */}
                <hr />
                <div className="payment-item">
                  <span className="payment-title-total">
                    Amount to be Pay Now
                  </span>
                  <span className="payment-price">
                    {" "}
                    {packageDetail?.currency?.symbol}{" "}
                    {packageDetail?.final_price}
                  </span>
                </div>
              </div>
              <div className="choose-provider-footer">
                <span className="choose-provider-price">
                  Total : {packageDetail?.currency?.symbol}{" "}
                  {packageDetail?.final_price}
                </span>

                <button className="next-button" onClick={handleBookService}>
                  {t("common.continue")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentOption;
